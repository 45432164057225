import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { OperatorService } from '@daml.js/utility-registry-operator-v0/lib/Utility/Registry/Operator/V0/Service/Operator';
import { CreateEvent } from '@daml/ledger';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useCreateOperatorService: () => UseMutationResult<
  CreateEvent<OperatorService>,
  string,
  void
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useMutation({
    mutationKey: ['createRegistryOperatorService', ledgerApi, operator],
    mutationFn: async () => {
      return ledgerApi.createRegistryOperatorService(operator);
    },
  });
};

export default useCreateOperatorService;
