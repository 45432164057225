import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { OperatorConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Operator';
import { ProviderServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptProviderServiceRequest: () => UseMutationResult<
  void,
  string,
  {
    providerServiceRequestCid: ContractId<ProviderServiceRequest>;
    operatorConfigurationCid: ContractId<OperatorConfiguration>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useMutation({
    mutationKey: ['acceptRegistryProviderServiceRequest', ledgerApi, operator],
    mutationFn: async ({ providerServiceRequestCid, operatorConfigurationCid }) => {
      return ledgerApi.acceptRegistryProviderServiceRequest(
        providerServiceRequestCid,
        operatorConfigurationCid,
      );
    },
  });
};

export default useAcceptProviderServiceRequest;
