import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { AuthConf, isHs256UnsafeAuthConfig, isRs256AuthConfig } from '../config/config';
import { decodeToken, generateToken } from '../utils/auth';

export type UserState = {
  loggedInUser?: LoggedInUser;
  login: (id: string) => void;
  logout: () => void;
  isSelfSignedToken: () => boolean;
};

export type LoggedInUser = {
  userId: string;
  userAccessToken: string;
};

export const UserContext = React.createContext<UserState | undefined>(undefined);

export const UserProvider = ({
  auth,
  children,
}: PropsWithChildren<{
  auth: AuthConf;
}>) => {
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>();
  const oidcAuth = useAuth();

  useEffect(() => {
    if (isRs256AuthConfig(auth) && oidcAuth && oidcAuth.isAuthenticated && oidcAuth.user) {
      const userId = decodeToken(oidcAuth.user.access_token).sub!;
      setLoggedInUser({
        userId,
        userAccessToken: oidcAuth.user.access_token,
      });
    }
  }, [auth, oidcAuth]);

  const login = useCallback(
    async (userId: string) => {
      if (isHs256UnsafeAuthConfig(auth)) {
        const userAccessToken = await generateToken(
          userId,
          auth.secret,
          auth.audience,
          'daml_ledger_api',
        );
        setLoggedInUser({ userId, userAccessToken });
      } else if (
        !hasAuthParams() &&
        !oidcAuth.isAuthenticated &&
        !oidcAuth.activeNavigator &&
        !oidcAuth.isLoading
      ) {
        console.debug('User is unauthenticated. Signing in.');
        await oidcAuth.signinRedirect();
      } else {
        console.debug('Authentication process is still executing.');
      }
    },
    [auth, oidcAuth],
  );

  const logout = useCallback(async () => {
    setLoggedInUser(undefined);
    if (isRs256AuthConfig(auth) && oidcAuth) {
      await oidcAuth.removeUser();
    }
  }, [auth, oidcAuth]);

  const isSelfSignedToken = useCallback(() => isHs256UnsafeAuthConfig(auth), [auth]);

  const userState: UserState = useMemo(
    () => ({ loggedInUser, login, logout, isSelfSignedToken }),
    [loggedInUser, login, logout, isSelfSignedToken],
  );

  return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};
