import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useOfferUnlock from '../../../hooks/mutations/registry/unlock/useOfferUnlock';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import TemplateContract from '../../../utils/TemplateContract';
import { ERR_OFF_UNLOCK, SUC_OFF_UNLOCK } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  holding?: TemplateContract<Holding>;
};

export const OfferUnlockDialog = ({ open, onClose, holding }: DialogProps) => {
  const [locker, setLocker] = useState<string>(
    holding?.payload.lock?.lockers.map.entriesArray()[0][0] || '',
  );
  const [instrumentId, setInstrumentId] = useState<string>(holding?.payload.instrument.id || '');
  const [amount, setAmount] = useState<string>(holding?.payload.amount || '');
  const [label, setLabel] = useState<string>(holding?.payload.label || '');
  const [registrar, setRegistrar] = useState<string>(holding?.payload.registrar || '');

  const holderService = useHolderService();
  const offerUnlock = useOfferUnlock();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setLocker('');
    setInstrumentId('');
    setAmount('');
    setLabel('');
    setRegistrar('');
  }, [setLocker, setInstrumentId, setAmount, setLabel, setRegistrar]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      registrar,
      locker,
      instrumentId,
      amount,
      holdingLabel: label,
      batch: { id: uuidv4(), size: '1', settlementFrom: null, settlementUntil: null },
    };
    await mutate(offerUnlock, payload, SUC_OFF_UNLOCK, ERR_OFF_UNLOCK);
    handleClose();
  }, [
    holderService,
    label,
    amount,
    instrumentId,
    locker,
    registrar,
    handleClose,
    mutate,
    offerUnlock,
  ]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !instrumentId || !amount || !locker;
  return (
    <FormDialog
      open={open}
      title="Offer Unlock"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Offer"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Locker" value={locker} setValue={setLocker} required />
      <TextInput label="Instrument" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput label="Amount" value={amount} setValue={setAmount} required />
      <TextInput label="Label" value={label} setValue={setLabel} required />
      <TextInput
        label="Registrar"
        value={registrar}
        setValue={setRegistrar}
        required
        disabled={!!holding}
      />
    </FormDialog>
  );
};
