import { useCallback, useState } from 'react';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useCreateProviderConfiguration from '../../../hooks/mutations/registry/configuration/useCreateProviderConfiguration';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useProviderService from '../../../hooks/queries/registry/onboarding/useProviderService';
import { ERR_CRE_PROV_CONF, SUC_CRE_PROV_CONF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateProviderConfigurationDialog = ({ open, onClose }: DialogProps) => {
  const [holderRequirementProperty, setHolderRequirementProperty] = useState<string>('');
  const [holderRequirementValue, setHolderRequirementValue] = useState<string>('');
  const [registrarRequirementProperty, setRegistrarRequirementProperty] = useState<string>('');
  const [registrarRequirementValue, setRegistrarRequirementValue] = useState<string>('');

  const { operator, party } = useParties();
  const providerService = useProviderService();
  const mutate = useMutate();
  const createProviderConfiguration = useCreateProviderConfiguration();

  const resetState = useCallback(() => {
    setHolderRequirementProperty('');
    setHolderRequirementValue('');
    setRegistrarRequirementProperty('');
    setRegistrarRequirementValue('');
  }, [
    setHolderRequirementProperty,
    setHolderRequirementValue,
    setRegistrarRequirementProperty,
    setRegistrarRequirementValue,
  ]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!providerService.data) return;
    const payload = {
      providerServiceCid: providerService.data.contractId,
      holderRequirement: {
        operator,
        issuer: party,
        requiredClaims: [{ _1: holderRequirementProperty, _2: holderRequirementValue }],
      },
      registrarRequirement: {
        operator,
        issuer: party,
        requiredClaims: [{ _1: registrarRequirementProperty, _2: registrarRequirementValue }],
      },
    };
    await mutate(createProviderConfiguration, payload, SUC_CRE_PROV_CONF, ERR_CRE_PROV_CONF);
    handleClose();
  }, [
    providerService.data,
    createProviderConfiguration,
    operator,
    party,
    holderRequirementProperty,
    holderRequirementValue,
    registrarRequirementProperty,
    registrarRequirementValue,
    handleClose,
    mutate,
  ]);

  const { isLoading } = providerService;
  if (isLoading) return null;

  const disableSubmit =
    !holderRequirementProperty ||
    !holderRequirementValue ||
    !registrarRequirementProperty ||
    !registrarRequirementValue;
  return (
    <FormDialog
      open={open}
      title="Create Provider Configuration"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Create"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput
        label="Holder Requirement Property"
        value={holderRequirementProperty}
        setValue={setHolderRequirementProperty}
        required
      />
      <TextInput
        label="Holder Requirement Value"
        value={holderRequirementValue}
        setValue={setHolderRequirementValue}
        required
      />
      <TextInput
        label="Registrar Requirement Property"
        value={registrarRequirementProperty}
        setValue={setRegistrarRequirementProperty}
        required
      />
      <TextInput
        label="Registrar Requirement Value"
        value={registrarRequirementValue}
        setValue={setRegistrarRequirementValue}
        required
      />
    </FormDialog>
  );
};
