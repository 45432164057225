import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useOfferTransfer from '../../../hooks/mutations/registry/transfer/useOfferTransfer';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import TemplateContract from '../../../utils/TemplateContract';
import { ERR_OFF_XFER, SUC_OFF_XFER } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  holding?: TemplateContract<Holding>;
};

export const OfferTransferDialog = ({ open, onClose, holding }: DialogProps) => {
  const [receiver, setReceiver] = useState<string>('');
  const [instrumentId, setInstrumentId] = useState<string>(holding?.payload.instrument.id || '');
  const [amount, setAmount] = useState<string>(holding?.payload.amount || '');
  const [senderLabel, setSenderLabel] = useState<string>(holding?.payload.label || '');
  const [registrar, setRegistrar] = useState<string>(holding?.payload.registrar || '');

  const holderService = useHolderService();
  const offerTransfer = useOfferTransfer();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setReceiver('');
    setInstrumentId('');
    setAmount('');
    setSenderLabel('');
    setRegistrar('');
  }, [setReceiver, setInstrumentId, setAmount, setSenderLabel, setRegistrar]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      registrar,
      receiver,
      instrumentId,
      amount,
      senderLabel,
      batch: { id: uuidv4(), size: '1', settlementFrom: null, settlementUntil: null },
    };
    await mutate(offerTransfer, payload, SUC_OFF_XFER, ERR_OFF_XFER);
    handleClose();
  }, [
    holderService,
    senderLabel,
    amount,
    instrumentId,
    receiver,
    registrar,
    handleClose,
    mutate,
    offerTransfer,
  ]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !instrumentId || !amount || !receiver || !registrar;
  return (
    <FormDialog
      open={open}
      title="Offer Transfer"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Offer"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Receiver" value={receiver} setValue={setReceiver} required />
      <TextInput label="Instrument" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput label="Amount" value={amount} setValue={setAmount} required />
      <TextInput label="Label" value={senderLabel} setValue={setSenderLabel} />
      <TextInput
        label="Registrar"
        value={registrar}
        setValue={setRegistrar}
        required
        disabled={!!holding}
      />
    </FormDialog>
  );
};
