import { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import {
  HolderService,
  HolderServiceRequest,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  ProviderServiceRequest,
  ProviderService,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import {
  RegistrarServiceRequest,
  RegistrarService,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptHolderServiceRequest from '../../../hooks/mutations/registry/onboarding/useAcceptHolderServiceRequest';
import useAcceptProviderServiceRequest from '../../../hooks/mutations/registry/onboarding/useAcceptProviderServiceRequest';
import useAcceptRegistrarServiceRequest from '../../../hooks/mutations/registry/onboarding/useAcceptRegistrarServiceRequest';
import useCancelHolderServiceRequest from '../../../hooks/mutations/registry/onboarding/useCancelHolderServiceRequest';
import useCancelProviderServiceRequest from '../../../hooks/mutations/registry/onboarding/useCancelProviderServiceRequest';
import useCancelRegistrarServiceRequest from '../../../hooks/mutations/registry/onboarding/useCancelRegistrarServiceRequest';
import useCreateOperatorService from '../../../hooks/mutations/registry/onboarding/useCreateOperatorService';
import useRejectHolderServiceRequest from '../../../hooks/mutations/registry/onboarding/useRejectHolderServiceRequest';
import useRejectProviderServiceRequest from '../../../hooks/mutations/registry/onboarding/useRejectProviderServiceRequest';
import useRejectRegistrarServiceRequest from '../../../hooks/mutations/registry/onboarding/useRejectRegistrarServiceRequest';
import useTerminateHolderService from '../../../hooks/mutations/registry/onboarding/useTerminateHolderService';
import useTerminateProviderService from '../../../hooks/mutations/registry/onboarding/useTerminateProviderService';
import useTerminateRegistrarService from '../../../hooks/mutations/registry/onboarding/useTerminateRegistrarService';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useRegistryOperatorConfiguration from '../../../hooks/queries/registry/configuration/useRegistryOperatorConfiguration';
import useRegistryProviderConfiguration from '../../../hooks/queries/registry/configuration/useRegistryProviderConfiguration';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useHolderServiceRequests from '../../../hooks/queries/registry/onboarding/useHolderServiceRequests';
import useHolderServices from '../../../hooks/queries/registry/onboarding/useHolderServices';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useProviderService from '../../../hooks/queries/registry/onboarding/useProviderService';
import useProviderServiceRequests from '../../../hooks/queries/registry/onboarding/useProviderServiceRequests';
import useProviderServices from '../../../hooks/queries/registry/onboarding/useProviderServices';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import useRegistrarServiceRequests from '../../../hooks/queries/registry/onboarding/useRegistrarServiceRequests';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import { prettyParty } from '../../../utils/common';
import {
  ERR_ACC_HOLDER_SVC,
  ERR_ACC_PROVIDER_SVC,
  ERR_ACC_REGISTRAR_SVC,
  ERR_CAN_HOLDER_SVC,
  ERR_CAN_PROVIDER_SVC,
  ERR_CAN_REGISTRAR_SVC,
  ERR_CRE_OP_SVC,
  ERR_REJ_HOLDER_SVC,
  ERR_REJ_PROVIDER_SVC,
  ERR_REJ_REGISTRAR_SVC,
  ERR_TER_HOLDER_SVC,
  ERR_TER_PROVIDER_SVC,
  ERR_TER_REGISTRAR_SVC,
  SUC_ACC_HOLDER_SVC,
  SUC_ACC_PROVIDER_SVC,
  SUC_ACC_REGISTRAR_SVC,
  SUC_CAN_HOLDER_SVC,
  SUC_CAN_PROVIDER_SVC,
  SUC_CAN_REGISTRAR_SVC,
  SUC_CRE_OP_SVC,
  SUC_REJ_HOLDER_SVC,
  SUC_REJ_PROVIDER_SVC,
  SUC_REJ_REGISTRAR_SVC,
  SUC_TER_HOLDER_SVC,
  SUC_TER_PROVIDER_SVC,
  SUC_TER_REGISTRAR_SVC,
} from '../../../utils/strings';
import { RequestHolderServiceDialog } from './RequestHolderServiceDialog';
import { RequestProviderServiceDialog } from './RequestProviderServiceDialog';
import { RequestRegistrarServiceDialog } from './RequestRegistrarServiceDialog';

type Row = {
  contractId: string;
  serviceType: string;
  operator: string;
  provider: string;
  user: string;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'serviceType',
      headerName: 'Service Type',
      valueGetter: (params) => params.row.serviceType,
      flex: 150,
      filterable: false,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      valueGetter: (params) => prettyParty(params.row.provider),
      flex: 150,
      filterable: false,
    },
    {
      field: 'user',
      headerName: 'User',
      valueGetter: (params) => prettyParty(params.row.user),
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [providerIsOpen, setProviderIsOpen] = useState<boolean>(false);
  const [holderIsOpen, setHolderIsOpen] = useState<boolean>(false);
  const [registrarIsOpen, setRegistrarIsOpen] = useState<boolean>(false);
  const { operator, party } = useParties();
  const holderService = useHolderService();
  const holderServiceRequests = useHolderServiceRequests();
  const registrarService = useRegistrarService();
  const registrarServiceRequests = useRegistrarServiceRequests();
  const providerService = useProviderService();
  const providerServiceRequests = useProviderServiceRequests();
  const operatorService = useOperatorService();
  const providerConfiguration = useRegistryProviderConfiguration();
  const createOperatorService = useCreateOperatorService();
  const mutate = useMutate();

  const createOperator = useCallback(() => {
    return mutate(createOperatorService, undefined, SUC_CRE_OP_SVC, ERR_CRE_OP_SVC);
  }, [createOperatorService, mutate]);

  const isLoading =
    holderService.isLoading ||
    holderServiceRequests.isLoading ||
    registrarService.isLoading ||
    registrarServiceRequests.isLoading ||
    providerConfiguration.isLoading ||
    providerService.isLoading ||
    providerServiceRequests.isLoading ||
    operatorService.isLoading;
  if (isLoading) return null;

  const holderServiceRequest = holderServiceRequests.data!.find((r) => r.payload.holder === party);
  const registrarServiceRequest = registrarServiceRequests.data!.find(
    (r) => r.payload.registrar === party,
  );
  const providerServiceRequest = providerServiceRequests.data!.find(
    (r) => r.payload.provider === party,
  );

  const canRequestHolder = !holderService.data && !holderServiceRequest;
  const canRequestRegistrar = !registrarService.data && !registrarServiceRequest;
  const canRequestProvider = !providerService.data && !providerServiceRequest;
  const canCreateOperator = !operatorService.data && operator === party;

  return (
    <GridToolbarContainer>
      {!!providerIsOpen && (
        <RequestProviderServiceDialog
          open={!!providerIsOpen}
          onClose={() => setProviderIsOpen(false)}
        />
      )}
      {!!holderIsOpen && (
        <RequestHolderServiceDialog open={!!holderIsOpen} onClose={() => setHolderIsOpen(false)} />
      )}
      {!!registrarIsOpen && (
        <RequestRegistrarServiceDialog
          open={!!registrarIsOpen}
          onClose={() => setRegistrarIsOpen(false)}
        />
      )}
      {operator === party && (
        <AsyncButton onClick={createOperator} disabled={!canCreateOperator}>
          Create operator service
        </AsyncButton>
      )}
      <SyncButton onClick={() => setHolderIsOpen(true)} disabled={!canRequestHolder}>
        Request holder service
      </SyncButton>
      <SyncButton onClick={() => setRegistrarIsOpen(true)} disabled={!canRequestRegistrar}>
        Request registrar service
      </SyncButton>
      <SyncButton onClick={() => setProviderIsOpen(true)} disabled={!canRequestProvider}>
        Request provider service
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const Onboarding = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const { party } = useParties();
  const holderServices = useHolderServices();
  const registrarServices = useRegistrarServices();
  const providerServices = useProviderServices();
  const holderServiceRequests = useHolderServiceRequests();
  const registrarServiceRequests = useRegistrarServiceRequests();
  const providerServiceRequests = useProviderServiceRequests();
  const terminateHolderService = useTerminateHolderService();
  const terminateRegistrarService = useTerminateRegistrarService();
  const terminateProviderService = useTerminateProviderService();
  const acceptHolderServiceRequest = useAcceptHolderServiceRequest();
  const acceptRegistrarServiceRequest = useAcceptRegistrarServiceRequest();
  const acceptProviderServiceRequest = useAcceptProviderServiceRequest();
  const rejectHolderServiceRequest = useRejectHolderServiceRequest();
  const rejectRegistrarServiceRequest = useRejectRegistrarServiceRequest();
  const rejectProviderServiceRequest = useRejectProviderServiceRequest();
  const cancelHolderServiceRequest = useCancelHolderServiceRequest();
  const cancelRegistrarServiceRequest = useCancelRegistrarServiceRequest();
  const cancelProviderServiceRequest = useCancelProviderServiceRequest();
  const providerConfiguration = useRegistryProviderConfiguration();
  const operatorConfiguration = useRegistryOperatorConfiguration();
  const mutate = useMutate();

  const terminateHolder = useCallback(
    (r: Row) => {
      const payload = { holderServiceCid: r.contractId as ContractId<HolderService> };
      return mutate(terminateHolderService, payload, SUC_TER_HOLDER_SVC, ERR_TER_HOLDER_SVC);
    },
    [terminateHolderService, mutate],
  );

  const terminateRegistrar = useCallback(
    (r: Row) => {
      const payload = { registrarServiceCid: r.contractId as ContractId<RegistrarService> };
      return mutate(
        terminateRegistrarService,
        payload,
        SUC_TER_REGISTRAR_SVC,
        ERR_TER_REGISTRAR_SVC,
      );
    },
    [terminateRegistrarService, mutate],
  );

  const terminateProvider = useCallback(
    (r: Row) => {
      const payload = { providerServiceCid: r.contractId as ContractId<ProviderService> };
      return mutate(terminateProviderService, payload, SUC_TER_PROVIDER_SVC, ERR_TER_PROVIDER_SVC);
    },
    [terminateProviderService, mutate],
  );

  const acceptHolder = useCallback(
    (r: Row) => {
      if (!providerConfiguration.data) throw new Error('Provider configuration not loaded');
      const payload = {
        holderServiceRequestCid: r.contractId as ContractId<HolderServiceRequest>,
        providerConfigurationCid: providerConfiguration.data.contractId,
      };
      return mutate(acceptHolderServiceRequest, payload, SUC_ACC_HOLDER_SVC, ERR_ACC_HOLDER_SVC);
    },
    [acceptHolderServiceRequest, providerConfiguration, mutate],
  );

  const acceptRegistrar = useCallback(
    (r: Row) => {
      if (!providerConfiguration.data) throw new Error('Provider configuration not loaded');
      const payload = {
        registrarServiceRequestCid: r.contractId as ContractId<RegistrarServiceRequest>,
        providerConfigurationCid: providerConfiguration.data.contractId,
      };
      return mutate(
        acceptRegistrarServiceRequest,
        payload,
        SUC_ACC_REGISTRAR_SVC,
        ERR_ACC_REGISTRAR_SVC,
      );
    },
    [acceptRegistrarServiceRequest, providerConfiguration, mutate],
  );

  const acceptProvider = useCallback(
    (r: Row) => {
      if (!operatorConfiguration.data) throw new Error('Provider configuration not loaded');
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
        operatorConfigurationCid: operatorConfiguration.data.contractId,
      };
      return mutate(
        acceptProviderServiceRequest,
        payload,
        SUC_ACC_PROVIDER_SVC,
        ERR_ACC_PROVIDER_SVC,
      );
    },
    [acceptProviderServiceRequest, operatorConfiguration, mutate],
  );

  const rejectHolder = useCallback(
    (r: Row, rejectReason: string) => {
      const payload = {
        holderServiceRequestCid: r.contractId as ContractId<HolderServiceRequest>,
        rejectReason,
      };
      return mutate(rejectHolderServiceRequest, payload, SUC_REJ_HOLDER_SVC, ERR_REJ_HOLDER_SVC);
    },
    [rejectHolderServiceRequest, mutate],
  );

  const rejectRegistrar = useCallback(
    (r: Row, rejectReason: string) => {
      const payload = {
        registrarServiceRequestCid: r.contractId as ContractId<RegistrarServiceRequest>,
        rejectReason,
      };
      return mutate(
        rejectRegistrarServiceRequest,
        payload,
        SUC_REJ_REGISTRAR_SVC,
        ERR_REJ_REGISTRAR_SVC,
      );
    },
    [rejectRegistrarServiceRequest, mutate],
  );

  const rejectProvider = useCallback(
    (r: Row, rejectReason: string) => {
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
        rejectReason,
      };
      return mutate(
        rejectProviderServiceRequest,
        payload,
        SUC_REJ_PROVIDER_SVC,
        ERR_REJ_PROVIDER_SVC,
      );
    },
    [rejectProviderServiceRequest, mutate],
  );

  const cancelHolder = useCallback(
    (r: Row) => {
      const payload = { holderServiceRequestCid: r.contractId as ContractId<HolderServiceRequest> };
      return mutate(cancelHolderServiceRequest, payload, SUC_CAN_HOLDER_SVC, ERR_CAN_HOLDER_SVC);
    },
    [cancelHolderServiceRequest, mutate],
  );

  const cancelRegistrar = useCallback(
    (r: Row) => {
      const payload = {
        registrarServiceRequestCid: r.contractId as ContractId<RegistrarServiceRequest>,
      };
      return mutate(
        cancelRegistrarServiceRequest,
        payload,
        SUC_CAN_REGISTRAR_SVC,
        ERR_CAN_REGISTRAR_SVC,
      );
    },
    [cancelRegistrarServiceRequest, mutate],
  );

  const cancelProvider = useCallback(
    (r: Row) => {
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
      };
      return mutate(
        cancelProviderServiceRequest,
        payload,
        SUC_CAN_PROVIDER_SVC,
        ERR_CAN_PROVIDER_SVC,
      );
    },
    [cancelProviderServiceRequest, mutate],
  );

  const createServiceActions = (r: Row) => {
    if (r.serviceType === 'HolderService') return [{ name: 'Terminate', action: terminateHolder }];
    if (r.serviceType === 'RegistrarService')
      return [{ name: 'Terminate', action: terminateRegistrar }];
    if (r.serviceType === 'ProviderService')
      return [{ name: 'Terminate', action: terminateProvider }];
    return [];
  };

  const createRequestActions = (r: Row) => {
    if (r.operator === party && r.serviceType === 'ProviderService') {
      return [
        { name: 'Accept', action: acceptProvider },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectProvider(row, reason))),
        },
      ];
    }
    if (r.provider === party) {
      if (r.serviceType === 'HolderService')
        return [
          { name: 'Accept', action: acceptHolder },
          {
            name: 'Reject',
            action: (row: Row) =>
              Promise.resolve(setOnReject((reason: string) => rejectHolder(row, reason))),
          },
        ];
      if (r.serviceType === 'RegistrarService')
        return [
          { name: 'Accept', action: acceptRegistrar },
          {
            name: 'Reject',
            action: (row: Row) =>
              Promise.resolve(setOnReject((reason: string) => rejectRegistrar(row, reason))),
          },
        ];
      return [];
    }
    if (r.user === party) {
      if (r.serviceType === 'HolderService') return [{ name: 'Cancel', action: cancelHolder }];
      if (r.serviceType === 'RegistrarService')
        return [{ name: 'Cancel', action: cancelRegistrar }];
      if (r.serviceType === 'ProviderService') return [{ name: 'Cancel', action: cancelProvider }];
      return [];
    }
    return [];
  };

  const isLoading =
    holderServices.isLoading ||
    registrarServices.isLoading ||
    providerServices.isLoading ||
    holderServiceRequests.isLoading ||
    registrarServiceRequests.isLoading ||
    providerServiceRequests.isLoading ||
    !holderServices.data ||
    !registrarServices.data ||
    !providerServices.data ||
    !holderServiceRequests.data ||
    !registrarServiceRequests.data ||
    !providerServiceRequests.data;
  if (isLoading) return <Loading />;

  const serviceRows = holderServices.data
    .map((r) => ({
      contractId: r.contractId as string,
      serviceType: 'HolderService',
      operator: r.payload.operator,
      provider: r.payload.provider,
      user: r.payload.holder,
    }))
    .concat(
      registrarServices.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'RegistrarService',
        operator: r.payload.operator,
        provider: r.payload.provider,
        user: r.payload.registrar,
      })),
    )
    .concat(
      providerServices.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'ProviderService',
        operator: r.payload.operator,
        provider: '',
        user: r.payload.provider,
      })),
    );

  const requestRows = holderServiceRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      serviceType: 'HolderService',
      operator: r.payload.operator,
      provider: r.payload.provider,
      user: r.payload.holder,
    }))
    .concat(
      registrarServiceRequests.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'RegistrarService',
        operator: r.payload.operator,
        provider: r.payload.provider,
        user: r.payload.registrar,
      })),
    )
    .concat(
      providerServiceRequests.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'ProviderService',
        operator: r.payload.operator,
        provider: '',
        user: r.payload.provider,
      })),
    );

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataTable
            title="Services"
            variant="h3"
            columns={createHeaders(createServiceActions)}
            rows={serviceRows}
            rowKey={(a) => a.contractId}
            toolbar={Toolbar}
          />
        </Grid>
        <Grid item xs={6}>
          <DataTable
            title="Requests"
            variant="h3"
            columns={createHeaders(createRequestActions)}
            rows={requestRows}
            rowKey={(a) => a.contractId}
          />
        </Grid>
      </Grid>
    </>
  );
};
