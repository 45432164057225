import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import configReader from './config/config';
import { BrandingProvider } from './contexts/BrandingContext';

// Configuration specified in files that need to be replaced by users.
// To use this external configuration, add a script file to the web site that is loaded
// before the application code, and writes the config to the global "window" variable.
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
const externalConfig: unknown = (window as any).utility_config;
const config = configReader.loadConfig(externalConfig);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrandingProvider>
      <App config={config} />
    </BrandingProvider>
  </React.StrictMode>,
);
