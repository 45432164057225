import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import {
  UserService,
  UserServiceRequest,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { AsyncButton } from '../../components/Button/AsyncButton';
import Loading from '../../components/Loading';
import { QueryTable } from '../../components/Table/QueryTable';
import useAcceptUserServiceRequest from '../../hooks/mutations/credential/onboarding/useAcceptUserServiceRequest';
import useCancelUserServiceRequest from '../../hooks/mutations/credential/onboarding/useCancelUserServiceRequest';
import useRejectUserServiceRequest from '../../hooks/mutations/credential/onboarding/useRejectUserServiceRequest';
import useCreateUserServiceRequest from '../../hooks/mutations/credential/onboarding/useRequestUserService';
import useTerminateUserService from '../../hooks/mutations/credential/onboarding/useTerminateUserService';
import useMutate from '../../hooks/mutations/useMutate';
import useParties from '../../hooks/other/useParties';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import useUserServiceRequests from '../../hooks/queries/credential/onboarding/useUserServiceRequests';
import useUserServices from '../../hooks/queries/credential/onboarding/useUserServices';
import TemplateContract from '../../utils/TemplateContract';
import { prettyParty } from '../../utils/common';
import {
  ERR_ACC_USER_SVC,
  ERR_CAN_USER_SVC,
  ERR_REJ_USER_SVC,
  ERR_REQ_USER_SVC,
  ERR_TER_USER_SVC,
  SUC_ACC_USER_SVC,
  SUC_CAN_USER_SVC,
  SUC_REJ_USER_SVC,
  SUC_REQ_USER_SVC,
  SUC_TER_USER_SVC,
} from '../../utils/strings';

type ServiceRow = TemplateContract<UserService>;

type RequestRow = TemplateContract<UserServiceRequest>;

type ServiceAction = {
  name: string;
  action: (r: ServiceRow) => Promise<unknown>;
};

type RequestAction = {
  name: string;
  action: (r: RequestRow) => Promise<unknown>;
};

const createServiceHeaders: (
  createActions: (r: ServiceRow) => ServiceAction[],
) => GridColDef<ServiceRow>[] = (createActions) => {
  const renderActionCell = (params: GridRenderCellParams<ServiceRow>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'user',
      headerName: 'User',
      valueGetter: (params) => prettyParty(params.row.payload.user),
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const createRequestHeaders: (
  createActions: (r: RequestRow) => RequestAction[],
) => GridColDef<RequestRow>[] = (createActions) => {
  const renderActionCell = (params: GridRenderCellParams<RequestRow>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'user',
      headerName: 'User',
      valueGetter: (params) => prettyParty(params.row.payload.user),
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const { party } = useParties();
  const userService = useUserService();
  const userServiceRequests = useUserServiceRequests();
  const createUserServiceRequest = useCreateUserServiceRequest();
  const mutate = useMutate();

  const request = useCallback(() => {
    return mutate(createUserServiceRequest, undefined, SUC_REQ_USER_SVC, ERR_REQ_USER_SVC);
  }, [createUserServiceRequest, mutate]);

  const isLoading = userService.isLoading || userServiceRequests.isLoading;
  if (isLoading) return null;

  const userServiceRequest = userServiceRequests.data!.find((r) => r.payload.user === party);
  const canRequest = !userService.data && !userServiceRequest; // TODO: Make this more robust

  return (
    <GridToolbarContainer>
      <AsyncButton onClick={request} disabled={!canRequest}>
        Request credential user service
      </AsyncButton>
    </GridToolbarContainer>
  );
};

export const Onboarding = () => {
  const { party } = useParties();
  const userServices = useUserServices();
  const userServiceRequests = useUserServiceRequests();
  const terminateUserService = useTerminateUserService();
  const approveUserServiceRequest = useAcceptUserServiceRequest();
  const rejectUserServiceRequest = useRejectUserServiceRequest();
  const cancelUserServiceRequest = useCancelUserServiceRequest();
  const mutate = useMutate();

  const terminate = async (r: ServiceRow) => {
    const payload = { userServiceCid: r.contractId };
    return mutate(terminateUserService, payload, SUC_TER_USER_SVC, ERR_TER_USER_SVC);
  };

  const approve = async (r: RequestRow) => {
    const payload = { userServiceRequestCid: r.contractId };
    return mutate(approveUserServiceRequest, payload, SUC_ACC_USER_SVC, ERR_ACC_USER_SVC);
  };

  const reject = async (r: RequestRow) => {
    const payload = { userServiceRequestCid: r.contractId };
    return mutate(rejectUserServiceRequest, payload, SUC_REJ_USER_SVC, ERR_REJ_USER_SVC);
  };

  const cancel = async (r: RequestRow) => {
    const payload = { userServiceRequestCid: r.contractId };
    return mutate(cancelUserServiceRequest, payload, SUC_CAN_USER_SVC, ERR_CAN_USER_SVC);
  };

  const createServiceActions = (r: ServiceRow) => {
    if (r.payload.operator === party) return [{ name: 'Terminate', action: terminate }];
    return [];
  };

  const createRequestActions = (r: RequestRow) => {
    if (r.payload.operator === party)
      return [
        { name: 'Approve', action: approve },
        { name: 'Reject', action: reject },
      ];
    if (r.payload.user === party) return [{ name: 'Cancel', action: cancel }];
    return [];
  };

  const isLoading = userServices.isLoading || userServiceRequests.isLoading;
  if (isLoading) return <Loading />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <QueryTable
          title="Services"
          variant="h3"
          columns={createServiceHeaders(createServiceActions)}
          rowQuery={userServices}
          rowKey={(a) => a.contractId}
          toolbar={Toolbar}
        />
      </Grid>
      <Grid item xs={6}>
        <QueryTable
          title="Requests"
          variant="h3"
          columns={createRequestHeaders(createRequestActions)}
          rowQuery={userServiceRequests}
          rowKey={(r) => r.contractId}
        />
      </Grid>
    </Grid>
  );
};
