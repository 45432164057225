import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TraderService,
  TraderService_Terminate_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTerminateTraderService: () => UseMutationResult<
  TraderService_Terminate_Result,
  string,
  { traderServiceCid: ContractId<TraderService> }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();

  return useMutation({
    mutationKey: ['terminateTradingTraderService', ledgerApi],
    mutationFn: async ({ traderServiceCid }) => {
      return ledgerApi.terminateTradingTraderService(traderServiceCid, party);
    },
  });
};

export default useTerminateTraderService;
