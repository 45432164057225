import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRegistryProviderConfiguration: () => UseQueryResult<
  TemplateContract<ProviderConfiguration> | null,
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryRegistryProviderConfiguration', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryRegistryProviderConfiguration(operator, party);
    },
  });
};

export default useRegistryProviderConfiguration;
