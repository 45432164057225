import { Route, Routes } from 'react-router-dom';
import ErrorWrapper from '../components/ErrorWrapper';
import Header from '../components/Header/Header';
import Loading from '../components/Loading';
import Sidebar from '../components/Sidebar/Sidebar';
import useOperatorService from '../hooks/queries/trading/onboarding/useOperatorService';
import useProviderService from '../hooks/queries/trading/onboarding/useProviderService';
import useTraderService from '../hooks/queries/trading/onboarding/useTraderService';
import { Configurations } from '../pages/trading/configuration/Configurations';
import { Onboarding } from '../pages/trading/onboarding/Onboarding';
import { Trades } from '../pages/trading/trader/Trades';
import { SidebarEntry } from '../routes/types';
import useStyles from './styles';

const sidebarEntries: SidebarEntry[] = [
  {
    label: 'Onboarding',
    route: { path: 'onboarding', element: <Onboarding /> },
    subEntries: [],
  },
];

const configurationEntry: SidebarEntry = {
  label: 'Configurations',
  route: { path: 'configurations', element: <Configurations /> },
  subEntries: [],
};

const traderEntries: SidebarEntry[] = [
  {
    label: 'Trades',
    route: { path: 'trades', element: <Trades /> },
    subEntries: [],
  },
];

export const Trading: React.FC = () => {
  const { classes } = useStyles();
  const providerService = useProviderService();
  const operatorService = useOperatorService();
  const traderService = useTraderService();

  const isLoading =
    operatorService.isLoading || providerService.isLoading || traderService.isLoading;
  if (isLoading) return <Loading />;

  const isOperator = !!operatorService.data;
  const isProvider = !!providerService.data;
  const isTrader = !!traderService.data;

  let entries = sidebarEntries;
  if (isOperator || isProvider) {
    entries = entries.concat([configurationEntry]);
  }
  if (isOperator || isProvider || isTrader) {
    entries = entries.concat(traderEntries);
  }

  const routes = entries.flatMap((e) =>
    [e.route].concat(e.subEntries ? e.subEntries.map((se) => se.route) : []),
  );

  return (
    <ErrorWrapper>
      <div className={classes.root}>
        <Header appName="Trading" />
        <Sidebar entries={entries} />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Routes>
            {routes.map((r) => (
              <Route key={r.path} path={r.path} element={r.element} />
            ))}
          </Routes>
        </div>
      </div>
    </ErrorWrapper>
  );
};
