import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  RejectedTransfer,
  TransferRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectTransferRequest: () => UseMutationResult<
  ContractId<RejectedTransfer>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    transferRequestCid: ContractId<TransferRequest>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTransferRequest', ledgerApi],
    mutationFn: async ({ holderServiceCid, transferRequestCid, reason }) => {
      return ledgerApi.rejectTransferRequest(holderServiceCid, transferRequestCid, reason);
    },
  });
};

export default useRejectTransferRequest;
