import { Outlet } from 'react-router-dom';
import { UtilityConfig } from '../config/config';
import { PartiesProvider } from '../contexts/PartiesContext';
import useUserState from '../hooks/other/useUserState';
import Login from '../pages/Login';

const AuthCheck: React.FC<{ config: UtilityConfig }> = ({ config }) => {
  const { loggedInUser } = useUserState();

  if (!loggedInUser) {
    return <Login />;
  }

  return (
    <PartiesProvider operator={config.operator}>
      <Outlet />
    </PartiesProvider>
  );
};

export default AuthCheck;
