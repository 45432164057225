import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import {
  BurnRequest,
  RejectedBurn,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Burn';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectBurnRequest: () => UseMutationResult<
  ContractId<RejectedBurn>,
  string,
  {
    registrarServiceCid: ContractId<RegistrarService>;
    burnRequestCid: ContractId<BurnRequest>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectBurnRequest', ledgerApi],
    mutationFn: async ({ registrarServiceCid, burnRequestCid, reason }) => {
      return ledgerApi.rejectBurnRequest(registrarServiceCid, burnRequestCid, reason);
    },
  });
};

export default useRejectBurnRequest;
