import { useCallback, useState } from 'react';
import { InstrumentKey } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { emptyMap } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useCreateOperatorConfiguration from '../../../hooks/mutations/registry/configuration/useCreateOperatorConfiguration';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import { ERR_CRE_OP_CONF, SUC_CRE_OP_CONF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateOperatorConfigurationDialog = ({ open, onClose }: DialogProps) => {
  const [providerRequirementProperty, setProviderRequirementProperty] = useState<string>('');
  const [providerRequirementValue, setProviderRequirementValue] = useState<string>('');

  const { operator, party } = useParties();
  const operatorService = useOperatorService();
  const mutate = useMutate();
  const createOperatorConfiguration = useCreateOperatorConfiguration();

  const resetState = useCallback(() => {
    setProviderRequirementProperty('');
    setProviderRequirementValue('');
  }, [setProviderRequirementProperty, setProviderRequirementValue]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!operatorService.data) return;
    const payload = {
      operatorServiceCid: operatorService.data.contractId,
      providerRequirement: {
        operator,
        issuer: party,
        requiredClaims: [{ _1: providerRequirementProperty, _2: providerRequirementValue }],
      },
      instrumentMapping: emptyMap<string, InstrumentKey>(), // TODO: Implement forms to support multiple entries.
    };
    await mutate(createOperatorConfiguration, payload, SUC_CRE_OP_CONF, ERR_CRE_OP_CONF);
    handleClose();
  }, [
    operatorService,
    createOperatorConfiguration,
    operator,
    party,
    providerRequirementProperty,
    providerRequirementValue,
    handleClose,
    mutate,
  ]);

  const { isLoading } = operatorService;
  if (isLoading) return null;

  const disableSubmit = !providerRequirementProperty || !providerRequirementValue;
  return (
    <FormDialog
      open={open}
      title="Create Operator Configuration"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Create"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput
        label="Provider Requirement Property"
        value={providerRequirementProperty}
        setValue={setProviderRequirementProperty}
        required
      />
      <TextInput
        label="Provider Requirement Value"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
    </FormDialog>
  );
};
