import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  AmuletRules_ComputeFeesResult,
  TransferContext,
  TransferOutput,
} from '@daml.js/splice-amulet-0.1.3/lib/Splice/AmuletRules';
import { emptyMap } from '@daml/types';
import { appTransferContextWithDisclosures } from '../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../other/useLedgerApiClient';
import useParties from '../other/useParties';
import { useScanProxyApiClient } from '../other/useScanProxyApiClient';

const useCalculateFees: () => UseMutationResult<
  { res: AmuletRules_ComputeFeesResult; latestAmuletPrice: string },
  string,
  { amountUsd: number; holder: string },
  unknown
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();
  const { party: sender } = useParties();

  return useMutation({
    mutationKey: ['calculateFees', ledgerApi],
    mutationFn: async ({ amountUsd, holder }) => {
      const { appTransferContext, disclosedContracts, latestAmuletPrice } =
        await appTransferContextWithDisclosures(scanProxyApi, 30);
      const amountCc = amountUsd / parseFloat(latestAmuletPrice);
      const context: TransferContext = {
        openMiningRound: appTransferContext.openMiningRound,
        featuredAppRight: null,
        issuingMiningRounds: emptyMap(),
        validatorRights: emptyMap(),
      };
      const transferOutput: TransferOutput = {
        receiver: holder,
        receiverFeeRatio: '0.0',
        amount: amountCc.toString(),
        lock: null,
      };

      const feeValue = ledgerApi.calculateFees(
        appTransferContext.amuletRules,
        context,
        sender,
        [transferOutput],
        disclosedContracts,
        latestAmuletPrice,
      );
      return feeValue;
    },
  });
};

export default useCalculateFees;
