import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Direction,
  Trade,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import ToggleInput from '../../../components/Form/ToggleInput';
import useOfferTrade from '../../../hooks/mutations/trading/trade/useOfferTrade';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import { ERR_OFF_TRADE, SUC_OFF_TRADE } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const OfferTradeDialog = ({ open, onClose }: DialogProps) => {
  const [direction, setDirection] = useState<Direction>(Direction.Buy);
  const [instrumentId, setInstrumentId] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [currencyId, setCurrencyId] = useState<string>('');
  const [pricePerUnit, setPricePerUnit] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [counterparty, setCounterparty] = useState<string>('');
  const [offerorLabel, setOfferorLabel] = useState<string>('');

  const { party } = useParties();
  const traderService = useTraderService();
  const offerTrade = useOfferTrade();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setDirection(Direction.Buy);
    setInstrumentId('');
    setAmount('');
    setCurrencyId('');
    setPricePerUnit('');
    setDescription('');
    setCounterparty('');
    setOfferorLabel('');
  }, [
    setDirection,
    setInstrumentId,
    setAmount,
    setCurrencyId,
    setPricePerUnit,
    setDescription,
    setCounterparty,
    setOfferorLabel,
  ]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!traderService.data) throw new Error('Trader service not found');
    const trade: Trade = {
      operator: traderService.data.payload.operator,
      provider: traderService.data.payload.provider,
      buyer: direction === Direction.Buy ? party : counterparty,
      seller: direction === Direction.Sell ? party : counterparty,
      id: uuidv4(),
      description,
      instrumentId,
      amount,
      currencyId,
    };
    const payload = {
      traderServiceCid: traderService.data.contractId,
      trade,
      direction,
      pricePerUnit,
      offerorLabel,
    };
    await mutate(offerTrade, payload, SUC_OFF_TRADE, ERR_OFF_TRADE);
    handleClose();
  }, [
    traderService,
    direction,
    party,
    counterparty,
    description,
    instrumentId,
    amount,
    currencyId,
    pricePerUnit,
    offerorLabel,
    offerTrade,
    mutate,
    handleClose,
  ]);

  const isLoading = traderService.isLoading || !traderService.data;
  if (isLoading) return null;

  const directionValues = [
    { value: Direction.Buy, display: 'Buy' },
    { value: Direction.Sell, display: 'Sell' },
  ];
  const disableSubmit =
    !description || !instrumentId || !amount || !currencyId || !counterparty || !pricePerUnit;
  return (
    <FormDialog
      open={open}
      title="Offer Trade"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Offer"
      isSubmitDisabled={disableSubmit}
    >
      <ToggleInput
        label="Direction"
        value={direction}
        setValue={(s) => setDirection(s as Direction)}
        values={directionValues}
      />
      <TextInput label="Instrument" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput label="Amount" value={amount} setValue={setAmount} required />
      <TextInput label="Currency" value={currencyId} setValue={setCurrencyId} required />
      <TextInput label="Price per unit" value={pricePerUnit} setValue={setPricePerUnit} required />
      <TextInput label="Description" value={description} setValue={setDescription} required />
      <TextInput label="Label" value={offerorLabel} setValue={setOfferorLabel} />
      <TextInput label="Counterparty" value={counterparty} setValue={setCounterparty} required />
    </FormDialog>
  );
};
