import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { OperatorConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Operator';
import { OperatorService } from '@daml.js/utility-registry-operator-v0/lib/Utility/Registry/Operator/V0/Service/Operator';
import { InstrumentKey } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { ContractId, Map } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCreateOperatorConfiguration: () => UseMutationResult<
  ContractId<OperatorConfiguration>,
  string,
  {
    operatorServiceCid: ContractId<OperatorService>;
    providerRequirement: PartyCredentialRequirement;
    instrumentMapping: Map<string, InstrumentKey>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['createRegistryOperatorConfiguration', ledgerApi],
    mutationFn: async ({ operatorServiceCid, providerRequirement, instrumentMapping }) => {
      return ledgerApi.createRegistryOperatorConfiguration(
        operatorServiceCid,
        providerRequirement,
        instrumentMapping,
      );
    },
  });
};

export default useCreateOperatorConfiguration;
