import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Transfer,
  TransferOffer,
  TransferRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useCancelTransferOffer from '../../../hooks/mutations/registry/transfer/useCancelTransferOffer';
import useCancelTransferRequest from '../../../hooks/mutations/registry/transfer/useCancelTransferRequest';
import useRejectTransferOffer from '../../../hooks/mutations/registry/transfer/useRejectTransferOffer';
import useRejectTransferRequest from '../../../hooks/mutations/registry/transfer/useRejectTransferRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import useAcceptedTransfers from '../../../hooks/queries/registry/transfer/useAcceptedTransfers';
import useExecutedTransfers from '../../../hooks/queries/registry/transfer/useExecutedTransfers';
import useFailedTransfers from '../../../hooks/queries/registry/transfer/useFailedTransfers';
import useRejectedTransfers from '../../../hooks/queries/registry/transfer/useRejectedTransfers';
import useTransferOffers from '../../../hooks/queries/registry/transfer/useTransferOffers';
import useTransferRequests from '../../../hooks/queries/registry/transfer/useTransferRequests';
import useHoldings from '../../../hooks/queries/registry/useHoldings';
import { prettyParty, shorten } from '../../../utils/common';
import {
  ERR_CAN_XFER_OFF,
  ERR_CAN_XFER_REQ,
  ERR_REJ_XFER_OFF,
  ERR_REJ_XFER_REQ,
  SUC_CAN_XFER_OFF,
  SUC_CAN_XFER_REQ,
  SUC_REJ_XFER_OFF,
  SUC_REJ_XFER_REQ,
} from '../../../utils/strings';
import { AcceptTransferOfferDialog } from './AcceptTransferOfferDialog';
import { AcceptTransferRequestDialog } from './AcceptTransferRequestDialog';

export type Row = {
  contractId: string;
  state: string;
  payload: Transfer;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 80,
      filterable: false,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 100,
      filterable: false,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      valueGetter: (params) => prettyParty(params.row.payload.provider),
      flex: 100,
      filterable: false,
    },
    {
      field: 'sender',
      headerName: 'Sender',
      valueGetter: (params) => prettyParty(params.row.payload.sender),
      flex: 100,
      filterable: false,
    },
    {
      field: 'receiver',
      headerName: 'Receiver',
      valueGetter: (params) => prettyParty(params.row.payload.receiver),
      flex: 100,
      filterable: false,
    },
    {
      field: 'registrar',
      headerName: 'Registrar',
      valueGetter: (params) => prettyParty(params.row.payload.registrar),
      flex: 100,
      filterable: false,
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => params.row.payload.amount,
      flex: 150,
      filterable: false,
    },
    {
      field: 'batch',
      headerName: 'Batch (size)',
      valueGetter: (params) =>
        `${shorten(params.row.payload.batch.id)} (${params.row.payload.batch.size})`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

export const Transfers = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const [transferRequest, setTransferRequest] = useState<Row | undefined>();
  const [transferOffer, setTransferOffer] = useState<Row | undefined>();

  const { party } = useParties();
  const holderService = useHolderService();
  const operatorService = useOperatorService();
  const registrarServices = useRegistrarServices();
  const holdings = useHoldings();
  const transferRequests = useTransferRequests();
  const transferOffers = useTransferOffers();
  const acceptedTransfers = useAcceptedTransfers();
  const rejectedTransfers = useRejectedTransfers();
  const executedTransfers = useExecutedTransfers();
  const failedTransfers = useFailedTransfers();
  const rejectTransferOffer = useRejectTransferOffer();
  const rejectTransferRequest = useRejectTransferRequest();
  const cancelTransferOffer = useCancelTransferOffer();
  const cancelTransferRequest = useCancelTransferRequest();
  const mutate = useMutate();

  const rejectTransferOff = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        transferOfferCid: row.contractId as ContractId<TransferOffer>,
        reason,
      };
      return mutate(rejectTransferOffer, payload, SUC_REJ_XFER_OFF, ERR_REJ_XFER_OFF);
    },
    [holderService, rejectTransferOffer, mutate],
  );

  const cancelTransferOff = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        transferOfferCid: row.contractId as ContractId<TransferOffer>,
      };
      return mutate(cancelTransferOffer, payload, SUC_CAN_XFER_OFF, ERR_CAN_XFER_OFF);
    },
    [holderService, cancelTransferOffer, mutate],
  );

  const rejectTransferReq = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        transferRequestCid: row.contractId as ContractId<TransferRequest>,
        reason,
      };
      return mutate(rejectTransferRequest, payload, SUC_REJ_XFER_REQ, ERR_REJ_XFER_REQ);
    },
    [holderService, rejectTransferRequest, mutate],
  );

  const cancelTransferReq = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        transferRequestCid: row.contractId as ContractId<TransferRequest>,
      };
      return mutate(cancelTransferRequest, payload, SUC_CAN_XFER_REQ, ERR_CAN_XFER_REQ);
    },
    [holderService, cancelTransferRequest, mutate],
  );

  const isLoading =
    holderService.isLoading ||
    operatorService.isLoading ||
    registrarServices.isLoading ||
    holdings.isLoading ||
    transferRequests.isLoading ||
    transferOffers.isLoading ||
    acceptedTransfers.isLoading ||
    rejectedTransfers.isLoading ||
    executedTransfers.isLoading ||
    failedTransfers.isLoading ||
    !holdings.data ||
    !transferRequests.data ||
    !transferOffers.data ||
    !acceptedTransfers.data ||
    !rejectedTransfers.data ||
    !executedTransfers.data ||
    !failedTransfers.data;
  if (isLoading) return <Loading />;

  const rows = transferRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      state: 'Requested',
      payload: r.payload.transfer,
    }))
    .concat(
      transferOffers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Offered',
        payload: r.payload.transfer,
      })),
    )
    .concat(
      acceptedTransfers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Accepted',
        payload: r.payload.transfer,
      })),
    )
    .concat(
      rejectedTransfers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Rejected',
        payload: r.payload.transfer,
      })),
    )
    .concat(
      executedTransfers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Executed',
        payload: r.payload.transfer,
      })),
    )
    .concat(
      failedTransfers.data.map((r) => ({
        contractId: r.contractId as string,
        state: `Failed: ${r.payload.reason}`,
        payload: r.payload.transfer,
      })),
    );

  const isHolder = !!holderService.data;
  const createActions = (r: Row) => {
    let actions: Action[] = [];
    if (isHolder && r.state === 'Offered' && r.payload.receiver === party)
      actions = actions.concat([
        { name: 'Accept', action: () => Promise.resolve(setTransferOffer(r)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectTransferOff(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Offered' && r.payload.sender === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelTransferOff }]);
    if (isHolder && r.state === 'Requested' && r.payload.sender === party)
      actions = actions.concat([
        { name: 'Accept', action: () => Promise.resolve(setTransferRequest(r)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectTransferReq(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Requested' && r.payload.receiver === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelTransferReq }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      {!!transferRequest && (
        <AcceptTransferRequestDialog
          open={!!transferRequest}
          onClose={() => setTransferRequest(undefined)}
          request={transferRequest}
        />
      )}
      {!!transferOffer && (
        <AcceptTransferOfferDialog
          open={!!transferOffer}
          onClose={() => setTransferOffer(undefined)}
          offer={transferOffer}
        />
      )}
      <DataTable
        title="Transfers"
        variant="h3"
        columns={createHeaders(createActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
      />
    </>
  );
};
