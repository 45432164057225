import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { RegistrarServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelRegistrarServiceRequest: () => UseMutationResult<
  void,
  string,
  { registrarServiceRequestCid: ContractId<RegistrarServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelRegistryRegistrarServiceRequest', ledgerApi],
    mutationFn: async ({ registrarServiceRequestCid }) => {
      return ledgerApi.cancelRegistryRegistrarServiceRequest(registrarServiceRequestCid);
    },
  });
};

export default useCancelRegistrarServiceRequest;
