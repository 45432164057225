import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ExecutedLock } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useExecutedLocks: () => UseQueryResult<TemplateContract<ExecutedLock>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useQuery({
    queryKey: ['queryExecutedLocks', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryExecutedLocks(operator);
    },
  });
};

export default useExecutedLocks;
