import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CredentialOffer } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useCredentialOffers: () => UseQueryResult<
  TemplateContract<CredentialOffer>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryCredentialOffers', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryCredentialOffers(operator);
    },
  });
};

export default useCredentialOffers;
