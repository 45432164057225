import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { HolderServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useHolderServiceRequests: () => UseQueryResult<
  TemplateContract<HolderServiceRequest>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryRegistryHolderServiceRequests', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryRegistryHolderServiceRequests(operator);
    },
  });
};

export default useHolderServiceRequests;
