import { z } from 'zod';
import ConfigReader from './ConfigReader';

export enum Algorithm {
  RS256 = 'rs-256',
  HS256UNSAFE = 'hs-256-unsafe',
}

const hs256UnsafeSchema = z.object({
  algorithm: z.literal(Algorithm.HS256UNSAFE),
  secret: z.string(),
  audience: z.string(),
});
export type Hs256UnsafeAuth = z.infer<typeof hs256UnsafeSchema>;

const rs256Schema = z.object({
  algorithm: z.literal(Algorithm.RS256),
  authority: z.string().url(),
  client_id: z.string(),
  audience: z.string(),
});
export type Rs256Auth = z.infer<typeof rs256Schema>;

const authSchema = z.discriminatedUnion('algorithm', [hs256UnsafeSchema, rs256Schema]);
export type AuthConf = z.infer<typeof authSchema>;

const configSpec = z.object({
  jsonApi: z.object({
    url: z.string().url(),
  }),
  scanProxyApi: z.object({
    url: z.string().url(),
  }),
  auth: authSchema,
  modules: z.array(z.string()),
  operator: z.string(),
});
export type UtilityConfig = z.infer<typeof configSpec>;

export const isHs256UnsafeAuthConfig = (auth: AuthConf): auth is Hs256UnsafeAuth => {
  return auth.algorithm === Algorithm.HS256UNSAFE;
};
export const isRs256AuthConfig = (auth: AuthConf): auth is Rs256Auth => {
  return auth.algorithm === Algorithm.RS256;
};

const configReader = new ConfigReader(configSpec);

export default configReader;
