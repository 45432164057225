import { useState } from 'react';
import { Chip } from '@mui/material';
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { OperatorConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Operator';
import { SyncButton } from '../../../components/Button/SyncButton';
import Loading from '../../../components/Loading';
import { QueryTable } from '../../../components/Table/QueryTable';
import useRegistryOperatorConfigurations from '../../../hooks/queries/registry/configuration/useRegistryOperatorConfigurations';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import TemplateContract from '../../../utils/TemplateContract';
import { prettyParty } from '../../../utils/common';
import { CreateOperatorConfigurationDialog } from './CreateOperatorConfigurationDialog';

type Row = TemplateContract<OperatorConfiguration>;

const createHeaders: () => GridColDef<Row>[] = () => {
  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'providerRequirement',
      headerName: 'Provider Requirement',
      renderCell: (params) => (
        <>
          {(
            params.row.payload.providerRequirement as PartyCredentialRequirement
          ).requiredClaims.map((c) => (
            <>
              <Chip key={c._1 + c._2} label={c._1} />
              <Chip key={c._1 + c._2} label={c._2} />
            </>
          ))}
        </>
      ),
      flex: 250,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [createOperatorConfigurationIsOpen, setCreateOperatorConfigurationIsOpen] = useState(false);
  const operatorService = useOperatorService();

  const { isLoading } = operatorService;
  if (isLoading) return null;

  return (
    <GridToolbarContainer>
      {createOperatorConfigurationIsOpen && (
        <CreateOperatorConfigurationDialog
          open={createOperatorConfigurationIsOpen}
          onClose={() => setCreateOperatorConfigurationIsOpen(false)}
        />
      )}
      <SyncButton
        onClick={() => setCreateOperatorConfigurationIsOpen(true)}
        disabled={!operatorService.data}
      >
        Create operator configuration
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const OperatorConfigurations = () => {
  const configurations = useRegistryOperatorConfigurations();

  const isLoading = configurations.isLoading || !configurations.data;
  if (isLoading) return <Loading />;

  return (
    <QueryTable
      title="Operator Configurations"
      variant="h3"
      columns={createHeaders()}
      rowQuery={configurations}
      rowKey={(a) => a.contractId}
      toolbar={Toolbar}
    />
  );
};
