import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Lock,
  LockOffer,
  LockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptLockOffer from '../../../hooks/mutations/registry/lock/useAcceptLockOffer';
import useCancelLockOffer from '../../../hooks/mutations/registry/lock/useCancelLockOffer';
import useCancelLockRequest from '../../../hooks/mutations/registry/lock/useCancelLockRequest';
import useRejectLockOffer from '../../../hooks/mutations/registry/lock/useRejectLockOffer';
import useRejectLockRequest from '../../../hooks/mutations/registry/lock/useRejectLockRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useAcceptedLocks from '../../../hooks/queries/registry/lock/useAcceptedLocks';
import useExecutedLocks from '../../../hooks/queries/registry/lock/useExecutedLocks';
import useLockOffers from '../../../hooks/queries/registry/lock/useLockOffers';
import useLockRequests from '../../../hooks/queries/registry/lock/useLockRequests';
import useRejectedLocks from '../../../hooks/queries/registry/lock/useRejectedLocks';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import useHoldings from '../../../hooks/queries/registry/useHoldings';
import { prettyParty, shorten } from '../../../utils/common';
import {
  ERR_ACC_LOCK_OFF,
  ERR_CAN_LOCK_OFF,
  ERR_CAN_LOCK_REQ,
  ERR_REJ_LOCK_OFF,
  ERR_REJ_LOCK_REQ,
  SUC_ACC_LOCK_OFF,
  SUC_CAN_LOCK_OFF,
  SUC_CAN_LOCK_REQ,
  SUC_REJ_LOCK_OFF,
  SUC_REJ_LOCK_REQ,
} from '../../../utils/strings';
import { AcceptLockRequestDialog } from './AcceptLockRequestDialog';

export type Row = {
  contractId: string;
  state: string;
  payload: Lock;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 150,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 150,
      filterable: false,
    },
    {
      field: 'locker',
      headerName: 'locker',
      valueGetter: (params) => prettyParty(params.row.payload.locker),
      flex: 150,
      filterable: false,
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => params.row.payload.amount,
      flex: 150,
      filterable: false,
    },
    {
      field: 'batch',
      headerName: 'Batch (size)',
      valueGetter: (params) =>
        `${shorten(params.row.payload.batch.id)} (${params.row.payload.batch.size})`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

export const Locks = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const [lockRequest, setLockRequest] = useState<Row | undefined>();

  const { party } = useParties();
  const holderService = useHolderService();
  const operatorService = useOperatorService();
  const registrarServices = useRegistrarServices();
  const holdings = useHoldings();
  const lockRequests = useLockRequests();
  const lockOffers = useLockOffers();
  const acceptedLocks = useAcceptedLocks();
  const rejectedLocks = useRejectedLocks();
  const executedLocks = useExecutedLocks();
  const acceptLockOffer = useAcceptLockOffer();
  const rejectLockOffer = useRejectLockOffer();
  const rejectLockRequest = useRejectLockRequest();
  const cancelLockOffer = useCancelLockOffer();
  const cancelLockRequest = useCancelLockRequest();
  const mutate = useMutate();

  const acceptLockOff = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        lockOfferCid: row.contractId as ContractId<LockOffer>,
      };
      return mutate(acceptLockOffer, payload, SUC_ACC_LOCK_OFF, ERR_ACC_LOCK_OFF);
    },
    [holderService, acceptLockOffer, mutate],
  );

  const rejectLockOff = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        lockOfferCid: row.contractId as ContractId<LockOffer>,
        reason,
      };
      return mutate(rejectLockOffer, payload, SUC_REJ_LOCK_OFF, ERR_REJ_LOCK_OFF);
    },
    [holderService, rejectLockOffer, mutate],
  );

  const cancelLockOff = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        lockOfferCid: row.contractId as ContractId<LockOffer>,
      };
      return mutate(cancelLockOffer, payload, SUC_CAN_LOCK_OFF, ERR_CAN_LOCK_OFF);
    },
    [holderService, cancelLockOffer, mutate],
  );

  const rejectLockReq = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        lockRequestCid: row.contractId as ContractId<LockRequest>,
        reason,
      };
      return mutate(rejectLockRequest, payload, SUC_REJ_LOCK_REQ, ERR_REJ_LOCK_REQ);
    },
    [holderService, rejectLockRequest, mutate],
  );

  const cancelLockReq = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        lockRequestCid: row.contractId as ContractId<LockRequest>,
      };
      return mutate(cancelLockRequest, payload, SUC_CAN_LOCK_REQ, ERR_CAN_LOCK_REQ);
    },
    [holderService, cancelLockRequest, mutate],
  );

  const isLoading =
    holderService.isLoading ||
    operatorService.isLoading ||
    registrarServices.isLoading ||
    holdings.isLoading ||
    lockRequests.isLoading ||
    lockOffers.isLoading ||
    acceptedLocks.isLoading ||
    rejectedLocks.isLoading ||
    executedLocks.isLoading ||
    !holdings.data ||
    !lockRequests.data ||
    !lockOffers.data ||
    !acceptedLocks.data ||
    !rejectedLocks.data ||
    !executedLocks.data;
  if (isLoading) return <Loading />;

  const rows = lockRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      state: 'Requested',
      payload: r.payload.lock,
    }))
    .concat(
      lockOffers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Offered',
        payload: r.payload.lock,
      })),
    )
    .concat(
      acceptedLocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Accepted',
        payload: r.payload.lock,
      })),
    )
    .concat(
      rejectedLocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Rejected',
        payload: r.payload.lock,
      })),
    )
    .concat(
      executedLocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Executed',
        payload: r.payload.lock,
      })),
    );

  const isHolder = !!holderService.data;
  const createActions = (r: Row) => {
    let actions: Action[] = [];
    if (isHolder && r.state === 'Offered' && r.payload.locker === party)
      actions = actions.concat([
        { name: 'Accept', action: acceptLockOff },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectLockOff(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Offered' && r.payload.holder === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelLockOff }]);
    if (isHolder && r.state === 'Requested' && r.payload.holder === party)
      actions = actions.concat([
        { name: 'Accept', action: () => Promise.resolve(setLockRequest(r)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectLockReq(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Requested' && r.payload.locker === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelLockReq }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      {!!lockRequest && (
        <AcceptLockRequestDialog
          open={!!lockRequest}
          onClose={() => setLockRequest(undefined)}
          request={lockRequest}
        />
      )}
      <DataTable
        title="Locks"
        variant="h3"
        columns={createHeaders(createActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
      />
    </>
  );
};
