import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ExecutedUnlock } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useExecutedUnlocks: () => UseQueryResult<TemplateContract<ExecutedUnlock>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useQuery({
    queryKey: ['queryExecutedUnlocks', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryExecutedUnlocks(operator);
    },
  });
};

export default useExecutedUnlocks;
