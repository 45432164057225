import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  RejectedLock,
  LockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectLockRequest: () => UseMutationResult<
  ContractId<RejectedLock>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    lockRequestCid: ContractId<LockRequest>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectLockRequest', ledgerApi],
    mutationFn: async ({ holderServiceCid, lockRequestCid, reason }) => {
      return ledgerApi.rejectLockRequest(holderServiceCid, lockRequestCid, reason);
    },
  });
};

export default useRejectLockRequest;
