import { useCallback, useState } from 'react';
import {
  Transfer,
  TransferOffer,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptTransferOffer from '../../../hooks/mutations/registry/transfer/useAcceptTransferOffer';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import { ERR_ACC_XFER_REQ, SUC_ACC_XFER_REQ } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  offer: { contractId: string; state: string; payload: Transfer };
};

export const AcceptTransferOfferDialog = ({ open, onClose, offer }: DialogProps) => {
  const [registrar, setRegistrar] = useState<string>('');
  const [receiverLabel, setReceiverLabel] = useState<string>('');

  const holderService = useHolderService();
  const acceptTransferOffer = useAcceptTransferOffer();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setRegistrar('');
    setReceiverLabel('');
  }, [setRegistrar, setReceiverLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data || !registrar) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      transferOfferCid: offer.contractId as ContractId<TransferOffer>,
      receiverLabel,
    };
    await mutate(acceptTransferOffer, payload, SUC_ACC_XFER_REQ, ERR_ACC_XFER_REQ);
    handleClose();
  }, [
    holderService,
    registrar,
    receiverLabel,
    offer.contractId,
    handleClose,
    mutate,
    acceptTransferOffer,
  ]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !registrar;
  return (
    <FormDialog
      open={open}
      title="Accept Transfer Offer"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
      <TextInput label="Label" value={receiverLabel} setValue={setReceiverLabel} />
    </FormDialog>
  );
};
