import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AcceptedTrade } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptedTrades: () => UseQueryResult<TemplateContract<AcceptedTrade>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryAcceptedTradesService', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryAcceptedTrades(operator);
    },
  });
};

export default useAcceptedTrades;
