import * as openapi from 'validator-scan-proxy-api-ts-client';
import { CreateEvent } from '@daml/ledger';
import { ContractId, ContractTypeCompanion } from '@daml/types';

export default class TemplateContract<T> {
  readonly templateId: string;

  readonly contractId: ContractId<T>;

  readonly payload: T;

  readonly createdEventBlob?: string;

  constructor(
    templateId: string,
    contractId: ContractId<T>,
    payload: T,
    createdEventBlob?: string,
  ) {
    this.templateId = templateId;
    this.contractId = contractId;
    this.payload = payload;
    this.createdEventBlob = createdEventBlob;
  }

  static fromScanProxyAPI<T extends object, K, I extends string>(
    tmpl: ContractTypeCompanion<T, K, I>,
    c: openapi.Contract,
  ): TemplateContract<T> {
    return new TemplateContract(
      c.template_id,
      c.contract_id as ContractId<T>,
      tmpl.decoder.runWithException(c.payload),
      c.created_event_blob,
    );
  }

  static fromJsonAPI<T extends object, K, I extends string>(
    ev: CreateEvent<T, K, I>,
  ): TemplateContract<T> {
    // JSON API does not expose metadata.
    return new TemplateContract(ev.templateId, ev.contractId, ev.payload);
  }
}
