import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AmuletRules_DevNet_TapResult } from '@daml.js/splice-amulet-0.1.3/lib/Splice/AmuletRules';
import { appTransferContextWithDisclosures } from '../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../other/useLedgerApiClient';
import useParties from '../other/useParties';
import { useScanProxyApiClient } from '../other/useScanProxyApiClient';

const useTapCoin: () => UseMutationResult<
  AmuletRules_DevNet_TapResult,
  string,
  {
    amount: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();
  const { party: receiver } = useParties();

  return useMutation({
    mutationKey: ['tapCoin', ledgerApi],
    mutationFn: async ({ amount }) => {
      const { appTransferContext, disclosedContracts } = await appTransferContextWithDisclosures(
        scanProxyApi,
        30,
      );

      return ledgerApi.tapCoin(
        appTransferContext.amuletRules,
        receiver,
        amount,
        appTransferContext.openMiningRound,
        disclosedContracts,
      );
    },
  });
};

export default useTapCoin;
