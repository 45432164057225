import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { UtilityConfig } from './config/config';
import { LedgerApiClientProvider } from './contexts/LedgerApiContext';
import { ScanProxyApiProvider } from './contexts/ScanProxyApiContext';
import { UserProvider } from './contexts/UserContext';
import useBranding from './hooks/other/useBranding';
import { Credential } from './modules/Credential';
import { Registry } from './modules/Registry';
import { Trading } from './modules/Trading';
import AuthProvider from './pages/AuthProvider';
import AuthCheck from './routes/AuthCheck';
import Root from './routes/Root';
import queryClient from './utils/QueryClient';

const Providers = ({ config, children }: PropsWithChildren<{ config: UtilityConfig }>) => {
  return (
    <AuthProvider auth={config.auth}>
      <QueryClientProvider client={queryClient()}>
        <ReactQueryDevtools initialIsOpen={false} />
        <UserProvider auth={config.auth}>
          <LedgerApiClientProvider jsonApiUrl={config.jsonApi.url}>
            <ScanProxyApiProvider validatorUrl={config.scanProxyApi.url}>
              <SnackbarProvider>{children}</SnackbarProvider>
            </ScanProxyApiProvider>
          </LedgerApiClientProvider>
        </UserProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

const router = (config: UtilityConfig) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <Providers config={config}>
            <AuthCheck config={config} />
          </Providers>
        }
      >
        <Route path="/" element={<Root config={config} />} />
        <Route path="/credential/*" element={<Credential />} />
        <Route path="/registry/*" element={<Registry />} />
        <Route path="/trading/*" element={<Trading />} />
      </Route>,
    ),
  );

export type AppProps = {
  config: UtilityConfig;
};

export const App: React.FC<AppProps> = ({ config }: AppProps) => {
  const { theme } = useBranding();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router(config)} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
