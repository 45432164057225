import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  Direction,
  Trade,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import {
  TraderService,
  TraderService_RequestTrade_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRequestTrade: () => UseMutationResult<
  TraderService_RequestTrade_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    trade: Trade;
    direction: Direction;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['requestTrade', ledgerApi],
    mutationFn: async ({ traderServiceCid, trade, direction }) => {
      return ledgerApi.requestTrade(traderServiceCid, trade, direction);
    },
  });
};

export default useRequestTrade;
