import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../other/useLedgerApiClient';

const useRevokeCredential: () => UseMutationResult<
  void,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialCid: ContractId<Credential>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['revokeCredential', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialCid }) => {
      return ledgerApi.revokeCredential(userServiceCid, credentialCid);
    },
  });
};

export default useRevokeCredential;
