import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { RegistrarServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { CreateEvent } from '@daml/ledger';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRequestRegistrarService: () => UseMutationResult<
  CreateEvent<RegistrarServiceRequest>,
  string,
  {
    provider: string;
    credentialCid: ContractId<Credential>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();

  return useMutation({
    mutationKey: ['requestRegistryRegistrarService', ledgerApi, operator, party],
    mutationFn: async ({ provider, credentialCid }) => {
      return ledgerApi.requestRegistryRegistrarService(operator, provider, party, credentialCid);
    },
  });
};

export default useRequestRegistrarService;
