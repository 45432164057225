import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  RejectedLock,
  LockOffer,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectLockOffer: () => UseMutationResult<
  ContractId<RejectedLock>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    lockOfferCid: ContractId<LockOffer>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectLockOffer', ledgerApi],
    mutationFn: async ({ holderServiceCid, lockOfferCid, reason }) => {
      return ledgerApi.rejectLockOffer(holderServiceCid, lockOfferCid, reason);
    },
  });
};

export default useRejectLockOffer;
