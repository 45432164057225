import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelHolderServiceRequest: () => UseMutationResult<
  void,
  string,
  { holderServiceRequestCid: ContractId<HolderServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelRegistryHolderServiceRequest', ledgerApi],
    mutationFn: async ({ holderServiceRequestCid }) => {
      return ledgerApi.cancelRegistryHolderServiceRequest(holderServiceRequestCid);
    },
  });
};

export default useCancelHolderServiceRequest;
