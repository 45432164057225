import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { Amulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import { CredentialBilling } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import { appTransferContextWithDisclosures } from '../../../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import { useScanProxyApiClient } from '../../../other/useScanProxyApiClient';

const useDistributeAmulet: () => UseMutationResult<
  void,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialBillingCid: ContractId<CredentialBilling>;
    amountUsd: string;
    amuletCids: ContractId<Amulet>[];
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();

  return useMutation({
    mutationKey: ['distributeAmulet', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialBillingCid, amountUsd, amuletCids }) => {
      const { appTransferContext, disclosedContracts } = await appTransferContextWithDisclosures(
        scanProxyApi,
        30,
      );

      return ledgerApi.distributeAmulet(
        userServiceCid,
        credentialBillingCid,
        amountUsd,
        amuletCids,
        appTransferContext,
        disclosedContracts,
      );
    },
  });
};

export default useDistributeAmulet;
