import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserServiceRequest } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useUserServiceRequests: () => UseQueryResult<
  TemplateContract<UserServiceRequest>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryCredentialUserServiceRequests', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryCredentialUserServiceRequests(operator);
    },
  });
};

export default useUserServiceRequests;
