import { JWTPayload, SignJWT, decodeJwt } from 'jose';

export const generateToken = async (
  userId: string,
  secret: string,
  audience: string,
  scope?: string,
): Promise<string> => {
  const key = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(secret),
    { name: 'HMAC', hash: { name: 'SHA-256' } },
    false,
    ['sign'],
  );

  return new SignJWT({ scope })
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setAudience(audience)
    .setSubject(userId)
    .sign(key);
};

export const decodeToken = (token: string): JWTPayload => {
  return decodeJwt(token);
};
