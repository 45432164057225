import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  AcceptedUnlock,
  UnlockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptUnlockRequest: () => UseMutationResult<
  ContractId<AcceptedUnlock>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    unlockRequestCid: ContractId<UnlockRequest>;
    holdingLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptUnlockRequest', ledgerApi],
    mutationFn: async ({ holderServiceCid, unlockRequestCid, holdingLabel }) => {
      return ledgerApi.acceptUnlockRequest(holderServiceCid, unlockRequestCid, holdingLabel);
    },
  });
};

export default useAcceptUnlockRequest;
