import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { InstrumentConfiguration } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Configuration/Instrument';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCreateInstrumentConfiguration: () => UseMutationResult<
  ContractId<InstrumentConfiguration>,
  string,
  {
    registrarServiceCid: ContractId<RegistrarService>;
    registrar: string;
    instrumentId: string;
    issuerRequirements: PartyCredentialRequirement[];
    holderRequirements: PartyCredentialRequirement[];
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['requestInstrumentConfiguration', ledgerApi],
    mutationFn: async ({
      registrarServiceCid,
      registrar,
      instrumentId,
      issuerRequirements,
      holderRequirements,
    }) => {
      return ledgerApi.createRegistryInstrumentConfiguration(
        registrarServiceCid,
        registrar,
        instrumentId,
        issuerRequirements,
        holderRequirements,
      );
    },
  });
};

export default useCreateInstrumentConfiguration;
