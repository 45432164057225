import { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import { Amulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import { CredentialBilling } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import FormDialog from '../../components/Dialog/FormDialog';
import TextInput from '../../components/Form/TextInput';
import useDistributeAmulets from '../../hooks/mutations/credential/billing/useDistributeAmulet';
import useCalculateFees from '../../hooks/mutations/useCalculateFees';
import useMutate from '../../hooks/mutations/useMutate';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import TemplateContract from '../../utils/TemplateContract';
import {
  ERR_DIST_CRED_BILL,
  ERR_UPDATE_FEE,
  SUC_DIST_CRED_BILL,
  SUC_UPDATE_FEE,
} from '../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  row: TemplateContract<CredentialBilling> | undefined;
  amulets: TemplateContract<Amulet>[] | undefined;
  totalBalance: number;
};

export const DistributeAmuletBillingDialog = ({
  open,
  onClose,
  row,
  amulets,
  totalBalance,
}: DialogProps) => {
  const [amount, setAmount] = useState<string>('0.00');
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [fees, setFees] = useState<string[]>([]);
  const [sLatestamuletPrice, setLatestAmuletPrice] = useState<string>('');

  const userService = useUserService();
  const distributeAmulets = useDistributeAmulets();
  const mutate = useMutate();
  const calculateFees = useCalculateFees();
  const resetState = useCallback(() => {
    setAmount('');
    setFees([]);
    setValidationMessage('');
  }, [setAmount, setFees]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const feeCheck = useCallback(
    async (inputAmount: number) => {
      const holder = row?.payload.holder;
      const res = await mutate(
        calculateFees,
        { amountUsd: inputAmount, holder: holder ?? '' },
        SUC_UPDATE_FEE,
        ERR_UPDATE_FEE,
      );
      return res;
    },
    [calculateFees, mutate, row?.payload.holder],
  );

  const handleSubmit = useCallback(async () => {
    if (row !== undefined) {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialBillingCid: row.contractId,
        amountUsd: amount,
        amuletCids: (amulets ?? []).map((a) => a.contractId),
      };
      await mutate(distributeAmulets, payload, SUC_DIST_CRED_BILL, ERR_DIST_CRED_BILL);
    }
    handleClose();
  }, [userService, row, amount, amulets, distributeAmulets, mutate, handleClose]);

  const validateAsync = async (s: string) => {
    const depositAmountUSD = parseFloat(s);
    const getFeeValidation = await feeCheck(depositAmountUSD).then(({ res, latestAmuletPrice }) => {
      const fullFees = res.fees.reduce((acc, c) => acc + parseFloat(c), 0);
      const fullCoins = depositAmountUSD / parseFloat(latestAmuletPrice) + fullFees;
      const distributionValidity = fullCoins > totalBalance;
      setFees(res.fees);
      setLatestAmuletPrice(latestAmuletPrice);
      return distributionValidity ? 'Amount Exceeds Balance' : '';
    });
    return getFeeValidation;
  };

  return (
    <FormDialog
      open={open}
      title="Distribute Amulets"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Distribute"
      isSubmitDisabled={!amount || !!validationMessage}
    >
      <TextField
        disabled
        label="CredentialBilling recipient"
        defaultValue={row?.payload.holder ?? ''}
        fullWidth
      />
      <TextInput disabled label="Fees(Cc)" value={`${fees.toString()}`} setValue={() => {}} />
      <TextInput
        disabled
        label="Latest Amulet Price(USD)"
        value={`${sLatestamuletPrice}`}
        setValue={() => {}}
      />

      <TextInput
        setValidationMessage={setValidationMessage}
        validationMessage={validationMessage}
        getValidationMessageAsync={validateAsync}
        type="number"
        label="Amount(USD)"
        value={amount}
        setValue={setAmount}
        required
      />
    </FormDialog>
  );
};
