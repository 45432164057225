import { useCallback, useState } from 'react';
import FormDialog from '../../components/Dialog/FormDialog';
import TextInput from '../../components/Form/TextInput';
import useOfferPaidCredential from '../../hooks/mutations/credential/offer/useOfferPaidCredential';
import useMutate from '../../hooks/mutations/useMutate';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import { ERR_OFF_CRED_PAID, SUC_OFF_CRED_PAID } from '../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const OfferPaidCredentialDialog = ({ open, onClose }: DialogProps) => {
  const [holder, setHolder] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [property, setProperty] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [feePerDayUsd, setFeePerDayUsd] = useState<string>('');
  const [billingPeriodMinutes, setBillingPeriod] = useState<string>('');
  const [depositInitialAmountUsd, setCredentialFeesReserveAmountUsd] = useState<string>('');
  const [depositTargetAmountUsd, setCredentialFeesTargetReserveAmountUsd] = useState<string>('');
  const userService = useUserService();
  const offerPaidCredential = useOfferPaidCredential();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setHolder('');
    setId('');
    setDescription('');
    setSubject('');
    setProperty('');
    setValue('');
  }, [setHolder, setId, setDescription, setSubject, setProperty, setValue]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      userServiceCid: userService.data!.contractId,
      holder,
      id,
      description,
      claims: [{ subject, property, value }],
      billingParams: {
        feePerDayUsd: { rate: feePerDayUsd },
        billingPeriodMinutes,
        depositTargetAmountUsd,
      },
      depositInitialAmountUsd,
    };
    await mutate(offerPaidCredential, payload, SUC_OFF_CRED_PAID, ERR_OFF_CRED_PAID);
    handleClose();
  }, [
    feePerDayUsd,
    billingPeriodMinutes,
    depositInitialAmountUsd,
    depositTargetAmountUsd,
    userService,
    holder,
    id,
    description,
    subject,
    property,
    value,
    offerPaidCredential,
    mutate,
    handleClose,
  ]);

  const disableSubmit = !holder || !id || !description || !subject || !property || !value;
  return (
    <FormDialog
      open={open}
      title="Offer Paid Credential"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Offer"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Holder" value={holder} setValue={setHolder} required />
      <TextInput label="Id" value={id} setValue={setId} required />
      <TextInput label="Description" value={description} setValue={setDescription} required />
      <TextInput label="Subject" value={subject} setValue={setSubject} required />
      <TextInput label="Property" value={property} setValue={setProperty} required />
      <TextInput label="Value" value={value} setValue={setValue} required />
      <TextInput label="FeePerDayUsd" value={feePerDayUsd} setValue={setFeePerDayUsd} required />
      <TextInput
        label="BillingPeriod (minutes)"
        value={billingPeriodMinutes}
        setValue={setBillingPeriod}
        required
      />
      <TextInput
        label="CredentialFeesReserveAmountUsd"
        value={depositInitialAmountUsd}
        setValue={setCredentialFeesReserveAmountUsd}
        required
      />
      <TextInput
        label="CredentialFeesTargetReserveAmountUsd"
        value={depositTargetAmountUsd}
        setValue={setCredentialFeesTargetReserveAmountUsd}
        required
      />
    </FormDialog>
  );
};
