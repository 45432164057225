import { useContext } from 'react';
import { ScanProxyApi } from 'validator-scan-proxy-api-ts-client';
import { ScanProxyApiContext } from '../../contexts/ScanProxyApiContext';

export const useScanProxyApiClient: () => ScanProxyApi = () => {
  const client = useContext<ScanProxyApi | undefined>(ScanProxyApiContext);
  if (!client) {
    throw new Error('Validator scan proxy client not initialized');
  }
  return client;
};
