import { useCallback, useState } from 'react';
import {
  Transfer,
  TransferRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptTransferRequest from '../../../hooks/mutations/registry/transfer/useAcceptTransferRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import { ERR_ACC_XFER_REQ, SUC_ACC_XFER_REQ } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  request: { contractId: string; state: string; payload: Transfer };
};

export const AcceptTransferRequestDialog = ({ open, onClose, request }: DialogProps) => {
  const [registrar, setRegistrar] = useState<string>('');
  const [senderLabel, setSenderLabel] = useState<string>('');

  const holderService = useHolderService();
  const acceptTransferRequest = useAcceptTransferRequest();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setRegistrar('');
    setSenderLabel('');
  }, [setRegistrar, setSenderLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [onClose, resetState]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data || !registrar) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      transferRequestCid: request.contractId as ContractId<TransferRequest>,
      senderLabel,
    };
    await mutate(acceptTransferRequest, payload, SUC_ACC_XFER_REQ, ERR_ACC_XFER_REQ);
    handleClose();
  }, [
    holderService,
    registrar,
    senderLabel,
    request.contractId,
    handleClose,
    mutate,
    acceptTransferRequest,
  ]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !registrar;
  return (
    <FormDialog
      open={open}
      title="Accept Transfer Request"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
      <TextInput label="Label" value={senderLabel} setValue={setSenderLabel} />
    </FormDialog>
  );
};
