import { UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useErrorBoundary } from 'react-error-boundary';

const useMutate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { showBoundary } = useErrorBoundary<Error>();

  const mutate = async <TData, TError, TVariables, TContext>(
    mutation: UseMutationResult<TData, TError, TVariables, TContext>,
    payload: TVariables,
    successMsg: string,
    errorMsg: string,
  ) => {
    try {
      const res = await mutation.mutateAsync(payload);
      enqueueSnackbar(successMsg, { variant: 'success' });
      return res;
    } catch (error) {
      const err = new Error(errorMsg, { cause: error });
      showBoundary(err);
      throw err;
    }
  };
  return mutate;
};

export default useMutate;
