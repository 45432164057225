import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRegistryProviderConfigurations: () => UseQueryResult<
  TemplateContract<ProviderConfiguration>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryRegistryProviderConfigurations', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryRegistryProviderConfigurations(operator);
    },
  });
};

export default useRegistryProviderConfigurations;
