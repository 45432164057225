import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import {
  Burn,
  BurnRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Burn';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptBurnRequest from '../../../hooks/mutations/registry/burn/useAcceptBurnRequest';
import useCancelBurnRequest from '../../../hooks/mutations/registry/burn/useCancelBurnRequest';
import useRejectBurnRequest from '../../../hooks/mutations/registry/burn/useRejectBurnRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useAcceptedBurns from '../../../hooks/queries/registry/burn/useAcceptedBurns';
import useBurnOffers from '../../../hooks/queries/registry/burn/useBurnOffers';
import useBurnRequests from '../../../hooks/queries/registry/burn/useBurnRequests';
import useExecutedBurns from '../../../hooks/queries/registry/burn/useExecutedBurns';
import useRejectedBurns from '../../../hooks/queries/registry/burn/useRejectedBurns';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import useHoldings from '../../../hooks/queries/registry/useHoldings';
import { fmt, prettyParty, shorten } from '../../../utils/common';
import {
  ERR_ACC_BURN,
  ERR_CAN_BURN,
  ERR_REJ_BURN,
  SUC_ACC_BURN,
  SUC_CAN_BURN,
  SUC_REJ_BURN,
} from '../../../utils/strings';
import { RequestBurnDialog } from './RequestBurnDialog';

type Row = {
  contractId: string;
  state: string;
  payload: Burn;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 100,
      filterable: false,
    },
    {
      field: 'registrar',
      headerName: 'Registrar',
      valueGetter: (params) => prettyParty(params.row.payload.registrar),
      flex: 100,
      filterable: false,
    },
    {
      field: 'instrumentId',
      headerName: 'Instrument Id',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => fmt(params.row.payload.amount, 0),
      flex: 150,
      filterable: false,
    },
    {
      field: 'batch',
      headerName: 'Batch (size)',
      valueGetter: (params) =>
        `${shorten(params.row.payload.batch.id)} (${params.row.payload.batch.size})`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [requestBurnIsOpen, setRequestBurnIsOpen] = useState(false);
  const holderService = useHolderService();

  const { isLoading } = holderService;
  if (isLoading) return null;

  const isHolder = !!holderService.data;

  return (
    <GridToolbarContainer>
      {requestBurnIsOpen && (
        <RequestBurnDialog open={requestBurnIsOpen} onClose={() => setRequestBurnIsOpen(false)} />
      )}
      <SyncButton onClick={() => setRequestBurnIsOpen(true)} disabled={!isHolder}>
        Request burn
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const Burns = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const { party } = useParties();
  const operatorService = useOperatorService();
  const registrarService = useRegistrarService();
  const registrarServices = useRegistrarServices();
  const holderService = useHolderService();
  const burnRequests = useBurnRequests();
  const burnOffers = useBurnOffers();
  const acceptedBurns = useAcceptedBurns();
  const rejectedBurns = useRejectedBurns();
  const executedBurns = useExecutedBurns();
  const holdings = useHoldings();
  const acceptBurnRequest = useAcceptBurnRequest();
  const rejectBurnRequest = useRejectBurnRequest();
  const cancelBurnRequest = useCancelBurnRequest();
  const mutate = useMutate();

  const acceptBurn = useCallback(
    (r: Row) => {
      if (!registrarService.data) return null;
      const registrarServiceCid = registrarService.data.contractId;
      if (!registrarServiceCid) throw new Error('Registrar service not found');

      const payload = {
        registrarServiceCid,
        burnRequestCid: r.contractId as ContractId<BurnRequest>,
      };
      return mutate(acceptBurnRequest, payload, SUC_ACC_BURN, ERR_ACC_BURN);
    },
    [registrarService, acceptBurnRequest, mutate],
  );

  const rejectBurn = useCallback(
    (r: Row, reason: string) => {
      const payload = {
        registrarServiceCid: registrarService.data!.contractId,
        burnRequestCid: r.contractId as ContractId<BurnRequest>,
        reason,
      };
      return mutate(rejectBurnRequest, payload, SUC_REJ_BURN, ERR_REJ_BURN);
    },
    [registrarService, rejectBurnRequest, mutate],
  );

  const cancelBurn = useCallback(
    (r: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        burnRequestCid: r.contractId as ContractId<BurnRequest>,
      };
      return mutate(cancelBurnRequest, payload, SUC_CAN_BURN, ERR_CAN_BURN);
    },
    [holderService, cancelBurnRequest, mutate],
  );

  const isLoading =
    operatorService.isLoading ||
    registrarService.isLoading ||
    registrarServices.isLoading ||
    holderService.isLoading ||
    burnRequests.isLoading ||
    burnOffers.isLoading ||
    acceptedBurns.isLoading ||
    rejectedBurns.isLoading ||
    executedBurns.isLoading ||
    holdings.isLoading ||
    !burnRequests.data ||
    !burnOffers.data ||
    !acceptedBurns.data ||
    !rejectedBurns.data ||
    !executedBurns.data ||
    !holdings.data;
  if (isLoading) return <Loading />;

  const rows = burnRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      state: 'Requested',
      payload: r.payload.burn,
    }))
    .concat(
      burnOffers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Offered',
        payload: r.payload.burn,
      })),
    )
    .concat(
      acceptedBurns.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Accepted',
        payload: r.payload.burn,
      })),
    )
    .concat(
      rejectedBurns.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Rejected',
        payload: r.payload.burn,
      })),
    )
    .concat(
      executedBurns.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Executed',
        payload: r.payload.burn,
      })),
    );

  const isHolder = !!holderService.data;
  const isRegistrar = !!registrarService.data;
  const createBurnActions = (r: Row) => {
    let actions: Action[] = [];
    if (isRegistrar && r.state === 'Requested' && r.payload.registrar === party)
      actions = actions.concat([
        { name: 'Accept', action: (row: Row) => Promise.resolve(acceptBurn(row)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectBurn(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Requested' && r.payload.holder === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelBurn }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      <DataTable
        title="Burns"
        variant="h3"
        columns={createHeaders(createBurnActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
        toolbar={Toolbar}
      />
    </>
  );
};
