import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CredentialBilling } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import { AsyncButton } from '../../components/Button/AsyncButton';
import { SyncButton } from '../../components/Button/SyncButton';
import Loading from '../../components/Loading';
import { QueryTable } from '../../components/Table/QueryTable';
import useBillCredential from '../../hooks/mutations/credential/billing/useBillCredential';
import useCancelCredentialBilling from '../../hooks/mutations/credential/billing/useCancelCredentialBilling';
import useMutate from '../../hooks/mutations/useMutate';
import useParties from '../../hooks/other/useParties';
import useCoins from '../../hooks/queries/cn/useCoin';
import useCredentialBillings from '../../hooks/queries/credential/billing/useCredentialBillings';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import TemplateContract from '../../utils/TemplateContract';
import { parseDateString, prettyParty } from '../../utils/common';
import {
  ERR_BILL_CRED,
  ERR_CAN_BILL_CRED,
  SUC_BILL_CRED,
  SUC_CAN_BILL_CRED,
} from '../../utils/strings';
import { DistributeAmuletBillingDialog } from './DistributeAmuletBillingDialog';
import { TopUpLockedDepositDialog } from './TopUpLockedDepositDialog';

type Row = TemplateContract<CredentialBilling>;

type Action<T> = {
  name: string;
  action: (r: Row) => T;
  disabled?: boolean;
};

const createHeaders: (
  createActions: (r: Row) => Action<Promise<unknown>>[],
  createDistributeOrTopUp: (r: Row) => Action<void>[],
  setOpenDistributeCredentialBillingDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenTopupDepositDialog: React.Dispatch<React.SetStateAction<boolean>>,
  party: string,
  setRow: React.Dispatch<React.SetStateAction<Row | undefined>>,
) => GridColDef<Row>[] = (createActions, createDistributeOrTopUp) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const coreActions = createActions(params.row);
    const auxActions = createDistributeOrTopUp(params.row);
    return (
      <>
        {coreActions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
        {auxActions.map(({ name, action, disabled }) => (
          <SyncButton
            key={name}
            sx={{ mr: 1 }}
            onClick={() => action(params.row)}
            disabled={disabled}
          >
            {name}
          </SyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 100,
      filterable: false,
    },
    {
      field: 'issuer',
      headerName: 'Issuer',
      valueGetter: (params) => prettyParty(params.row.payload.issuer),
      flex: 100,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 100,
      filterable: false,
    },
    {
      field: 'id',
      headerName: 'Id',
      valueGetter: (params) => params.row.payload.credentialId,
      flex: 80,
      filterable: false,
    },
    {
      field: 'currentDeposit',
      headerName: 'Current Deposit',
      valueGetter: (params) => `${params.row.payload.balanceState.currentDepositAmountCc} CC`,
      flex: 100,
      filterable: false,
    },
    {
      field: 'totalPaid',
      headerName: 'Total Paid',
      valueGetter: (params) => `${params.row.payload.balanceState.totalCredentialFeesPaidCc} CC`,
      flex: 100,
      filterable: false,
    },
    {
      field: 'lastBilledAt',
      headerName: 'Last Billed At',
      valueGetter: (params) =>
        parseDateString(params.row.payload.billingState.lastBilledAt).toLocaleString(),
      flex: 150,
      filterable: false,
    },
    {
      field: 'status',
      headerName: 'Billing Status',
      valueGetter: (params) => {
        if (params.row.payload.billingState.status.tag === 'Failure') {
          return `Failure - ${params.row.payload.billingState.status.value.reason}`;
        }
        return params.row.payload.billingState.status.tag;
      },
      flex: 100,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: renderActionCell,
      sortable: false,
      filterable: false,
      flex: 200,
    },
  ];
};

export const Billing = () => {
  const [openDistributeCredentialBillingDialog, setOpenDistributeCredentialBillingDialog] =
    useState<boolean>(false);
  const [openTopupDepositDialog, setOpenTopupDepositDialog] = useState<boolean>(false);
  const [getRow, setRow] = useState<Row>();
  const { party } = useParties();
  const userService = useUserService();
  const billings = useCredentialBillings();
  const billCredential = useBillCredential();
  const cancelBilling = useCancelCredentialBilling();
  const amulets = useCoins();
  const mutate = useMutate();

  const bill = useCallback(
    (r: Row) => {
      const payload = {
        credentialBillingCid: r.contractId,
      };
      return mutate(billCredential, payload, SUC_BILL_CRED, ERR_BILL_CRED);
    },
    [billCredential, mutate],
  );

  const cancel = useCallback(
    (r: Row) => {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialBillingCid: r.contractId,
      };
      return mutate(cancelBilling, payload, SUC_CAN_BILL_CRED, ERR_CAN_BILL_CRED);
    },
    [userService.data, cancelBilling, mutate],
  );

  const topUp = (r: Row) => {
    setOpenTopupDepositDialog(true);
    setRow(r);
  };

  const distribute = (r: Row) => {
    setOpenDistributeCredentialBillingDialog(true);
    setRow(r);
  };

  const isLoading = billings.isLoading || userService.isLoading;

  const totalBalance = amulets.data
    ? amulets.data.reduce((acc, c) => acc + parseFloat(c.payload.amount.initialAmount), 0)
    : 0;

  if (isLoading) return <Loading />;

  const createActions = (r: Row) => {
    let actions: Action<Promise<unknown>>[] = [];
    if (r.payload.operator === party) actions = actions.concat([{ name: 'Bill', action: bill }]);
    if (r.payload.issuer === party) actions = actions.concat([{ name: 'Cancel', action: cancel }]);
    return actions;
  };

  const createDistTopUp = (r: Row) => {
    const areAmuletsAvailable = amulets?.data?.length !== 0;
    let actions: Action<void>[] = [];
    if (r.payload.issuer === party)
      actions = actions.concat([{ name: 'Distribute', action: distribute }]);
    if (r.payload.holder === party)
      actions = actions.concat([
        {
          name: areAmuletsAvailable ? 'Top Up' : 'Balance Too Low',
          action: topUp,
          disabled: !areAmuletsAvailable,
        },
      ]);
    return actions;
  };

  return (
    <>
      <DistributeAmuletBillingDialog
        open={openDistributeCredentialBillingDialog}
        onClose={() => setOpenDistributeCredentialBillingDialog(false)}
        row={getRow}
        amulets={amulets.data}
        totalBalance={totalBalance}
      />
      <TopUpLockedDepositDialog
        open={openTopupDepositDialog}
        onClose={() => setOpenTopupDepositDialog(false)}
        row={getRow}
        amulets={amulets.data}
      />
      <QueryTable
        title="Credential Billings"
        variant="h3"
        columns={createHeaders(
          createActions,
          createDistTopUp,
          setOpenDistributeCredentialBillingDialog,
          setOpenTopupDepositDialog,
          party,
          setRow,
        )}
        rowQuery={billings}
        rowKey={(a) => a.contractId}
      />
    </>
  );
};
