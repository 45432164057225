import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ProviderConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Provider';
import {
  TraderServiceRequest,
  TraderServiceRequest_Accept_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptTraderServiceRequest: () => UseMutationResult<
  TraderServiceRequest_Accept_Result,
  string,
  {
    traderServiceRequestCid: ContractId<TraderServiceRequest>;
    providerConfigurationCid: ContractId<ProviderConfiguration>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useMutation({
    mutationKey: ['acceptTradingTraderServiceRequest', ledgerApi, operator],
    mutationFn: async ({ traderServiceRequestCid, providerConfigurationCid }) => {
      return ledgerApi.acceptTradingTraderServiceRequest(
        traderServiceRequestCid,
        providerConfigurationCid,
      );
    },
  });
};

export default useAcceptTraderServiceRequest;
