import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { CredentialOffer } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectCredentialOffer: () => UseMutationResult<
  void,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialOfferCid: ContractId<CredentialOffer>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectCredentialOffer', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialOfferCid, reason }) => {
      return ledgerApi.rejectCredentialOffer(userServiceCid, credentialOfferCid, reason);
    },
  });
};

export default useRejectCredentialOffer;
