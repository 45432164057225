import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ProviderServiceRequest } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import { CreateEvent } from '@daml/ledger';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRequestProviderService: () => UseMutationResult<
  CreateEvent<ProviderServiceRequest>,
  string,
  {
    credentialCid: ContractId<Credential>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();

  return useMutation({
    mutationKey: ['requestTradingProviderService', ledgerApi, operator, party],
    mutationFn: async ({ credentialCid }) => {
      return ledgerApi.requestTradingProviderService(operator, party, credentialCid);
    },
  });
};

export default useRequestProviderService;
