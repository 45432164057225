import { useCallback, useState } from 'react';
import TextInput from '../Form/TextInput';
import FormDialog from './FormDialog';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  onReject: ((reason: string) => Promise<unknown>) | undefined;
};

export const RejectDialog = ({ open, onClose, onReject }: DialogProps) => {
  const [rejectReason, setRejectReason] = useState<string>('');

  const resetState = useCallback(() => {
    setRejectReason('');
  }, [setRejectReason]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(() => {
    return onReject!(rejectReason);
  }, [onReject, rejectReason]);

  const disableSubmit = !rejectReason;
  return (
    <FormDialog
      open={open}
      title="Reject Request"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Reject"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Reason" value={rejectReason} setValue={setRejectReason} required />
    </FormDialog>
  );
};
