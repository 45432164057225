import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProviderConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Provider';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useProviderConfiguration: () => UseQueryResult<
  TemplateContract<ProviderConfiguration> | null,
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryTradingProviderConfiguration', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryTradingProviderConfiguration(operator, party);
    },
  });
};

export default useProviderConfiguration;
