import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useUserService: () => UseQueryResult<TemplateContract<UserService> | null, string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryCredentialUserService', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryCredentialUserService(operator, party);
    },
  });
};

export default useUserService;
