import { useCallback, useState } from 'react';
import {
  Unlock,
  UnlockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptUnlockRequest from '../../../hooks/mutations/registry/unlock/useAcceptUnlockRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import { ERR_ACC_UNLOCK_REQ, SUC_ACC_UNLOCK_REQ } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  request: { contractId: string; state: string; payload: Unlock };
};

export const AcceptUnlockRequestDialog = ({ open, onClose, request }: DialogProps) => {
  const [registrar, setRegistrar] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const holderService = useHolderService();
  const acceptUnlockRequest = useAcceptUnlockRequest();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setRegistrar('');
    setLabel('');
  }, [setRegistrar, setLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [onClose, resetState]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data || !registrar) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      unlockRequestCid: request.contractId as ContractId<UnlockRequest>,
      holdingLabel: label,
    };
    await mutate(acceptUnlockRequest, payload, SUC_ACC_UNLOCK_REQ, ERR_ACC_UNLOCK_REQ);
    handleClose();
  }, [
    holderService,
    registrar,
    label,
    request.contractId,
    handleClose,
    mutate,
    acceptUnlockRequest,
  ]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !registrar;
  return (
    <FormDialog
      open={open}
      title="Accept Unlock Request"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
      <TextInput label="Label" value={label} setValue={setLabel} />
    </FormDialog>
  );
};
