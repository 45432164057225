import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  CredentialBilling,
  CredentialBilling_Bill_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import { ContractId } from '@daml/types';
import { appTransferContextWithDisclosures } from '../../../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import { useScanProxyApiClient } from '../../../other/useScanProxyApiClient';

const useBillCredential: () => UseMutationResult<
  CredentialBilling_Bill_Result,
  string,
  {
    credentialBillingCid: ContractId<CredentialBilling>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();

  return useMutation({
    mutationKey: ['billCredential', ledgerApi, scanProxyApi],
    mutationFn: async ({ credentialBillingCid }) => {
      // We fetch all data in the mutation function to avoid using outdated mining round / amulet
      // rules, as there is a (potentially large) difference in time between the hook being created
      // and the mutation function being called.
      const { appTransferContext, disclosedContracts } = await appTransferContextWithDisclosures(
        scanProxyApi,
        30,
      );

      return ledgerApi.billCredential(credentialBillingCid, appTransferContext, disclosedContracts);
    },
  });
};

export default useBillCredential;
