import { useCallback, useState } from 'react';
import { Amulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import { CredentialBilling } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import FormDialog from '../../components/Dialog/FormDialog';
import TextInput from '../../components/Form/TextInput';
import useTopUpCredentialBillingLockedDeposit from '../../hooks/mutations/credential/billing/useTopUpCredentialBillingLockedDeposit';
import useMutate from '../../hooks/mutations/useMutate';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import TemplateContract from '../../utils/TemplateContract';
import { ERR_TOPUP_CRED_BILL, SUC_TOPUP_CRED_BILL } from '../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  row: TemplateContract<CredentialBilling> | undefined;
  amulets: TemplateContract<Amulet>[] | undefined;
};

export const TopUpLockedDepositDialog = ({ open, onClose, row, amulets }: DialogProps) => {
  const [amount, setAmount] = useState<string>('');
  const userService = useUserService();
  const topUpCredentialBillingLockedDeposit = useTopUpCredentialBillingLockedDeposit();
  const mutate = useMutate();
  const resetState = useCallback(() => {
    setAmount('');
  }, [setAmount]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (row !== undefined) {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialBillingCid: row.contractId,
        amountUsd: amount,
        amuletCids: (amulets ?? []).map((a) => a.contractId),
      };
      await mutate(
        topUpCredentialBillingLockedDeposit,
        payload,
        SUC_TOPUP_CRED_BILL,
        ERR_TOPUP_CRED_BILL,
      );
    }
    handleClose();
  }, [userService, row, amount, amulets, topUpCredentialBillingLockedDeposit, mutate, handleClose]);

  const disableSubmit = !amount;
  return (
    <FormDialog
      open={open}
      title="TopUp Locked Paid Credential Deposit"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Top Up"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput
        disabled
        label="Billing Holder for Top Up"
        value={row?.payload.holder ?? ''}
        setValue={() => {}}
      />
      <TextInput
        type="number"
        label="Amount to Top Up (USD)"
        value={amount}
        setValue={setAmount}
        required
      />
    </FormDialog>
  );
};
