import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTraderServices: () => UseQueryResult<TemplateContract<TraderService>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryTradingTraderServices', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryTradingTraderServices(operator);
    },
  });
};

export default useTraderServices;
