import { useContext } from 'react';
import { UserContext, UserState } from '../../contexts/UserContext';

const useUserState: () => UserState = () => {
  const user = useContext<UserState | undefined>(UserContext);
  if (!user) {
    throw new Error('User context not initialized');
  }
  return user;
};

export default useUserState;
