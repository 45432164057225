import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Unlock,
  UnlockOffer,
  UnlockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptUnlockOffer from '../../../hooks/mutations/registry/unlock/useAcceptUnlockOffer';
import useCancelUnlockOffer from '../../../hooks/mutations/registry/unlock/useCancelUnlockOffer';
import useCancelUnlockRequest from '../../../hooks/mutations/registry/unlock/useCancelUnlockRequest';
import useRejectUnlockOffer from '../../../hooks/mutations/registry/unlock/useRejectUnlockOffer';
import useRejectUnlockRequest from '../../../hooks/mutations/registry/unlock/useRejectUnlockRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import useAcceptedUnlocks from '../../../hooks/queries/registry/unlock/useAcceptedUnlocks';
import useExecutedUnlocks from '../../../hooks/queries/registry/unlock/useExecutedUnlocks';
import useRejectedUnlocks from '../../../hooks/queries/registry/unlock/useRejectedUnlocks';
import useUnlockOffers from '../../../hooks/queries/registry/unlock/useUnlockOffers';
import useUnlockRequests from '../../../hooks/queries/registry/unlock/useUnlockRequests';
import useHoldings from '../../../hooks/queries/registry/useHoldings';
import { prettyParty, shorten } from '../../../utils/common';
import {
  ERR_ACC_UNLOCK_OFF,
  ERR_CAN_UNLOCK_OFF,
  ERR_CAN_UNLOCK_REQ,
  ERR_REJ_UNLOCK_OFF,
  ERR_REJ_UNLOCK_REQ,
  SUC_ACC_UNLOCK_OFF,
  SUC_CAN_UNLOCK_OFF,
  SUC_CAN_UNLOCK_REQ,
  SUC_REJ_UNLOCK_OFF,
  SUC_REJ_UNLOCK_REQ,
} from '../../../utils/strings';
import { AcceptUnlockRequestDialog } from './AcceptUnlockRequestDialog';

export type Row = {
  contractId: string;
  state: string;
  payload: Unlock;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 150,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 150,
      filterable: false,
    },
    {
      field: 'locker',
      headerName: 'Locker',
      valueGetter: (params) => prettyParty(params.row.payload.locker),
      flex: 150,
      filterable: false,
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => params.row.payload.amount,
      flex: 150,
      filterable: false,
    },
    {
      field: 'batch',
      headerName: 'Batch (size)',
      valueGetter: (params) =>
        `${shorten(params.row.payload.batch.id)} (${params.row.payload.batch.size})`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

export const Unlocks = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const [unlockRequest, setUnlockRequest] = useState<Row | undefined>();

  const { party } = useParties();
  const holderService = useHolderService();
  const operatorService = useOperatorService();
  const registrarServices = useRegistrarServices();
  const holdings = useHoldings();
  const unlockRequests = useUnlockRequests();
  const unlockOffers = useUnlockOffers();
  const acceptedUnlocks = useAcceptedUnlocks();
  const rejectedUnlocks = useRejectedUnlocks();
  const executedUnlocks = useExecutedUnlocks();
  const acceptUnlockOffer = useAcceptUnlockOffer();
  const rejectUnlockOffer = useRejectUnlockOffer();
  const rejectUnlockRequest = useRejectUnlockRequest();
  const cancelUnlockOffer = useCancelUnlockOffer();
  const cancelUnlockRequest = useCancelUnlockRequest();
  const mutate = useMutate();

  const acceptUnlockOff = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        unlockOfferCid: row.contractId as ContractId<UnlockOffer>,
      };
      return mutate(acceptUnlockOffer, payload, SUC_ACC_UNLOCK_OFF, ERR_ACC_UNLOCK_OFF);
    },
    [holderService, acceptUnlockOffer, mutate],
  );

  const rejectUnlockOff = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        unlockOfferCid: row.contractId as ContractId<UnlockOffer>,
        reason,
      };
      return mutate(rejectUnlockOffer, payload, SUC_REJ_UNLOCK_OFF, ERR_REJ_UNLOCK_OFF);
    },
    [holderService, rejectUnlockOffer, mutate],
  );

  const cancelUnlockOff = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        unlockOfferCid: row.contractId as ContractId<UnlockOffer>,
      };
      return mutate(cancelUnlockOffer, payload, SUC_CAN_UNLOCK_OFF, ERR_CAN_UNLOCK_OFF);
    },
    [holderService, cancelUnlockOffer, mutate],
  );

  const rejectUnlockReq = useCallback(
    (row: Row, reason: string) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        unlockRequestCid: row.contractId as ContractId<UnlockRequest>,
        reason,
      };
      return mutate(rejectUnlockRequest, payload, SUC_REJ_UNLOCK_REQ, ERR_REJ_UNLOCK_REQ);
    },
    [holderService, rejectUnlockRequest, mutate],
  );

  const cancelUnlockReq = useCallback(
    (row: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        unlockRequestCid: row.contractId as ContractId<UnlockRequest>,
      };
      return mutate(cancelUnlockRequest, payload, SUC_CAN_UNLOCK_REQ, ERR_CAN_UNLOCK_REQ);
    },
    [holderService, cancelUnlockRequest, mutate],
  );

  const isLoading =
    holderService.isLoading ||
    operatorService.isLoading ||
    registrarServices.isLoading ||
    holdings.isLoading ||
    unlockRequests.isLoading ||
    unlockOffers.isLoading ||
    acceptedUnlocks.isLoading ||
    rejectedUnlocks.isLoading ||
    executedUnlocks.isLoading ||
    !holdings.data ||
    !unlockRequests.data ||
    !unlockOffers.data ||
    !acceptedUnlocks.data ||
    !rejectedUnlocks.data ||
    !executedUnlocks.data;
  if (isLoading) return <Loading />;

  const rows = unlockRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      state: 'Requested',
      payload: r.payload.unlock,
    }))
    .concat(
      unlockOffers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Offered',
        payload: r.payload.unlock,
      })),
    )
    .concat(
      acceptedUnlocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Accepted',
        payload: r.payload.unlock,
      })),
    )
    .concat(
      rejectedUnlocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Rejected',
        payload: r.payload.unlock,
      })),
    )
    .concat(
      executedUnlocks.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Executed',
        payload: r.payload.unlock,
      })),
    );

  const isHolder = !!holderService.data;
  const createActions = (r: Row) => {
    let actions: Action[] = [];
    if (isHolder && r.state === 'Offered' && r.payload.locker === party)
      actions = actions.concat([
        { name: 'Accept', action: acceptUnlockOff },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectUnlockOff(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Offered' && r.payload.holder === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelUnlockOff }]);
    if (isHolder && r.state === 'Requested' && r.payload.holder === party)
      actions = actions.concat([
        { name: 'Accept', action: () => Promise.resolve(setUnlockRequest(r)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectUnlockReq(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Requested' && r.payload.locker === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelUnlockReq }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      {!!unlockRequest && (
        <AcceptUnlockRequestDialog
          open={!!unlockRequest}
          onClose={() => setUnlockRequest(undefined)}
          request={unlockRequest}
        />
      )}
      <DataTable
        title="Unlocks"
        variant="h3"
        columns={createHeaders(createActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
      />
    </>
  );
};
