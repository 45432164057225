import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren } from 'react';
import { AuthProvider as OidcAuthProvider } from 'react-oidc-context';
import { AuthConf, isHs256UnsafeAuthConfig } from '../config/config';

const AuthProvider = ({
  auth,
  children,
}: PropsWithChildren<{
  auth: AuthConf;
}>) => {
  if (isHs256UnsafeAuthConfig(auth)) {
    return children;
  }

  const redirectUri = window.location.origin;
  const scope = 'daml_ledger_api offline_access';

  return (
    <OidcAuthProvider
      automaticSilentRenew
      authority={auth.authority}
      client_id={auth.client_id}
      redirect_uri={redirectUri}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      scope={scope}
      extraQueryParams={{ audience: auth.audience }}
      onSigninCallback={() => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
    >
      {children}
    </OidcAuthProvider>
  );
};

export default AuthProvider;
