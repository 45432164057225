import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Amulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import TemplateContract from '../../../utils/TemplateContract';
import useLedgerApiClient from '../../other/useLedgerApiClient';
import useParties from '../../other/useParties';

const useCoins: () => UseQueryResult<TemplateContract<Amulet>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();
  return useQuery({
    queryKey: ['queryCoins', ledgerApi, party],
    queryFn: async () => {
      return ledgerApi.queryCoins(party);
    },
  });
};

export default useCoins;
