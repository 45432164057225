// Credential mutations
export const SUC_OFF_CRED_FREE = 'Successfully offered free credential';
export const SUC_OFF_CRED_PAID = 'Successfully offered paid credential';
export const SUC_ACC_CRED_FREE_OFF = 'Successfully accepted free credential offer';
export const SUC_ACC_CRED_PAID_OFF = 'Successfully accepted paid credential offer';
export const SUC_CAN_CRED_OFF = 'Successfully canceled credential offer';
export const SUC_REJ_CRED_OFF = 'Successfully rejected credential offer';
export const SUC_REV_CRED = 'Successfully revoked credential';
export const SUC_REV_CAN_CRED = 'Successfully revoked credential and canceled billing';
export const SUC_BILL_CRED = 'Successfully billed credential';
export const SUC_DIST_CRED_BILL = 'Successfully distributed credential billing';
export const SUC_TOPUP_CRED_BILL = 'Successfully topped up deposit for credential billing';
export const SUC_CAN_BILL_CRED = 'Successfully canceled credential billing';
export const SUC_UPDATE_FEE = 'Successfully re-calculated fees';
export const ERR_UPDATE_FEE = 'Failed re-calculated fees';
export const ERR_OFF_CRED_FREE = 'Failed to offer free credential';
export const ERR_OFF_CRED_PAID = 'Failed to offer paid credential';
export const ERR_ACC_CRED_FREE_OFF = 'Failed to accept free credential offer';
export const ERR_ACC_CRED_PAID_OFF = 'Failed to accept paid credential offer';
export const ERR_CAN_CRED_OFF = 'Failed to cancel credential offer';
export const ERR_REJ_CRED_OFF = 'Failed to reject credential offer';
export const ERR_BILL_CRED = 'Failed to bill credential';
export const ERR_DIST_CRED_BILL = 'Failed to distribute credential billing';
export const ERR_TOPUP_CRED_BILL = 'Failed to top up deposit for credential billing';
export const ERR_CAN_BILL_CRED = 'Failed to cancel credential billing';
export const ERR_REV_CRED = 'Failed to revoke credential';
export const ERR_REV_CAN_CRED = 'Failed to revoke credential and cancel billing';

// Credential operator mutations
export const SUC_REQ_USER_SVC = 'Successfully requested credential user service';
export const SUC_ACC_USER_SVC = 'Successfully accepted credential user service request';
export const SUC_REJ_USER_SVC = 'Successfully rejected credential user service request';
export const SUC_CAN_USER_SVC = 'Successfully canceled credential user service request';
export const SUC_TER_USER_SVC = 'Successfully terminated credential user service';
export const ERR_REQ_USER_SVC = 'Failed to request credential user service';
export const ERR_ACC_USER_SVC = 'Failed to accept credential user service request';
export const ERR_REJ_USER_SVC = 'Failed to reject credential user service request';
export const ERR_CAN_USER_SVC = 'Failed to cancel credential user service request';
export const ERR_TER_USER_SVC = 'Failed to terminate credential user service';

// Registry operator mutations
export const SUC_CRE_OP_SVC = 'Successfully created operator service';
export const SUC_CRE_OP_CONF = 'Successfully created operator configuration';
export const SUC_REQ_PROVIDER_SVC = 'Successfully requested registry provider service';
export const SUC_ACC_PROVIDER_SVC = 'Successfully accepted registry provider service request';
export const SUC_REJ_PROVIDER_SVC = 'Successfully rejected registry provider service request';
export const SUC_CAN_PROVIDER_SVC = 'Successfully canceled registry provider service request';
export const SUC_TER_PROVIDER_SVC = 'Successfully terminated registry provider service';
export const ERR_CRE_OP_SVC = 'Failed to create operator service';
export const ERR_CRE_OP_CONF = 'Failed to create operator configuration';
export const ERR_REQ_PROVIDER_SVC = 'Failed to request registry provider service';
export const ERR_ACC_PROVIDER_SVC = 'Failed to accept registry provider service request';
export const ERR_REJ_PROVIDER_SVC = 'Failed to reject registry provider service request';
export const ERR_CAN_PROVIDER_SVC = 'Failed to cancel registry provider service request';
export const ERR_TER_PROVIDER_SVC = 'Failed to terminate registry provider service';

// Registry provider mutations
export const SUC_CRE_PROV_CONF = 'Successfully created provider configuration';
export const SUC_REQ_HOLDER_SVC = 'Successfully requested registry holder service';
export const SUC_REQ_REGISTRAR_SVC = 'Successfully requested registry registrar service';
export const SUC_ACC_HOLDER_SVC = 'Successfully accepted registry holder service request';
export const SUC_ACC_REGISTRAR_SVC = 'Successfully accepted registry registrar service request';
export const SUC_REJ_HOLDER_SVC = 'Successfully rejected registry holder service request';
export const SUC_REJ_REGISTRAR_SVC = 'Successfully rejected registry registrar service request';
export const SUC_CAN_HOLDER_SVC = 'Successfully canceled registry holder service request';
export const SUC_CAN_REGISTRAR_SVC = 'Successfully canceled registry registrar service request';
export const SUC_TER_HOLDER_SVC = 'Successfully terminated registry holder service';
export const SUC_TER_REGISTRAR_SVC = 'Successfully terminated registry registrar service';
export const ERR_CRE_PROV_CONF = 'Failed to create provider configuration';
export const ERR_REQ_HOLDER_SVC = 'Failed to request registry holder service';
export const ERR_REQ_REGISTRAR_SVC = 'Failed to request registry registrar service';
export const ERR_ACC_HOLDER_SVC = 'Failed to accept registry holder service request';
export const ERR_ACC_REGISTRAR_SVC = 'Failed to accept registry registrar service request';
export const ERR_REJ_HOLDER_SVC = 'Failed to reject registry holder service request';
export const ERR_REJ_REGISTRAR_SVC = 'Failed to reject registry registrar service request';
export const ERR_CAN_HOLDER_SVC = 'Failed to cancel registry holder service request';
export const ERR_CAN_REGISTRAR_SVC = 'Failed to cancel registry registrar service request';
export const ERR_TER_HOLDER_SVC = 'Failed to terminate registry holder service';
export const ERR_TER_REGISTRAR_SVC = 'Failed to terminate registry registrar service';

// Registry registrar mutations
export const SUC_CRE_INST_CONF = 'Successfully created intsrument configuration';
export const SUC_ACC_MINT = 'Successfully accepted mint request';
export const SUC_REJ_MINT = 'Successfully rejected mint request';
export const SUC_ACC_BURN = 'Successfully accepted burn request';
export const SUC_REJ_BURN = 'Successfully rejected burn request';
export const ERR_CRE_INST_CONF = 'Failed to create instrument configuration';
export const ERR_ACC_MINT = 'Failed to accept mint request';
export const ERR_REJ_MINT = 'Failed to reject mint request';
export const ERR_ACC_BURN = 'Failed to accept burn request';
export const ERR_REJ_BURN = 'Failed to reject burn request';

// Registry holder mutations
export const SUC_REQ_MINT = 'Successfully requested mint';
export const SUC_CAN_MINT = 'Successfully canceled mint request';
export const SUC_REQ_BURN = 'Successfully requested burn';
export const SUC_CAN_BURN = 'Successfully canceled burn request';
export const SUC_OFF_XFER = 'Successfully offered transfer';
export const SUC_ACC_XFER_OFF = 'Successfully accepted transfer offer';
export const SUC_REJ_XFER_OFF = 'Successfully rejected transfer offer';
export const SUC_CAN_XFER_OFF = 'Successfully canceled transfer offer';
export const SUC_REQ_XFER = 'Successfully requested transfer';
export const SUC_ACC_XFER_REQ = 'Successfully accepted transfer request';
export const SUC_REJ_XFER_REQ = 'Successfully rejected transfer request';
export const SUC_CAN_XFER_REQ = 'Successfully canceled transfer request';
export const SUC_OFF_LOCK = 'Successfully offered lock';
export const SUC_ACC_LOCK_OFF = 'Successfully accepted lock offer';
export const SUC_REJ_LOCK_OFF = 'Successfully rejected lock offer';
export const SUC_CAN_LOCK_OFF = 'Successfully canceled lock offer';
export const SUC_REQ_LOCK = 'Successfully requested lock';
export const SUC_ACC_LOCK_REQ = 'Successfully accepted lock request';
export const SUC_REJ_LOCK_REQ = 'Successfully rejected lock request';
export const SUC_CAN_LOCK_REQ = 'Successfully canceled lock request';
export const SUC_OFF_UNLOCK = 'Successfully offered unlock';
export const SUC_ACC_UNLOCK_OFF = 'Successfully accepted unlock offer';
export const SUC_REJ_UNLOCK_OFF = 'Successfully rejected unlock offer';
export const SUC_CAN_UNLOCK_OFF = 'Successfully canceled unlock offer';
export const SUC_REQ_UNLOCK = 'Successfully requested unlock';
export const SUC_ACC_UNLOCK_REQ = 'Successfully accepted unlock request';
export const SUC_REJ_UNLOCK_REQ = 'Successfully rejected unlock request';
export const SUC_CAN_UNLOCK_REQ = 'Successfully canceled unlock request';
export const ERR_REQ_MINT = 'Failed to request mint';
export const ERR_CAN_MINT = 'Failed to cancel mint request';
export const ERR_REQ_BURN = 'Failed to request burn';
export const ERR_CAN_BURN = 'Failed to cancel burn request';
export const ERR_OFF_XFER = 'Failed to offer transfer';
export const ERR_ACC_XFER_OFF = 'Failed to accept transfer offer';
export const ERR_REJ_XFER_OFF = 'Failed to reject transfer offer';
export const ERR_CAN_XFER_OFF = 'Failed to cancel transfer offer';
export const ERR_REQ_XFER = 'Failed to request transfer';
export const ERR_ACC_XFER_REQ = 'Failed to accept transfer request';
export const ERR_REJ_XFER_REQ = 'Failed to reject transfer request';
export const ERR_CAN_XFER_REQ = 'Failed to cancel transfer request';
export const ERR_OFF_LOCK = 'Failed to offer lock';
export const ERR_ACC_LOCK_OFF = 'Failed to accept lock offer';
export const ERR_REJ_LOCK_OFF = 'Failed to reject lock offer';
export const ERR_CAN_LOCK_OFF = 'Failed to cancel lock offer';
export const ERR_REQ_LOCK = 'Failed to request lock';
export const ERR_ACC_LOCK_REQ = 'Failed to accept lock request';
export const ERR_REJ_LOCK_REQ = 'Failed to reject lock request';
export const ERR_CAN_LOCK_REQ = 'Failed to cancel lock request';
export const ERR_OFF_UNLOCK = 'Failed to offer unlock';
export const ERR_ACC_UNLOCK_OFF = 'Failed to accept unlock offer';
export const ERR_REJ_UNLOCK_OFF = 'Failed to reject unlock offer';
export const ERR_CAN_UNLOCK_OFF = 'Failed to cancel unlock offer';
export const ERR_REQ_UNLOCK = 'Failed to request unlock';
export const ERR_ACC_UNLOCK_REQ = 'Failed to accept unlock request';
export const ERR_REJ_UNLOCK_REQ = 'Failed to reject unlock request';
export const ERR_CAN_UNLOCK_REQ = 'Failed to cancel unlock request';

// Trading operator mutations
export const SUC_TRD_CRE_OP_SVC = 'Successfully created trading operator service';
export const SUC_TRD_CRE_OP_CONF = 'Successfully created trading operator configuration';
export const SUC_TRD_REQ_PROVIDER_SVC = 'Successfully requested trading provider service';
export const SUC_TRD_ACC_PROVIDER_SVC = 'Successfully accepted trading provider service request';
export const SUC_TRD_REJ_PROVIDER_SVC = 'Successfully rejected trading provider service request';
export const SUC_TRD_CAN_PROVIDER_SVC = 'Successfully canceled trading provider service request';
export const SUC_TRD_TER_PROVIDER_SVC = 'Successfully terminated trading provider service';
export const ERR_TRD_CRE_OP_SVC = 'Failed to create trading operator service';
export const ERR_TRD_CRE_OP_CONF = 'Failed to create trading operator configuration';
export const ERR_TRD_REQ_PROVIDER_SVC = 'Failed to request trading provider service';
export const ERR_TRD_ACC_PROVIDER_SVC = 'Failed to accept trading provider service request';
export const ERR_TRD_REJ_PROVIDER_SVC = 'Failed to reject trading provider service request';
export const ERR_TRD_CAN_PROVIDER_SVC = 'Failed to cancel trading provider service request';
export const ERR_TRD_TER_PROVIDER_SVC = 'Failed to terminate trading provider service';

// Trading provider mutations
export const SUC_TRD_CRE_PROV_CONF = 'Successfully created trading provider configuration';
export const SUC_TRD_REQ_TRADER_SVC = 'Successfully requested trading trader service';
export const SUC_TRD_ACC_TRADER_SVC = 'Successfully accepted trading trader service request';
export const SUC_TRD_REJ_TRADER_SVC = 'Successfully rejected trading trader service request';
export const SUC_TRD_CAN_TRADER_SVC = 'Successfully canceled trading trader service request';
export const SUC_TRD_TER_TRADER_SVC = 'Successfully terminated trading trader service';
export const ERR_TRD_CRE_PROV_CONF = 'Failed to create provider configuration';
export const ERR_TRD_REQ_TRADER_SVC = 'Failed to request trading trader service';
export const ERR_TRD_ACC_TRADER_SVC = 'Failed to accept trading trader service request';
export const ERR_TRD_REJ_TRADER_SVC = 'Failed to reject trading trader service request';
export const ERR_TRD_CAN_TRADER_SVC = 'Failed to cancel trading trader service request';
export const ERR_TRD_TER_TRADER_SVC = 'Failed to terminate trading trader service';

// Trader mutations
export const SUC_REQ_TRADE = 'Successfully requested trade';
export const SUC_ACC_TRADE_REQ = 'Successfully accepted trade request';
export const SUC_REJ_TRADE_REQ = 'Successfully rejected trade request';
export const SUC_CAN_TRADE_REQ = 'Successfully canceled trade request';
export const SUC_OFF_TRADE = 'Successfully offered trade';
export const SUC_ACC_TRADE_OFF = 'Successfully accepted trade offer';
export const SUC_REJ_TRADE_OFF = 'Successfully rejected trade offer';
export const SUC_CAN_TRADE_OFF = 'Successfully canceled trade offer';
export const SUC_INST_TRADE = 'Successfully instructed trade';
export const ERR_REQ_TRADE = 'Failed to request trade';
export const ERR_ACC_TRADE_REQ = 'Failed to accept trade request';
export const ERR_REJ_TRADE_REQ = 'Failed to reject trade request';
export const ERR_CAN_TRADE_REQ = 'Failed to cancel trade request';
export const ERR_OFF_TRADE = 'Failed to offer trade';
export const ERR_ACC_TRADE_OFF = 'Failed to accept trade offer';
export const ERR_REJ_TRADE_OFF = 'Failed to reject trade offer';
export const ERR_CAN_TRADE_OFF = 'Failed to cancel trade offer';
export const ERR_INST_TRADE = 'Failed to instruct accepted trade';

// Coin mutations
export const SUC_TAP_COIN = 'Successfully tapped coin';
export const ERR_TAP_COIN = 'Failed to tap coin';
