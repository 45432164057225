import {
  GetOpenAndIssuingMiningRoundsProxyResponse,
  ScanProxyApi,
} from 'validator-scan-proxy-api-ts-client';
import {
  AmuletRules,
  AppTransferContext,
} from '@daml.js/splice-amulet-0.1.3/lib/Splice/AmuletRules';
import { OpenMiningRound } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Round';
import { DisclosedContract } from '@daml/types';
import TemplateContract from './TemplateContract';

export const appTransferContextWithDisclosures = async (
  scanProxyApi: ScanProxyApi,
  openMiningRoundOpeningTimeOffsetSeconds: number,
) => {
  const amuletRulesResponse = await scanProxyApi.getAmuletRules();
  const amuletRules = TemplateContract.fromScanProxyAPI(
    AmuletRules,
    amuletRulesResponse.amulet_rules.contract,
  );

  const openMiningRoundResponse: GetOpenAndIssuingMiningRoundsProxyResponse =
    await scanProxyApi.getOpenAndIssuingMiningRounds();
  const openMiningRounds: TemplateContract<OpenMiningRound>[] =
    openMiningRoundResponse.open_mining_rounds.map((o) =>
      TemplateContract.fromScanProxyAPI(OpenMiningRound, o.contract),
    );

  const ts = new Date();
  ts.setSeconds(ts.getSeconds() - openMiningRoundOpeningTimeOffsetSeconds);
  const tsStr = ts.toISOString();
  const latestOpenRound = openMiningRounds.reduce((acc, cur) =>
    cur.payload.opensAt < tsStr ? cur : acc,
  );
  const appTransferContext: AppTransferContext = {
    amuletRules: amuletRules.contractId,
    openMiningRound: latestOpenRound.contractId,
    featuredAppRight: null,
  };
  const disclosedContracts: DisclosedContract[] = [
    {
      contractId: amuletRules.contractId,
      templateId: amuletRules.templateId,
      createdEventBlob: amuletRules.createdEventBlob!,
    },
    {
      contractId: latestOpenRound.contractId,
      templateId: latestOpenRound.templateId,
      createdEventBlob: latestOpenRound.createdEventBlob!,
    },
  ];
  const latestAmuletPrice = latestOpenRound.payload.amuletPrice;

  return { appTransferContext, disclosedContracts, latestAmuletPrice };
};
