import { useCallback, useState } from 'react';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import { SelectInput } from '../../../components/Form/SelectInput';
import TextInput from '../../../components/Form/TextInput';
import useRequestHolderService from '../../../hooks/mutations/registry/onboarding/useRequestHolderService';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useCredentials from '../../../hooks/queries/credential/useCredentials';
import { prettyParty } from '../../../utils/common';
import { ERR_REQ_HOLDER_SVC, SUC_REQ_HOLDER_SVC } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const RequestHolderServiceDialog = ({ open, onClose }: DialogProps) => {
  const [provider, setProvider] = useState<string>('');
  const [credentialCid, setCredentialCid] = useState<string>('');
  const { party } = useParties();
  const credentials = useCredentials();
  const requestHolderService = useRequestHolderService();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setProvider('');
    setCredentialCid('');
  }, [setProvider, setCredentialCid]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      provider,
      credentialCid: credentialCid as ContractId<Credential>,
    };
    const res = await mutate(requestHolderService, payload, SUC_REQ_HOLDER_SVC, ERR_REQ_HOLDER_SVC);
    handleClose();
    return res;
  }, [requestHolderService, provider, credentialCid, handleClose, mutate]);

  const setCredential = useCallback(
    (value: string) => {
      const credential = credentials.data!.find((c) => c.contractId === value);
      if (!credential) throw new Error('Credential not found');
      setCredentialCid(value);
      setProvider(credential.payload.issuer);
    },
    [credentials.data, setCredentialCid, setProvider],
  );

  const isLoading = credentials.isLoading || !credentials.data;
  if (isLoading) return null;

  const disableSubmit = !provider || !credentialCid;
  const values = credentials.data
    .filter((c) => c.payload.holder === party)
    .map((c) => ({
      value: c.contractId,
      display: `${c.payload.id} (issued by: ${prettyParty(c.payload.issuer)})`,
    }));
  return (
    <FormDialog
      open={open}
      title="Request Holder Service"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Request"
      isSubmitDisabled={disableSubmit}
    >
      <SelectInput
        label="Credential"
        value={credentialCid}
        setValue={setCredential}
        values={values}
      />
      <TextInput label="Provider" value={provider} setValue={setProvider} required disabled />
    </FormDialog>
  );
};
