import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  CredentialBilling,
  CredentialBilling_Cancel_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import { appTransferContextWithDisclosures } from '../../../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import { useScanProxyApiClient } from '../../../other/useScanProxyApiClient';

const useCancelCredentialBilling: () => UseMutationResult<
  CredentialBilling_Cancel_Result,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialBillingCid: ContractId<CredentialBilling>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();

  return useMutation({
    mutationKey: ['cancelCredentialBilling', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialBillingCid }) => {
      // We fetch all data in the mutation function to avoid using outdated mining round / amulet
      // rules, as there is a (potentially large) difference in time between the hook being created
      // and the mutation function being called.
      const { appTransferContext, disclosedContracts } = await appTransferContextWithDisclosures(
        scanProxyApi,
        30,
      );

      return ledgerApi.cancelCredentialBilling(
        userServiceCid,
        credentialBillingCid,
        appTransferContext,
        disclosedContracts,
      );
    },
  });
};

export default useCancelCredentialBilling;
