import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  UserService,
  UserService_OfferPaidCredential_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { BillingParams } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Types';
import { Claim } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useOfferPaidCredential: () => UseMutationResult<
  UserService_OfferPaidCredential_Result,
  string,
  {
    userServiceCid: ContractId<UserService>;
    holder: string;
    id: string;
    description: string;
    claims: Claim[];
    billingParams: BillingParams;
    depositInitialAmountUsd: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['offerPaidCredential', ledgerApi],
    mutationFn: async ({
      userServiceCid,
      holder,
      id,
      description,
      claims,
      billingParams,
      depositInitialAmountUsd,
    }) => {
      return ledgerApi.offerPaidCredential(
        userServiceCid,
        holder,
        id,
        description,
        claims,
        billingParams,
        depositInitialAmountUsd,
      );
    },
  });
};

export default useOfferPaidCredential;
