import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  AcceptedUnlock,
  UnlockOffer,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptUnlockOffer: () => UseMutationResult<
  ContractId<AcceptedUnlock>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    unlockOfferCid: ContractId<UnlockOffer>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptUnlockOffer', ledgerApi],
    mutationFn: async ({ holderServiceCid, unlockOfferCid }) => {
      return ledgerApi.acceptUnlockOffer(holderServiceCid, unlockOfferCid);
    },
  });
};

export default useAcceptUnlockOffer;
