import React, { PropsWithChildren, useMemo } from 'react';
import useDsoParty from '../hooks/queries/cn/useDsoParty';
import usePrimaryParty from '../hooks/queries/usePrimaryParty';

export const PartiesContext = React.createContext<PartiesState>({
  operator: '',
  party: '',
  dso: '',
});

export interface PartiesProps {
  operator: string;
}

export type PartiesState = {
  operator: string;
  party: string;
  dso: string;
};

export const PartiesProvider: React.FC<PropsWithChildren<PartiesProps>> = ({
  operator,
  children,
}) => {
  const dsoParty = useDsoParty();
  const primaryParty = usePrimaryParty();
  const value = useMemo(() => {
    return {
      operator,
      party: primaryParty.data ?? '',
      dso: dsoParty.data ?? '',
    };
  }, [operator, primaryParty.data, dsoParty]);
  return <PartiesContext.Provider value={value}>{children}</PartiesContext.Provider>;
};
