import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { RegistrarServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectRegistrarServiceRequest: () => UseMutationResult<
  void,
  string,
  {
    registrarServiceRequestCid: ContractId<RegistrarServiceRequest>;
    rejectReason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectRegistryRegistrarServiceRequest', ledgerApi],
    mutationFn: async ({ registrarServiceRequestCid, rejectReason }) => {
      return ledgerApi.rejectRegistryRegistrarServiceRequest(
        registrarServiceRequestCid,
        rejectReason,
      );
    },
  });
};

export default useRejectRegistrarServiceRequest;
