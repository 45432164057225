import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  CredentialOffer,
  CredentialOffer_AcceptFree_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptFreeCredentialOffer: () => UseMutationResult<
  CredentialOffer_AcceptFree_Result,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialOfferCid: ContractId<CredentialOffer>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptFreeCredentialOffer', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialOfferCid }) => {
      return ledgerApi.acceptFreeCredentialOffer(userServiceCid, credentialOfferCid);
    },
  });
};

export default useAcceptFreeCredentialOffer;
