import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Direction,
  Trade,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import ToggleInput from '../../../components/Form/ToggleInput';
import useRequestTrade from '../../../hooks/mutations/trading/trade/useRequestTrade';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import { ERR_REQ_TRADE, SUC_REQ_TRADE } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const RequestTradeDialog = ({ open, onClose }: DialogProps) => {
  const [direction, setDirection] = useState<Direction>(Direction.Buy);
  const [instrumentId, setInstrumentId] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [currencyId, setCurrencyId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [counterparty, setCounterparty] = useState<string>('');

  const { party } = useParties();
  const traderService = useTraderService();
  const requestTrade = useRequestTrade();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setDirection(Direction.Buy);
    setInstrumentId('');
    setAmount('');
    setCurrencyId('');
    setDescription('');
    setCounterparty('');
  }, [setDirection, setInstrumentId, setAmount, setCurrencyId, setDescription, setCounterparty]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!traderService.data) throw new Error('Trader service not found');
    const trade: Trade = {
      operator: traderService.data.payload.operator,
      provider: traderService.data.payload.provider,
      buyer: direction === Direction.Buy ? party : counterparty,
      seller: direction === Direction.Sell ? party : counterparty,
      id: uuidv4(),
      description,
      instrumentId,
      amount,
      currencyId,
    };
    const payload = {
      traderServiceCid: traderService.data.contractId,
      trade,
      direction,
    };
    await mutate(requestTrade, payload, SUC_REQ_TRADE, ERR_REQ_TRADE);
    handleClose();
  }, [
    traderService,
    direction,
    party,
    counterparty,
    description,
    instrumentId,
    amount,
    currencyId,
    requestTrade,
    mutate,
    handleClose,
  ]);

  const { isLoading } = traderService;
  if (isLoading) return null;

  const directions = [
    { value: Direction.Buy, display: 'Buy' },
    { value: Direction.Sell, display: 'Sell' },
  ];
  const disableSubmit = !description || !instrumentId || !amount || !currencyId || !counterparty;
  return (
    <FormDialog
      open={open}
      title="Request Trade"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Request"
      isSubmitDisabled={disableSubmit}
    >
      <ToggleInput
        label="Direction"
        value={direction}
        setValue={(s) => setDirection(s as Direction)}
        values={directions}
      />
      <TextInput label="Instrument" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput label="Amount" value={amount} setValue={setAmount} required />
      <TextInput label="Currency" value={currencyId} setValue={setCurrencyId} required />
      <TextInput label="Description" value={description} setValue={setDescription} required />
      <TextInput label="Counterparty" value={counterparty} setValue={setCounterparty} required />
    </FormDialog>
  );
};
