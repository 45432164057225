import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TraderServiceRequest,
  TraderServiceRequest_Reject_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectTraderServiceRequest: () => UseMutationResult<
  TraderServiceRequest_Reject_Result,
  string,
  {
    traderServiceRequestCid: ContractId<TraderServiceRequest>;
    rejectReason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTradingTraderServiceRequest', ledgerApi],
    mutationFn: async ({ traderServiceRequestCid, rejectReason }) => {
      return ledgerApi.rejectTradingTraderServiceRequest(traderServiceRequestCid, rejectReason);
    },
  });
};

export default useRejectTraderServiceRequest;
