import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeOffer,
  TradeOffer_Cancel_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelTradeOffer: () => UseMutationResult<
  TradeOffer_Cancel_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeOfferCid: ContractId<TradeOffer>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelTradeOffer', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeOfferCid }) => {
      return ledgerApi.cancelTradeOffer(traderServiceCid, tradeOfferCid);
    },
  });
};

export default useCancelTradeOffer;
