import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { Amulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import {
  CredentialOffer,
  CredentialOffer_AcceptPaid_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import { appTransferContextWithDisclosures } from '../../../../utils/appTransferContextWithDisclosures';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import { useScanProxyApiClient } from '../../../other/useScanProxyApiClient';

const useAcceptPaidCredentialOffer: () => UseMutationResult<
  CredentialOffer_AcceptPaid_Result,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialOfferCid: ContractId<CredentialOffer>;
    amuletCids: ContractId<Amulet>[];
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const scanProxyApi = useScanProxyApiClient();

  return useMutation({
    mutationKey: ['acceptPaidCredentialOffer', ledgerApi, scanProxyApi],
    mutationFn: async ({ userServiceCid, credentialOfferCid, amuletCids }) => {
      const { appTransferContext, disclosedContracts } = await appTransferContextWithDisclosures(
        scanProxyApi,
        30,
      );

      return ledgerApi.acceptPaidCredentialOffer(
        userServiceCid,
        credentialOfferCid,
        amuletCids,
        appTransferContext,
        disclosedContracts,
      );
    },
  });
};

export default useAcceptPaidCredentialOffer;
