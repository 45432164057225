import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import TemplateContract from '../../../utils/TemplateContract';
import useLedgerApiClient from '../../other/useLedgerApiClient';

const useCredentials: () => UseQueryResult<TemplateContract<Credential>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  return useQuery({
    queryKey: ['queryCredentials', ledgerApi],
    queryFn: async () => {
      return ledgerApi.queryCredentials();
    },
  });
};

export default useCredentials;
