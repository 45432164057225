import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ProviderServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelProviderServiceRequest: () => UseMutationResult<
  void,
  string,
  { providerServiceRequestCid: ContractId<ProviderServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelRegistryProviderServiceRequest', ledgerApi],
    mutationFn: async ({ providerServiceRequestCid }) => {
      return ledgerApi.cancelRegistryProviderServiceRequest(providerServiceRequestCid);
    },
  });
};

export default useCancelProviderServiceRequest;
