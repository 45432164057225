import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useUserServices: () => UseQueryResult<TemplateContract<UserService>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryCredentialUserServices', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryCredentialUserServices(operator);
    },
  });
};

export default useUserServices;
