import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useRequestBurn from '../../../hooks/mutations/registry/burn/useRequestBurn';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import TemplateContract from '../../../utils/TemplateContract';
import { ERR_REQ_BURN, SUC_REQ_BURN } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  holding?: TemplateContract<Holding>;
};

export const RequestBurnDialog = ({ open, onClose, holding }: DialogProps) => {
  const [instrumentId, setInstrumentId] = useState<string>(holding?.payload.instrument.id || '');
  const [amount, setAmount] = useState<string>(holding?.payload.amount || '');
  const [label, setLabel] = useState<string>(holding?.payload.label || '');
  const [registrar, setRegistrar] = useState<string>(holding?.payload.registrar || '');
  const holderService = useHolderService();
  const requestBurn = useRequestBurn();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setInstrumentId('');
    setAmount('');
    setLabel('');
    setRegistrar('');
  }, [setInstrumentId, setAmount, setLabel, setRegistrar]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      registrar,
      instrumentId,
      amount,
      holdingLabel: label,
      batch: { id: uuidv4(), size: '1', settlementFrom: null, settlementUntil: null },
    };
    await mutate(requestBurn, payload, SUC_REQ_BURN, ERR_REQ_BURN);
    handleClose();
  }, [holderService, label, amount, instrumentId, registrar, requestBurn, handleClose, mutate]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !instrumentId || !amount || !registrar || !label;
  return (
    <FormDialog
      open={open}
      title="Request Burn"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Request"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Instrument" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput label="Amount" value={amount} setValue={setAmount} required />
      <TextInput label="Label" value={label} setValue={setLabel} required />
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
    </FormDialog>
  );
};
