import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { BurnRequest } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Burn';
import { Batch } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRequestBurn: () => UseMutationResult<
  ContractId<BurnRequest>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    registrar: string;
    instrumentId: string;
    amount: string;
    holdingLabel: string;
    batch: Batch;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['requestBurn', ledgerApi],
    mutationFn: async ({
      holderServiceCid,
      registrar,
      instrumentId,
      amount,
      holdingLabel,
      batch,
    }) => {
      return ledgerApi.requestBurn(
        holderServiceCid,
        registrar,
        instrumentId,
        amount,
        holdingLabel,
        batch,
      );
    },
  });
};

export default useRequestBurn;
