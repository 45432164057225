import { useCallback, useState } from 'react';
import { Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { AsyncButton } from '../../components/Button/AsyncButton';
import { SyncButton } from '../../components/Button/SyncButton';
import Loading from '../../components/Loading';
import { QueryTable } from '../../components/Table/QueryTable';
import useRevokeCredential from '../../hooks/mutations/credential/useRevokeCredential';
import useRevokeCredentialAndCancelBilling from '../../hooks/mutations/credential/useRevokeCredentialAndCancelBilling';
import useMutate from '../../hooks/mutations/useMutate';
import useParties from '../../hooks/other/useParties';
import useCredentialBillings from '../../hooks/queries/credential/billing/useCredentialBillings';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import useCredentials from '../../hooks/queries/credential/useCredentials';
import TemplateContract from '../../utils/TemplateContract';
import { prettyParty } from '../../utils/common';
import {
  ERR_REV_CAN_CRED,
  ERR_REV_CRED,
  SUC_REV_CAN_CRED,
  SUC_REV_CRED,
} from '../../utils/strings';
import { OfferFreeCredentialDialog } from './OfferFreeCredentialDialog';
import { OfferPaidCredentialDialog } from './OfferPaidCredentialDialog';

type Row = TemplateContract<Credential>;

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'issuer',
      headerName: 'Issuer',
      valueGetter: (params) => prettyParty(params.row.payload.issuer),
      flex: 50,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 50,
      filterable: false,
    },
    {
      field: 'id',
      headerName: 'Id',
      valueGetter: (params) => params.row.payload.id,
      flex: 100,
      filterable: false,
    },
    {
      field: 'claims',
      headerName: 'Claims',
      renderCell: (params) => (
        <>
          {params.row.payload.claims.map((c) => (
            <>
              <Chip key={c.subject} label={prettyParty(c.subject)} />
              <Chip key={c.property} label={c.property} />
              <Chip key={c.value} label={c.value} />
            </>
          ))}
        </>
      ),
      flex: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: renderActionCell,
      flex: 150,
      sortable: false,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [openOfferFreeDialog, setOpenOfferFreeDialog] = useState<boolean>(false);
  const [openOfferPaidDialog, setOpenOfferPaidDialog] = useState<boolean>(false);

  return (
    <GridToolbarContainer>
      <OfferFreeCredentialDialog
        open={openOfferFreeDialog}
        onClose={() => setOpenOfferFreeDialog(false)}
      />
      <OfferPaidCredentialDialog
        open={openOfferPaidDialog}
        onClose={() => setOpenOfferPaidDialog(false)}
      />
      <SyncButton onClick={() => setOpenOfferFreeDialog(true)}>Offer free credential</SyncButton>
      <SyncButton onClick={() => setOpenOfferPaidDialog(true)}>Offer paid credential</SyncButton>
    </GridToolbarContainer>
  );
};

export const Credentials = () => {
  const { party } = useParties();
  const userService = useUserService();
  const credentials = useCredentials();
  const billings = useCredentialBillings();
  const revokeCredential = useRevokeCredential();
  const revokeAndCancelBilling = useRevokeCredentialAndCancelBilling();
  const mutate = useMutate();

  const revoke = useCallback(
    (r: Row) => {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialCid: r.contractId,
      };
      return mutate(revokeCredential, payload, SUC_REV_CRED, ERR_REV_CRED);
    },
    [userService.data, revokeCredential, mutate],
  );

  const revokeAndCancel = useCallback(
    (r: Row) => {
      const billing = billings.data?.find((b) => b.payload.credentialId === r.payload.id);
      if (!billing) throw new Error('Billing not found');
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialCid: r.contractId,
        credentialBillingCid: billing.contractId,
      };
      return mutate(revokeAndCancelBilling, payload, SUC_REV_CAN_CRED, ERR_REV_CAN_CRED);
    },
    [userService.data, billings.data, revokeAndCancelBilling, mutate],
  );

  const isLoading = credentials.isLoading || userService.isLoading;
  if (isLoading) return <Loading />;

  const createActions = (r: Row) => {
    if (r.payload.issuer === party)
      return [
        { name: 'Revoke', action: revoke },
        { name: 'Revoke And Cancel', action: revokeAndCancel },
      ];
    return [];
  };

  return (
    <QueryTable
      title="Credentials"
      variant="h3"
      columns={createHeaders(createActions)}
      rowQuery={credentials}
      rowKey={(a) => a.contractId}
      toolbar={Toolbar}
    />
  );
};
