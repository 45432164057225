import { useCallback, useState } from 'react';
import {
  Trade,
  TradeRequest,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptTradeRequest from '../../../hooks/mutations/trading/trade/useAcceptTradeRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import { ERR_ACC_TRADE_REQ, SUC_ACC_TRADE_REQ } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  tradeRequest?: { contractId: string; state: string; payload: Trade };
};

export const AcceptTradeRequestDialog = ({ open, onClose, tradeRequest }: DialogProps) => {
  const [pricePerUnit, setPricePerUnit] = useState<string>('');
  const [acceptorLabel, setAcceptorLabel] = useState<string>('');

  const traderService = useTraderService();
  const acceptTradeRequest = useAcceptTradeRequest();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setPricePerUnit('');
    setAcceptorLabel('');
  }, [setPricePerUnit, setAcceptorLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!traderService.data) throw new Error('Trader service not found');
    if (!tradeRequest) throw new Error('Trade request not set');
    const payload = {
      traderServiceCid: traderService.data.contractId,
      tradeRequestCid: tradeRequest.contractId as ContractId<TradeRequest>,
      pricePerUnit,
      acceptorLabel,
    };
    await mutate(acceptTradeRequest, payload, SUC_ACC_TRADE_REQ, ERR_ACC_TRADE_REQ);
    handleClose();
  }, [
    traderService.data,
    tradeRequest,
    pricePerUnit,
    acceptorLabel,
    acceptTradeRequest,
    mutate,
    handleClose,
  ]);

  const isLoading = traderService.isLoading || !traderService.data;
  if (isLoading) return null;

  const disableSubmit = !pricePerUnit;
  return (
    <FormDialog
      open={open}
      title="Accept trade request"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Price per unit" value={pricePerUnit} setValue={setPricePerUnit} required />
      <TextInput label="Account label" value={acceptorLabel} setValue={setAcceptorLabel} />
    </FormDialog>
  );
};
