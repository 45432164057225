import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TraderServiceRequest,
  TraderServiceRequest_Cancel_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelTraderServiceRequest: () => UseMutationResult<
  TraderServiceRequest_Cancel_Result,
  string,
  { traderServiceRequestCid: ContractId<TraderServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelTradingTraderServiceRequest', ledgerApi],
    mutationFn: async ({ traderServiceRequestCid }) => {
      return ledgerApi.cancelTradingTraderServiceRequest(traderServiceRequestCid);
    },
  });
};

export default useCancelTraderServiceRequest;
