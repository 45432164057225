import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import TemplateContract from '../../../utils/TemplateContract';
import useLedgerApiClient from '../../other/useLedgerApiClient';

const useHoldings: () => UseQueryResult<TemplateContract<Holding>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  return useQuery({
    queryKey: ['queryHoldings', ledgerApi],
    queryFn: async () => {
      return ledgerApi.queryHoldings();
    },
  });
};

export default useHoldings;
