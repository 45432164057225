import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRegistrarServices: () => UseQueryResult<
  TemplateContract<RegistrarService>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryRegistryRegistrarServices', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryRegistryRegistrarServices(operator);
    },
  });
};

export default useRegistrarServices;
