import { useCallback, useState } from 'react';
import { Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CredentialOffer } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import { Claim } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { AsyncButton } from '../../components/Button/AsyncButton';
import { SyncButton } from '../../components/Button/SyncButton';
import Loading from '../../components/Loading';
import { QueryTable } from '../../components/Table/QueryTable';
import useAcceptFreeCredentialOffer from '../../hooks/mutations/credential/offer/useAcceptFreeCredentialOffer';
import useAcceptPaidCredentialOffer from '../../hooks/mutations/credential/offer/useAcceptPaidCredentialOffer';
import useCancelCredentialOffer from '../../hooks/mutations/credential/offer/useCancelCredentialOffer';
import useMutate from '../../hooks/mutations/useMutate';
import useParties from '../../hooks/other/useParties';
import useCoins from '../../hooks/queries/cn/useCoin';
import useCredentialOffers from '../../hooks/queries/credential/offer/useCredentialOffers';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import TemplateContract from '../../utils/TemplateContract';
import { prettyParty } from '../../utils/common';
import {
  ERR_ACC_CRED_FREE_OFF,
  ERR_ACC_CRED_PAID_OFF,
  ERR_CAN_CRED_OFF,
  SUC_ACC_CRED_FREE_OFF,
  SUC_ACC_CRED_PAID_OFF,
  SUC_CAN_CRED_OFF,
} from '../../utils/strings';
import { RejectOfferPaidCredentialDialog } from './RejectCredentialOfferDialog';

type Row = TemplateContract<CredentialOffer>;

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (
  createActions: (r: Row) => Action[],
  setOpenRejectCredentialOfferDialog: React.Dispatch<React.SetStateAction<boolean>>,
  party: string,
  setRow: React.Dispatch<React.SetStateAction<Row | undefined>>,
) => GridColDef<Row>[] = (createActions, setOpenRejectCredentialOfferDialog, party, setRow) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 100,
      filterable: false,
    },
    {
      field: 'issuer',
      headerName: 'Issuer',
      valueGetter: (params) => prettyParty(params.row.payload.issuer),
      flex: 100,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 100,
      filterable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      valueGetter: (params) => params.row.payload.description,
      flex: 150,
      filterable: false,
    },
    {
      field: 'feeperdayusd',
      headerName: 'Fee/Day($)',
      valueGetter: (params) => params.row.payload.billingParams?.feePerDayUsd.rate,
      flex: 150,
      filterable: false,
    },
    {
      field: 'billingperiod',
      headerName: 'Billing Period(mins)',
      valueGetter: (params) => params.row.payload.billingParams?.billingPeriodMinutes,
      flex: 220,
      filterable: false,
    },
    {
      field: 'credentialfeesreserve',
      headerName: 'Fees Reserve($)',
      valueGetter: (params) => params.row.payload.depositInitialAmountUsd,
      flex: 150,
      filterable: false,
    },
    {
      field: 'claims',
      headerName: 'Claims',
      renderCell: (params) => (
        <>
          {params.row.payload.claims.map((c: Claim) => (
            <>
              <Chip key={c.subject} label={prettyParty(c.subject)} />
              <Chip key={c.property} label={c.property} />
              <Chip key={c.value} label={c.value} />
            </>
          ))}
        </>
      ),
      flex: 250,
      sortable: false,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Accept/Cancel',
      sortable: false,
      renderCell: renderActionCell,
      flex: 250,
      filterable: false,
    },
    {
      field: 'reject',
      headerName: 'Reject',
      sortable: false,
      renderCell: (params) => (
        <SyncButton
          disabled={params.row.payload.holder !== party}
          onClick={() => {
            setOpenRejectCredentialOfferDialog(true);
            setRow(params.row);
          }}
        >
          Reject
        </SyncButton>
      ),
      flex: 150,
      filterable: false,
    },
  ];
};

export const Offers = () => {
  const [openRejectCredentialOfferDialog, setOpenRejectCredentialOfferDialog] =
    useState<boolean>(false);
  const [getRow, setRow] = useState<Row>();
  const { party } = useParties();
  const credentialOffers = useCredentialOffers();
  const userService = useUserService();
  const acceptFreeCredentialOffer = useAcceptFreeCredentialOffer();
  const acceptPaidCredentialOffer = useAcceptPaidCredentialOffer();
  const withdrawCredentialOffer = useCancelCredentialOffer();
  const amulets = useCoins();
  const mutate = useMutate();

  const accept = useCallback(
    (r: Row) => {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialOfferCid: r.contractId,
        amuletCids: (amulets.data ?? []).map((a) => a.contractId),
      };
      if (r.payload.billingParams)
        return mutate(
          acceptPaidCredentialOffer,
          payload,
          SUC_ACC_CRED_PAID_OFF,
          ERR_ACC_CRED_PAID_OFF,
        );
      return mutate(
        acceptFreeCredentialOffer,
        payload,
        SUC_ACC_CRED_FREE_OFF,
        ERR_ACC_CRED_FREE_OFF,
      );
    },
    [userService.data, amulets.data, acceptFreeCredentialOffer, acceptPaidCredentialOffer, mutate],
  );

  const cancel = useCallback(
    (r: Row) => {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialOfferCid: r.contractId,
      };
      return mutate(withdrawCredentialOffer, payload, SUC_CAN_CRED_OFF, ERR_CAN_CRED_OFF);
    },
    [userService.data, withdrawCredentialOffer, mutate],
  );

  const isLoading = credentialOffers.isLoading || userService.isLoading;
  if (isLoading) return <Loading />;

  const createActions = (r: Row) => {
    let actions: Action[] = [];
    if (r.payload.holder === party) actions = actions.concat([{ name: 'Accept', action: accept }]);
    if (r.payload.issuer === party) actions = actions.concat([{ name: 'Cancel', action: cancel }]);
    return actions;
  };

  return (
    <>
      <RejectOfferPaidCredentialDialog
        open={openRejectCredentialOfferDialog}
        onClose={() => setOpenRejectCredentialOfferDialog(false)}
        row={getRow}
      />
      <QueryTable
        title="Credential Offers"
        variant="h3"
        columns={createHeaders(createActions, setOpenRejectCredentialOfferDialog, party, setRow)}
        rowQuery={credentialOffers}
        rowKey={(a) => a.contractId}
      />
    </>
  );
};
