import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import { RegistrarServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptRegistrarServiceRequest: () => UseMutationResult<
  void,
  string,
  {
    registrarServiceRequestCid: ContractId<RegistrarServiceRequest>;
    providerConfigurationCid: ContractId<ProviderConfiguration>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useMutation({
    mutationKey: ['acceptRegistryRegistrarServiceRequest', ledgerApi, operator],
    mutationFn: async ({ registrarServiceRequestCid, providerConfigurationCid }) => {
      return ledgerApi.acceptRegistryRegistrarServiceRequest(
        registrarServiceRequestCid,
        providerConfigurationCid,
      );
    },
  });
};

export default useAcceptRegistrarServiceRequest;
