import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UnlockOffer } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useUnlockOffers: () => UseQueryResult<TemplateContract<UnlockOffer>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useQuery({
    queryKey: ['queryUnlockOffers', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryUnlockOffers(operator);
    },
  });
};

export default useUnlockOffers;
