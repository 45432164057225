import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RegistrarServiceRequest } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRegistrarServiceRequests: () => UseQueryResult<
  TemplateContract<RegistrarServiceRequest>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryRegistryRegistrarServiceRequests', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryRegistryRegistrarServiceRequests(operator);
    },
  });
};

export default useRegistrarServiceRequests;
