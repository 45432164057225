import Loading from '../../../components/Loading';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useProviderService from '../../../hooks/queries/registry/onboarding/useProviderService';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import { InstrumentConfigurations } from './InstrumentConfigurations';
import { OperatorConfigurations } from './OperatorConfigurations';
import { ProviderConfigurations } from './ProviderConfigurations';

export const Configurations = () => {
  const registrarService = useRegistrarService();
  const providerService = useProviderService();
  const operatorService = useOperatorService();

  const isLoading =
    registrarService.isLoading || providerService.isLoading || operatorService.isLoading;
  if (isLoading) return <Loading />;

  const isRegistrar = !!registrarService.data;
  const isProvider = !!providerService.data;
  const isOperator = !!operatorService.data;

  return (
    <>
      {isOperator && <OperatorConfigurations />}
      {(isOperator || isProvider) && <ProviderConfigurations />}
      {(isOperator || isProvider || isRegistrar) && <InstrumentConfigurations />}
    </>
  );
};
