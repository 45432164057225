import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  ProviderServiceRequest,
  ProviderServiceRequest_Reject_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectProviderServiceRequest: () => UseMutationResult<
  ProviderServiceRequest_Reject_Result,
  string,
  {
    providerServiceRequestCid: ContractId<ProviderServiceRequest>;
    rejectReason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTradingProviderServiceRequest', ledgerApi],
    mutationFn: async ({ providerServiceRequestCid, rejectReason }) => {
      return ledgerApi.rejectTradingProviderServiceRequest(providerServiceRequestCid, rejectReason);
    },
  });
};

export default useRejectProviderServiceRequest;
