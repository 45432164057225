import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  Direction,
  Trade,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import {
  TraderService,
  TraderService_OfferTrade_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useOfferTrade: () => UseMutationResult<
  TraderService_OfferTrade_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    trade: Trade;
    direction: Direction;
    pricePerUnit: string;
    offerorLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['offerTrade', ledgerApi],
    mutationFn: async ({ traderServiceCid, trade, direction, pricePerUnit, offerorLabel }) => {
      return ledgerApi.offerTrade(traderServiceCid, trade, direction, pricePerUnit, offerorLabel);
    },
  });
};

export default useOfferTrade;
