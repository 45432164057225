import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { UserServiceRequest } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { CreateEvent } from '@daml/ledger';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useCreateUserServiceRequest: () => UseMutationResult<
  CreateEvent<UserServiceRequest>,
  string,
  void
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();

  return useMutation({
    mutationKey: ['createUserServiceRequest', ledgerApi],
    mutationFn: async () => {
      return ledgerApi.createCredentialUserServiceRequest(operator, party);
    },
  });
};

export default useCreateUserServiceRequest;
