import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  ProviderService,
  ProviderService_Terminate_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTerminateProviderService: () => UseMutationResult<
  ProviderService_Terminate_Result,
  string,
  { providerServiceCid: ContractId<ProviderService> }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();

  return useMutation({
    mutationKey: ['terminateTradingProviderService', ledgerApi],
    mutationFn: async ({ providerServiceCid }) => {
      return ledgerApi.terminateTradingProviderService(providerServiceCid, party);
    },
  });
};

export default useTerminateProviderService;
