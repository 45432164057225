import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import { ProviderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCreateProviderConfiguration: () => UseMutationResult<
  ContractId<ProviderConfiguration>,
  string,
  {
    providerServiceCid: ContractId<ProviderService>;
    holderRequirement: PartyCredentialRequirement;
    registrarRequirement: PartyCredentialRequirement;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['createRegistryProviderConfiguration', ledgerApi],
    mutationFn: async ({ providerServiceCid, holderRequirement, registrarRequirement }) => {
      return ledgerApi.createRegistryProviderConfiguration(
        providerServiceCid,
        holderRequirement,
        registrarRequirement,
      );
    },
  });
};

export default useCreateProviderConfiguration;
