import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OperatorService } from '@daml.js/utility-registry-operator-v0/lib/Utility/Registry/Operator/V0/Service/Operator';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useOperatorService: () => UseQueryResult<
  TemplateContract<OperatorService> | null,
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryRegistryOperatorService', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryRegistryOperatorService(operator, party);
    },
  });
};

export default useOperatorService;
