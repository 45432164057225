import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AmuletRules } from '@daml.js/splice-amulet-0.1.3/lib/Splice/AmuletRules';
import TemplateContract from '../../../utils/TemplateContract';
import { useScanProxyApiClient } from '../../other/useScanProxyApiClient';

const useCoinRules: () => UseQueryResult<TemplateContract<AmuletRules>, string> = () => {
  const scanProxyApi = useScanProxyApiClient();

  return useQuery({
    queryKey: ['getAmuletRules', scanProxyApi],
    queryFn: async () => {
      const amuletRulesResponse = await scanProxyApi.getAmuletRules();
      const amuletRules = TemplateContract.fromScanProxyAPI(
        AmuletRules,
        amuletRulesResponse.amulet_rules.contract,
      );
      return amuletRules;
    },
    refetchInterval: false,
  });
};

export default useCoinRules;
