import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  UserService,
  UserService_OfferFreeCredential_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { Claim } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useOfferFreeCredential: () => UseMutationResult<
  UserService_OfferFreeCredential_Result,
  string,
  {
    userServiceCid: ContractId<UserService>;
    holder: string;
    id: string;
    description: string;
    claims: Claim[];
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['offerFreeCredential', ledgerApi],
    mutationFn: async ({ userServiceCid, holder, id, description, claims }) => {
      return ledgerApi.offerFreeCredential(userServiceCid, holder, id, description, claims);
    },
  });
};

export default useOfferFreeCredential;
