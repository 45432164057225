import { useState } from 'react';
import { Chip } from '@mui/material';
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import { SyncButton } from '../../../components/Button/SyncButton';
import Loading from '../../../components/Loading';
import { QueryTable } from '../../../components/Table/QueryTable';
import useRegistryProviderConfigurations from '../../../hooks/queries/registry/configuration/useRegistryProviderConfigurations';
import useProviderService from '../../../hooks/queries/registry/onboarding/useProviderService';
import TemplateContract from '../../../utils/TemplateContract';
import { prettyParty } from '../../../utils/common';
import { CreateProviderConfigurationDialog } from './CreateProviderConfigurationDialog';

type Row = TemplateContract<ProviderConfiguration>;

const createHeaders: () => GridColDef<Row>[] = () => {
  return [
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.payload.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      valueGetter: (params) => prettyParty(params.row.payload.provider),
      flex: 150,
      filterable: false,
    },
    {
      field: 'holderRequirement',
      headerName: 'Holder Requirement',
      renderCell: (params) => (
        <>
          {(params.row.payload.holderRequirement as PartyCredentialRequirement).requiredClaims.map(
            (c) => (
              <>
                <Chip key={c._1 + c._2} label={c._1} />
                <Chip key={c._1 + c._2} label={c._2} />
              </>
            ),
          )}
        </>
      ),
      flex: 250,
      filterable: false,
    },
    {
      field: 'registrarRequirement',
      headerName: 'Registrar Requirement',
      renderCell: (params) => (
        <>
          {(
            params.row.payload.registrarRequirement as PartyCredentialRequirement
          ).requiredClaims.map((c) => (
            <>
              <Chip key={c._1 + c._2} label={c._1} />
              <Chip key={c._1 + c._2} label={c._2} />
            </>
          ))}
        </>
      ),
      flex: 250,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [createProviderConfigurationIsOpen, setCreateProviderConfigurationIsOpen] = useState(false);
  const providerService = useProviderService();

  const { isLoading } = providerService;
  if (isLoading) return null;

  return (
    <GridToolbarContainer>
      {createProviderConfigurationIsOpen && (
        <CreateProviderConfigurationDialog
          open={createProviderConfigurationIsOpen}
          onClose={() => setCreateProviderConfigurationIsOpen(false)}
        />
      )}
      <SyncButton
        onClick={() => setCreateProviderConfigurationIsOpen(true)}
        disabled={!providerService.data}
      >
        Create provider configuration
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const ProviderConfigurations = () => {
  const configurations = useRegistryProviderConfigurations();

  const isLoading = configurations.isLoading || !configurations.data;
  if (isLoading) return <Loading />;

  return (
    <QueryTable
      title="Provider Configurations"
      variant="h3"
      columns={createHeaders()}
      rowQuery={configurations}
      rowKey={(a) => a.contractId}
      toolbar={Toolbar}
    />
  );
};
