import { useContext } from 'react';
import { LedgerApiContext } from '../../contexts/LedgerApiContext';
import LedgerApiClient from '../../utils/LedgerApiClient';

const useLedgerApiClient: () => LedgerApiClient = () => {
  const client = useContext(LedgerApiContext);
  if (!client) {
    throw new Error('Ledger API client is not initialized');
  }
  return client;
};

export default useLedgerApiClient;
