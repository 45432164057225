import Loading from '../../../components/Loading';
import useOperatorService from '../../../hooks/queries/trading/onboarding/useOperatorService';
import useProviderService from '../../../hooks/queries/trading/onboarding/useProviderService';
import { OperatorConfigurations } from './OperatorConfigurations';
import { ProviderConfigurations } from './ProviderConfigurations';

export const Configurations = () => {
  const operatorService = useOperatorService();
  const providerService = useProviderService();

  const isLoading = operatorService.isLoading || providerService.isLoading;
  if (isLoading) return <Loading />;

  const isOperator = !!operatorService.data;
  const isProvider = !!providerService.data;

  return (
    <>
      {isOperator && <OperatorConfigurations />}
      {(isOperator || isProvider) && <ProviderConfigurations />}
    </>
  );
};
