import { Route, Routes } from 'react-router-dom';
import ErrorWrapper from '../components/ErrorWrapper';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import useUserService from '../hooks/queries/credential/onboarding/useUserService';
import { Billing } from '../pages/credentials/Billing';
import { Credentials } from '../pages/credentials/Credentials';
import { Offers } from '../pages/credentials/Offers';
import { Onboarding } from '../pages/credentials/Onboarding';
import { SidebarEntry } from '../routes/types';
import useStyles from './styles';

const sidebarEntries: SidebarEntry[] = [
  {
    label: 'Onboarding',
    route: { path: 'onboarding', element: <Onboarding /> },
    subEntries: [],
  },
];

const onboardedEntries: SidebarEntry[] = [
  {
    label: 'Credentials',
    route: { path: 'credentials', element: <Credentials /> },
    subEntries: [],
  },
  {
    label: 'Offers',
    route: { path: 'offers', element: <Offers /> },
    subEntries: [],
  },
  {
    label: 'Billing',
    route: { path: 'billing', element: <Billing /> },
    subEntries: [],
  },
];

export const Credential: React.FC = () => {
  const { classes } = useStyles();
  const userService = useUserService();
  const entries =
    userService.isSuccess && !!userService.data
      ? sidebarEntries.concat(onboardedEntries)
      : sidebarEntries;
  const routes = entries.flatMap((e) =>
    [e.route].concat(e.subEntries ? e.subEntries.map((se) => se.route) : []),
  );

  return (
    <ErrorWrapper>
      <div className={classes.root}>
        <Header appName="Credentials" />
        <Sidebar entries={entries} />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Routes>
            {routes.map((r) => (
              <Route key={r.path} path={r.path} element={r.element} />
            ))}
          </Routes>
        </div>
      </div>
    </ErrorWrapper>
  );
};
