import { useCallback, useState } from 'react';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useCreateInstrumentConfiguration from '../../../hooks/mutations/registry/configuration/useCreateInstrumentConfiguration';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import { ERR_CRE_INST_CONF, SUC_CRE_INST_CONF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateInstrumentConfigurationDialog = ({ open, onClose }: DialogProps) => {
  const [registrar, setRegistrar] = useState<string>('');
  const [instrumentId, setInstrumentId] = useState<string>('');
  const [issuerRequirementProperty, setIssuerRequirementProperty] = useState<string>('');
  const [issuerRequirementValue, setIssuerRequirementValue] = useState<string>('');
  const [holderRequirementProperty, setHolderRequirementProperty] = useState<string>('');
  const [holderRequirementValue, setHolderRequirementValue] = useState<string>('');

  const { operator } = useParties();
  const registrarService = useRegistrarService();
  const mutate = useMutate();
  const createInstrumentConfiguration = useCreateInstrumentConfiguration();

  const resetState = useCallback(() => {
    setRegistrar('');
    setInstrumentId('');
    setIssuerRequirementProperty('');
    setIssuerRequirementValue('');
    setHolderRequirementProperty('');
    setHolderRequirementValue('');
  }, [
    setRegistrar,
    setInstrumentId,
    setIssuerRequirementProperty,
    setIssuerRequirementValue,
    setHolderRequirementProperty,
    setHolderRequirementValue,
  ]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!registrarService.data) return;
    const payload = {
      registrarServiceCid: registrarService.data.contractId,
      registrar,
      instrumentId,
      issuerRequirements: [
        {
          operator,
          issuer: registrar,
          requiredClaims: [{ _1: issuerRequirementProperty, _2: issuerRequirementValue }],
        },
      ],
      holderRequirements: [
        {
          operator,
          issuer: registrar,
          requiredClaims: [{ _1: holderRequirementProperty, _2: holderRequirementValue }],
        },
      ],
    };
    await mutate(createInstrumentConfiguration, payload, SUC_CRE_INST_CONF, ERR_CRE_INST_CONF);
    handleClose();
  }, [
    registrarService.data,
    createInstrumentConfiguration,
    operator,
    registrar,
    instrumentId,
    issuerRequirementProperty,
    issuerRequirementValue,
    holderRequirementProperty,
    holderRequirementValue,
    handleClose,
    mutate,
  ]);

  const { isLoading } = registrarService;
  if (isLoading) return null;

  const disableSubmit =
    !registrar ||
    !instrumentId ||
    !issuerRequirementProperty ||
    !issuerRequirementValue ||
    !holderRequirementProperty ||
    !holderRequirementValue;
  return (
    <FormDialog
      open={open}
      title="Request Instrument Configuration"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Request"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
      <TextInput label="Instrument Id" value={instrumentId} setValue={setInstrumentId} required />
      <TextInput
        label="Issuer Requirement Property"
        value={issuerRequirementProperty}
        setValue={setIssuerRequirementProperty}
        required
      />
      <TextInput
        label="Issuer Requirement Value"
        value={issuerRequirementValue}
        setValue={setIssuerRequirementValue}
        required
      />
      <TextInput
        label="Holder Requirement Property"
        value={holderRequirementProperty}
        setValue={setHolderRequirementProperty}
        required
      />
      <TextInput
        label="Holder Requirement Value"
        value={holderRequirementValue}
        setValue={setHolderRequirementValue}
        required
      />
    </FormDialog>
  );
};
