import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Set } from '@daml.js/stdlib-set/lib/DA/Set/Types';
import {
  Mint,
  MintRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Mint';
import { ContractId, emptyMap } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptMintRequest from '../../../hooks/mutations/registry/mint/useAcceptMintRequest';
import useCancelMintRequest from '../../../hooks/mutations/registry/mint/useCancelMintRequest';
import useRejectMintRequest from '../../../hooks/mutations/registry/mint/useRejectMintRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useAcceptedMints from '../../../hooks/queries/registry/mint/useAcceptedMints';
import useExecutedMints from '../../../hooks/queries/registry/mint/useExecutedMints';
import useMintOffers from '../../../hooks/queries/registry/mint/useMintOffers';
import useMintRequests from '../../../hooks/queries/registry/mint/useMintRequests';
import useRejectedMints from '../../../hooks/queries/registry/mint/useRejectedMints';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../../../hooks/queries/registry/onboarding/useOperatorService';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import useRegistrarServices from '../../../hooks/queries/registry/onboarding/useRegistrarServices';
import { createSet, fmt, prettyParty, shorten } from '../../../utils/common';
import { ERR_ACC_MINT, ERR_CAN_MINT, SUC_ACC_MINT, SUC_CAN_MINT } from '../../../utils/strings';
import { RequestMintDialog } from './RequestMintDialog';

type Row = {
  contractId: string;
  state: string;
  payload: Mint;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 100,
      filterable: false,
    },
    {
      field: 'registrar',
      headerName: 'Registrar',
      valueGetter: (params) => prettyParty(params.row.payload.registrar),
      flex: 100,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.holder),
      flex: 100,
      filterable: false,
    },
    {
      field: 'instrumentId',
      headerName: 'Instrument Id',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => fmt(params.row.payload.amount, 0),
      flex: 150,
      filterable: false,
    },
    {
      field: 'batch',
      headerName: 'Batch (size)',
      valueGetter: (params) =>
        `${shorten(params.row.payload.batch.id)} (${params.row.payload.batch.size})`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [requestMintIsOpen, setRequestMintIsOpen] = useState(false);
  const holderService = useHolderService();

  const { isLoading } = holderService;
  if (isLoading) return null;

  const isHolder = !!holderService.data;

  return (
    <GridToolbarContainer>
      {requestMintIsOpen && (
        <RequestMintDialog open={requestMintIsOpen} onClose={() => setRequestMintIsOpen(false)} />
      )}
      <SyncButton onClick={() => setRequestMintIsOpen(true)} disabled={!isHolder}>
        Request mint
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const Mints = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const { operator, party } = useParties();
  const registrarService = useRegistrarService();
  const registrarServices = useRegistrarServices();
  const holderService = useHolderService();
  const operatorService = useOperatorService();
  const mintRequests = useMintRequests();
  const mintOffers = useMintOffers();
  const acceptedMints = useAcceptedMints();
  const rejectedMints = useRejectedMints();
  const executedMints = useExecutedMints();
  const acceptMintRequest = useAcceptMintRequest();
  const rejectMintRequest = useRejectMintRequest();
  const cancelMintRequest = useCancelMintRequest();
  const mutate = useMutate();

  const acceptMint = useCallback(
    (r: Row) => {
      if (!registrarService.data) return null;

      // Get default registrar observers
      const registrarObservers = emptyMap<string, Set<string>>().set(
        'Default',
        createSet([operator, party]),
      );

      // Get registrar service
      const registrarServiceCid = registrarService.data.contractId;
      if (!registrarServiceCid) throw new Error('Registrar service not found');

      // Create payload for acceptMintRequest
      const payload = {
        registrarServiceCid,
        mintRequestCid: r.contractId as ContractId<MintRequest>,
        registrarObservers,
      };
      return mutate(acceptMintRequest, payload, SUC_ACC_MINT, ERR_ACC_MINT);
    },
    [registrarService, acceptMintRequest, operator, party, mutate],
  );

  const rejectMint = useCallback(
    (r: Row, reason: string) => {
      const payload = {
        registrarServiceCid: registrarService.data!.contractId,
        mintRequestCid: r.contractId as ContractId<MintRequest>,
        reason,
      };
      return mutate(rejectMintRequest, payload, SUC_ACC_MINT, ERR_ACC_MINT);
    },
    [registrarService, rejectMintRequest, mutate],
  );

  const cancelMint = useCallback(
    (r: Row) => {
      const payload = {
        holderServiceCid: holderService.data!.contractId,
        mintRequestCid: r.contractId as ContractId<MintRequest>,
      };
      return mutate(cancelMintRequest, payload, SUC_CAN_MINT, ERR_CAN_MINT);
    },
    [holderService, cancelMintRequest, mutate],
  );

  const isLoading =
    registrarService.isLoading ||
    registrarServices.isLoading ||
    holderService.isLoading ||
    operatorService.isLoading ||
    mintRequests.isLoading ||
    mintOffers.isLoading ||
    acceptedMints.isLoading ||
    rejectedMints.isLoading ||
    executedMints.isLoading ||
    !mintRequests.data ||
    !mintOffers.data ||
    !acceptedMints.data ||
    !rejectedMints.data ||
    !executedMints.data;
  if (isLoading) return <Loading />;

  const rows = mintRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      state: 'Requested',
      payload: r.payload.mint,
    }))
    .concat(
      mintOffers.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Offered',
        payload: r.payload.mint,
      })),
    )
    .concat(
      acceptedMints.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Accepted',
        payload: r.payload.mint,
      })),
    )
    .concat(
      rejectedMints.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Rejected',
        payload: r.payload.mint,
      })),
    )
    .concat(
      executedMints.data.map((r) => ({
        contractId: r.contractId as string,
        state: 'Executed',
        payload: r.payload.mint,
      })),
    );

  const isHolder = !!holderService.data;
  const isRegistrar = !!registrarService.data;
  const createMintActions = (r: Row) => {
    let actions: Action[] = [];
    if (isRegistrar && r.state === 'Requested' && r.payload.registrar === party)
      actions = actions.concat([
        { name: 'Accept', action: (row: Row) => Promise.resolve(acceptMint(row)) },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectMint(row, reason))),
        },
      ]);
    if (isHolder && r.state === 'Requested' && r.payload.holder === party)
      actions = actions.concat([{ name: 'Cancel', action: cancelMint }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      <DataTable
        title="Mints"
        variant="h3"
        columns={createHeaders(createMintActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
        toolbar={Toolbar}
      />
    </>
  );
};
