import { z } from 'zod';

export default class ConfigReader<
  A extends z.ZodRawShape,
  B extends z.ZodTypeAny,
  T extends z.ZodObject<A, 'strip', B>,
> {
  private schema;

  constructor(schema: T) {
    this.schema = schema.strict();
  }

  loadConfig(config: unknown): z.infer<T> {
    if (config !== undefined) {
      return this.schema.parse(config);
    }
    throw new Error('No configuration found.');
  }
}
