import { useState } from 'react';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import Loading from '../../../components/Loading';
import { QueryTable } from '../../../components/Table/QueryTable';
import useParties from '../../../hooks/other/useParties';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import useHoldings from '../../../hooks/queries/registry/useHoldings';
import TemplateContract from '../../../utils/TemplateContract';
import { prettyParty, values } from '../../../utils/common';
import { RequestBurnDialog } from '../burn/RequestBurnDialog';
import { OfferLockDialog } from '../lock/OfferLockDialog';
import { OfferTransferDialog } from '../transfer/OfferTransferDialog';
import { OfferUnlockDialog } from '../unlock/OfferUnlockDialog';

type Row = TemplateContract<Holding>;

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'registrar',
      headerName: 'Registrar',
      valueGetter: (params) => prettyParty(params.row.payload.registrar),
      flex: 150,
      filterable: false,
    },
    {
      field: 'holder',
      headerName: 'Holder',
      valueGetter: (params) => prettyParty(params.row.payload.owner),
      flex: 150,
      filterable: false,
    },
    {
      field: 'label',
      headerName: 'Label',
      valueGetter: (params) => params.row.payload.label,
      flex: 150,
      filterable: false,
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueGetter: (params) => params.row.payload.instrument.id,
      flex: 150,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => params.row.payload.amount,
      flex: 150,
      filterable: false,
    },
    {
      field: 'lockedBy',
      headerName: 'Locked By',
      valueGetter: (params) =>
        params.row.payload.lock
          ? values(params.row.payload.lock.lockers).map(prettyParty).join(', ')
          : '',
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [offerTransferIsOpen, setOfferTransferIsOpen] = useState(false);
  const [offerLockIsOpen, setOfferLockIsOpen] = useState(false);
  const [offerUnlockIsOpen, setOfferUnlockIsOpen] = useState(false);
  const holderService = useHolderService();

  const { isLoading } = holderService;
  if (isLoading) return null;

  const isHolder = !!holderService.data;

  return (
    <GridToolbarContainer>
      {offerTransferIsOpen && (
        <OfferTransferDialog
          open={offerTransferIsOpen}
          onClose={() => setOfferTransferIsOpen(false)}
        />
      )}
      {offerLockIsOpen && (
        <OfferLockDialog open={offerLockIsOpen} onClose={() => setOfferLockIsOpen(false)} />
      )}
      {offerUnlockIsOpen && (
        <OfferUnlockDialog open={offerUnlockIsOpen} onClose={() => setOfferUnlockIsOpen(false)} />
      )}
      <SyncButton onClick={() => setOfferTransferIsOpen(true)} disabled={!isHolder}>
        Offer transfer
      </SyncButton>
      <SyncButton onClick={() => setOfferLockIsOpen(true)} disabled={!isHolder}>
        Offer lock
      </SyncButton>
      <SyncButton onClick={() => setOfferUnlockIsOpen(true)} disabled={!isHolder}>
        Offer unlock
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const Holdings = () => {
  const [transferHolding, setTransferHolding] = useState<TemplateContract<Holding> | undefined>();
  const [lockHolding, setLockHolding] = useState<TemplateContract<Holding> | undefined>();
  const [unlockHolding, setUnlockHolding] = useState<TemplateContract<Holding> | undefined>();
  const [burnHolding, setBurnHolding] = useState<TemplateContract<Holding> | undefined>();
  const { party } = useParties();
  const holderService = useHolderService();
  const holdings = useHoldings();

  const isLoading = holderService.isLoading || holdings.isLoading;
  if (isLoading) return <Loading />;

  const createActions = (r: Row) => {
    let actions: Action[] = [];
    if (!!holderService.data && r.payload.owner === party) {
      if (!r.payload.lock)
        actions = actions.concat([
          { name: 'Transfer', action: () => Promise.resolve(setTransferHolding(r)) },
          { name: 'Lock', action: () => Promise.resolve(setLockHolding(r)) },
        ]);
      else
        actions = actions.concat([
          { name: 'Unlock', action: () => Promise.resolve(setUnlockHolding(r)) },
        ]);
      actions = actions.concat([
        { name: 'Burn', action: () => Promise.resolve(setBurnHolding(r)) },
      ]);
    }
    return actions;
  };

  return (
    <>
      {!!transferHolding && (
        <OfferTransferDialog
          open={!!transferHolding}
          onClose={() => setTransferHolding(undefined)}
          holding={transferHolding}
        />
      )}
      {!!lockHolding && (
        <OfferLockDialog
          open={!!lockHolding}
          onClose={() => setLockHolding(undefined)}
          holding={lockHolding}
        />
      )}
      {!!unlockHolding && (
        <OfferUnlockDialog
          open={!!unlockHolding}
          onClose={() => setUnlockHolding(undefined)}
          holding={unlockHolding}
        />
      )}
      {!!burnHolding && (
        <RequestBurnDialog
          open={!!burnHolding}
          onClose={() => setBurnHolding(undefined)}
          holding={burnHolding}
        />
      )}
      <QueryTable
        title="Holdings"
        variant="h3"
        columns={createHeaders(createActions)}
        rowQuery={holdings}
        rowKey={(a) => a.contractId}
        toolbar={Toolbar}
      />
    </>
  );
};
