import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import {
  MintRequest,
  RejectedMint,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Mint';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectMintRequest: () => UseMutationResult<
  ContractId<RejectedMint>,
  string,
  {
    registrarServiceCid: ContractId<RegistrarService>;
    mintRequestCid: ContractId<MintRequest>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectMintRequest', ledgerApi],
    mutationFn: async ({ registrarServiceCid, mintRequestCid, reason }) => {
      return ledgerApi.rejectMintRequest(registrarServiceCid, mintRequestCid, reason);
    },
  });
};

export default useRejectMintRequest;
