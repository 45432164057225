import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeOffer,
  TradeOffer_Reject_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectTradeOffer: () => UseMutationResult<
  TradeOffer_Reject_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeOfferCid: ContractId<TradeOffer>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTradeOffer', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeOfferCid, reason }) => {
      return ledgerApi.rejectTradeOffer(traderServiceCid, tradeOfferCid, reason);
    },
  });
};

export default useRejectTradeOffer;
