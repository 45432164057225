import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { LockRequest } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelLockRequest: () => UseMutationResult<
  void,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    lockRequestCid: ContractId<LockRequest>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelLockRequest', ledgerApi],
    mutationFn: async ({ holderServiceCid, lockRequestCid }) => {
      return ledgerApi.cancelLockRequest(holderServiceCid, lockRequestCid);
    },
  });
};

export default useCancelLockRequest;
