import { useCallback, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import {
  AcceptedTrade,
  Direction,
  Trade,
  TradeOffer,
  TradeRequest,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useCancelTradeOffer from '../../../hooks/mutations/trading/trade/useCancelTradeOffer';
import useCancelTradeRequest from '../../../hooks/mutations/trading/trade/useCancelTradeRequest';
import useInstructAcceptedTrade from '../../../hooks/mutations/trading/trade/useInstructAcceptedTrade';
import useRejectTradeOffer from '../../../hooks/mutations/trading/trade/useRejectTradeOffer';
import useRejectTradeRequest from '../../../hooks/mutations/trading/trade/useRejectTradeRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useOperatorConfiguration from '../../../hooks/queries/trading/configuration/useOperatorConfiguration';
import useOperatorService from '../../../hooks/queries/trading/onboarding/useOperatorService';
import useProviderServices from '../../../hooks/queries/trading/onboarding/useProviderServices';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import useAcceptedTrades from '../../../hooks/queries/trading/trade/useAcceptedTrades';
import useExecutedTrades from '../../../hooks/queries/trading/trade/useExecutedTrades';
import useInstructedTrades from '../../../hooks/queries/trading/trade/useInstructedTrades';
import useRejectedTradeOffers from '../../../hooks/queries/trading/trade/useRejectedTradeOffers';
import useRejectedTradeRequests from '../../../hooks/queries/trading/trade/useRejectedTradeRequests';
import useTradeOffers from '../../../hooks/queries/trading/trade/useTradeOffers';
import useTradeRequests from '../../../hooks/queries/trading/trade/useTradeRequests';
import { fmt, prettyParty } from '../../../utils/common';
import {
  ERR_CAN_TRADE_OFF,
  ERR_CAN_TRADE_REQ,
  ERR_INST_TRADE,
  ERR_REJ_TRADE_OFF,
  ERR_REJ_TRADE_REQ,
  SUC_CAN_TRADE_OFF,
  SUC_CAN_TRADE_REQ,
  SUC_INST_TRADE,
  SUC_REJ_TRADE_OFF,
  SUC_REJ_TRADE_REQ,
} from '../../../utils/strings';
import { AcceptTradeOfferDialog } from './AcceptTradeOfferDialog';
import { AcceptTradeRequestDialog } from './AcceptTradeRequestDialog';
import { OfferTradeDialog } from './OfferTradeDialog';
import { RequestTradeDialog } from './RequestTradeDialog';

type Row = {
  contractId: string;
  state: string;
  payload: Trade;
  direction: Direction;
  pricePerUnit: string;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params) => params.row.state,
      flex: 100,
      filterable: false,
    },
    {
      field: 'id',
      headerName: 'Id',
      valueGetter: (params) => params.row.payload.id,
      flex: 150,
      filterable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      valueGetter: (params) => params.row.payload.description,
      flex: 150,
      filterable: false,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      valueGetter: (params) => prettyParty(params.row.payload.buyer),
      flex: 100,
      filterable: false,
    },
    {
      field: 'seller',
      headerName: 'Seller',
      valueGetter: (params) => prettyParty(params.row.payload.seller),
      flex: 100,
      filterable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => fmt(params.row.payload.amount, 0),
      flex: 150,
      filterable: false,
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueGetter: (params) => params.row.payload.instrumentId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      valueGetter: (params) => params.row.payload.currencyId,
      flex: 150,
      filterable: false,
    },
    {
      field: 'pricePerUnit',
      headerName: 'Price Per Unit',
      valueGetter: (params) => fmt(params.row.pricePerUnit, 0),
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [offerTradeIsOpen, setOfferTradeIsOpen] = useState(false);
  const [requestTradeIsOpen, setRequestTradeIsOpen] = useState(false);
  const traderService = useTraderService();

  const { isLoading } = traderService;
  if (isLoading) return null;

  const isTrader = !!traderService.data;

  return (
    <GridToolbarContainer>
      {isTrader && requestTradeIsOpen && (
        <RequestTradeDialog
          open={requestTradeIsOpen}
          onClose={() => setRequestTradeIsOpen(false)}
        />
      )}
      {isTrader && offerTradeIsOpen && (
        <OfferTradeDialog open={offerTradeIsOpen} onClose={() => setOfferTradeIsOpen(false)} />
      )}
      {isTrader && (
        <SyncButton onClick={() => setRequestTradeIsOpen(true)}>Request trade</SyncButton>
      )}
      {isTrader && <SyncButton onClick={() => setOfferTradeIsOpen(true)}>Offer trade</SyncButton>}
    </GridToolbarContainer>
  );
};

export const Trades = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const [tradeRequest, setTradeRequest] = useState<Row | undefined>();
  const [tradeOffer, setTradeOffer] = useState<Row | undefined>();

  const { party } = useParties();
  const operatorService = useOperatorService();
  const providerServices = useProviderServices();
  const traderService = useTraderService();
  const configuration = useOperatorConfiguration();
  const tradeRequests = useTradeRequests();
  const tradeOffers = useTradeOffers();
  const acceptedTrades = useAcceptedTrades();
  const rejectedTradeOffers = useRejectedTradeOffers();
  const rejectedTradeRequests = useRejectedTradeRequests();
  const instructedTrades = useInstructedTrades();
  const executedTrades = useExecutedTrades();
  const rejectTradeRequest = useRejectTradeRequest();
  const cancelTradeRequest = useCancelTradeRequest();
  const rejectTradeOffer = useRejectTradeOffer();
  const cancelTradeOffer = useCancelTradeOffer();
  const instructAcceptedTrade = useInstructAcceptedTrade();
  const mutate = useMutate();

  const rejectRequest = useCallback(
    (r: Row, reason: string) => {
      const payload = {
        traderServiceCid: traderService.data!.contractId,
        tradeRequestCid: r.contractId as ContractId<TradeRequest>,
        reason,
      };
      return mutate(rejectTradeRequest, payload, SUC_REJ_TRADE_REQ, ERR_REJ_TRADE_REQ);
    },
    [traderService.data, rejectTradeRequest, mutate],
  );

  const rejectOffer = useCallback(
    (r: Row, reason: string) => {
      const payload = {
        traderServiceCid: traderService.data!.contractId,
        tradeOfferCid: r.contractId as ContractId<TradeOffer>,
        reason,
      };
      return mutate(rejectTradeOffer, payload, SUC_REJ_TRADE_OFF, ERR_REJ_TRADE_OFF);
    },
    [traderService.data, rejectTradeOffer, mutate],
  );

  const cancelRequest = useCallback(
    (r: Row) => {
      const payload = {
        traderServiceCid: traderService.data!.contractId,
        tradeRequestCid: r.contractId as ContractId<TradeRequest>,
      };
      return mutate(cancelTradeRequest, payload, SUC_CAN_TRADE_REQ, ERR_CAN_TRADE_REQ);
    },
    [traderService.data, cancelTradeRequest, mutate],
  );

  const cancelOffer = useCallback(
    (r: Row) => {
      const payload = {
        traderServiceCid: traderService.data!.contractId,
        tradeOfferCid: r.contractId as ContractId<TradeOffer>,
      };
      return mutate(cancelTradeOffer, payload, SUC_CAN_TRADE_OFF, ERR_CAN_TRADE_OFF);
    },
    [traderService.data, cancelTradeOffer, mutate],
  );

  const instructTrade = useCallback(
    (r: Row) => {
      if (!configuration.data) throw new Error('Operator configuration not found');
      const providerServiceForTrade = providerServices.data!.find(
        (ps) => ps.payload.operator === party && ps.payload.provider === r.payload.provider,
      );
      // TODO: These throws are not reflected as error snackbar
      if (!providerServiceForTrade) throw new Error('Registrar service not found');
      const payload = {
        operatorServiceCid: operatorService.data!.contractId,
        providerServiceCid: providerServiceForTrade.contractId,
        acceptedTradeCid: r.contractId as ContractId<AcceptedTrade>,
        instrumentMapping: configuration.data.payload.instrumentMapping,
        registrarMapping: configuration.data.payload.registrarMapping,
      };
      return mutate(instructAcceptedTrade, payload, SUC_INST_TRADE, ERR_INST_TRADE);
    },
    [
      operatorService.data,
      providerServices.data,
      configuration.data,
      party,
      instructAcceptedTrade,
      mutate,
    ],
  );

  const isLoading =
    operatorService.isLoading ||
    traderService.isLoading ||
    tradeRequests.isLoading ||
    tradeOffers.isLoading ||
    acceptedTrades.isLoading ||
    rejectedTradeOffers.isLoading ||
    rejectedTradeRequests.isLoading ||
    instructedTrades.isLoading ||
    executedTrades.isLoading ||
    !tradeRequests.data ||
    !tradeOffers.data ||
    !acceptedTrades.data ||
    !rejectedTradeOffers.data ||
    !rejectedTradeRequests.data ||
    !instructedTrades.data ||
    !executedTrades.data;
  if (isLoading) return <Loading />;

  const rows = tradeRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      direction: r.payload.direction,
      payload: r.payload.trade,
      pricePerUnit: '',
      state: 'Requested',
    }))
    .concat(
      tradeOffers.data.map((r) => ({
        contractId: r.contractId as string,
        direction: r.payload.direction,
        payload: r.payload.trade,
        pricePerUnit: r.payload.pricePerUnit,
        state: 'Offered',
      })),
    )
    .concat(
      acceptedTrades.data.map((r) => ({
        contractId: r.contractId as string,
        direction: Direction.Buy, // TODO: Arbitrary, as we have all info explicitly.
        payload: r.payload.trade,
        pricePerUnit: r.payload.pricePerUnit,
        state: 'Accepted',
      })),
    )
    .concat(
      rejectedTradeRequests.data.map((r) => ({
        contractId: r.contractId as string,
        direction: r.payload.direction,
        payload: r.payload.trade,
        pricePerUnit: '',
        state: 'RequestRejected',
      })),
    )
    .concat(
      rejectedTradeOffers.data.map((r) => ({
        contractId: r.contractId as string,
        direction: r.payload.direction,
        payload: r.payload.trade,
        pricePerUnit: r.payload.pricePerUnit,
        state: 'OfferRejected',
      })),
    )
    .concat(
      instructedTrades.data.map((r) => ({
        contractId: r.contractId as string,
        direction: Direction.Buy, // TODO: Arbitrary, as we have all info explicitly.
        payload: r.payload.trade,
        pricePerUnit: r.payload.pricePerUnit,
        state: 'Instructed',
      })),
    )
    .concat(
      executedTrades.data.map((r) => ({
        contractId: r.contractId as string,
        direction: Direction.Buy, // TODO: Arbitrary, as we have all info explicitly.
        payload: r.payload.trade,
        pricePerUnit: r.payload.pricePerUnit,
        state: 'Executed',
      })),
    );

  const isOperator = !!operatorService.data;
  const isTrader = !!traderService.data;
  const createTradeActions = (r: Row) => {
    let actions: Action[] = [];
    if (isTrader && r.state === 'Requested') {
      if (
        (r.direction === 'Buy' && r.payload.seller === party) ||
        (r.direction === 'Sell' && r.payload.buyer === party)
      )
        actions = actions.concat([
          { name: 'Accept', action: (row) => Promise.resolve(setTradeRequest(row)) },
          {
            name: 'Reject',
            action: (row: Row) =>
              Promise.resolve(setOnReject((reason: string) => rejectRequest(row, reason))),
          },
        ]);
      if (
        (r.direction === 'Buy' && r.payload.buyer === party) ||
        (r.direction === 'Sell' && r.payload.seller === party)
      )
        actions = actions.concat([{ name: 'Cancel', action: cancelRequest }]);
    }
    if (isTrader && r.state === 'Offered') {
      if (
        (r.direction === 'Buy' && r.payload.seller === party) ||
        (r.direction === 'Sell' && r.payload.buyer === party)
      )
        actions = actions.concat([
          { name: 'Accept', action: (row) => Promise.resolve(setTradeOffer(row)) },
          {
            name: 'Reject',
            action: (row: Row) =>
              Promise.resolve(setOnReject((reason: string) => rejectOffer(row, reason))),
          },
        ]);
      if (
        (r.direction === 'Buy' && r.payload.buyer === party) ||
        (r.direction === 'Sell' && r.payload.seller === party)
      )
        actions = actions.concat([{ name: 'Cancel', action: cancelOffer }]);
    }

    if (isOperator && r.state === 'Accepted' && r.payload.operator === party)
      actions = actions.concat([{ name: 'Instruct', action: instructTrade }]);
    // if (isOperator && r.state === 'Instructed' && r.payload.operator === party)
    //   actions = actions.concat([{ name: 'Confirm Execution', action: confirmExecution }]);
    return actions;
  };

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      {!!tradeRequest && (
        <AcceptTradeRequestDialog
          open={!!tradeRequest}
          onClose={() => setTradeRequest(undefined)}
          tradeRequest={tradeRequest}
        />
      )}
      {!!tradeOffer && (
        <AcceptTradeOfferDialog
          open={!!tradeOffer}
          onClose={() => setTradeOffer(undefined)}
          tradeOffer={tradeOffer}
        />
      )}
      <DataTable
        title="Trades"
        variant="h3"
        columns={createHeaders(createTradeActions)}
        rows={rows}
        rowKey={(a) => a.contractId}
        toolbar={Toolbar}
      />
    </>
  );
};
