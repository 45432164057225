import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useRegistrarService: () => UseQueryResult<
  TemplateContract<RegistrarService> | null,
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryRegistryRegistrarService', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryRegistryRegistrarService(operator, party);
    },
  });
};

export default useRegistrarService;
