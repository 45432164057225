import { QueryClient } from '@tanstack/react-query';

const queryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: 2500,
      },
      mutations: {
        retry: 1,
      },
    },
  });

export default queryClient;
