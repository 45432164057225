import { useCallback, useState } from 'react';
import { Credential } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import { SelectInput } from '../../../components/Form/SelectInput';
import useRequestProviderService from '../../../hooks/mutations/registry/onboarding/useRequestProviderService';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useCredentials from '../../../hooks/queries/credential/useCredentials';
import { prettyParty } from '../../../utils/common';
import { ERR_REQ_PROVIDER_SVC, SUC_REQ_PROVIDER_SVC } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const RequestProviderServiceDialog = ({ open, onClose }: DialogProps) => {
  const [credentialCid, setCredentialCid] = useState<string>('');
  const { party } = useParties();
  const credentials = useCredentials();
  const requestProviderService = useRequestProviderService();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setCredentialCid('');
  }, [setCredentialCid]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      credentialCid: credentialCid as ContractId<Credential>,
    };
    const res = await mutate(
      requestProviderService,
      payload,
      SUC_REQ_PROVIDER_SVC,
      ERR_REQ_PROVIDER_SVC,
    );
    handleClose();
    return res;
  }, [requestProviderService, credentialCid, handleClose, mutate]);

  const isLoading = credentials.isLoading || !credentials.data;
  if (isLoading) return null;

  const disableSubmit = !credentialCid;
  const values = credentials.data
    .filter((c) => c.payload.holder === party)
    .map((c) => ({
      value: c.contractId,
      display: `${c.payload.id} (issued by: ${prettyParty(c.payload.issuer)})`,
    }));

  return (
    <FormDialog
      open={open}
      title="Request Provider Service"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Request"
      isSubmitDisabled={disableSubmit}
    >
      <SelectInput
        label="Credential"
        value={credentialCid}
        setValue={setCredentialCid}
        values={values}
      />
    </FormDialog>
  );
};
