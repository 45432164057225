import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  ProviderServiceRequest,
  ProviderServiceRequest_Cancel_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelProviderServiceRequest: () => UseMutationResult<
  ProviderServiceRequest_Cancel_Result,
  string,
  { providerServiceRequestCid: ContractId<ProviderServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelTradingProviderServiceRequest', ledgerApi],
    mutationFn: async ({ providerServiceRequestCid }) => {
      return ledgerApi.cancelTradingProviderServiceRequest(providerServiceRequestCid);
    },
  });
};

export default useCancelProviderServiceRequest;
