import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  AcceptedTransfer,
  TransferOffer,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptTransferOffer: () => UseMutationResult<
  ContractId<AcceptedTransfer>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    transferOfferCid: ContractId<TransferOffer>;
    receiverLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptTransferOffer', ledgerApi],
    mutationFn: async ({ holderServiceCid, transferOfferCid, receiverLabel }) => {
      return ledgerApi.acceptTransferOffer(holderServiceCid, transferOfferCid, receiverLabel);
    },
  });
};

export default useAcceptTransferOffer;
