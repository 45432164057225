import { useCallback, useState } from 'react';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useCreateProviderConfiguration from '../../../hooks/mutations/trading/configuration/useCreateProviderConfiguration';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useProviderService from '../../../hooks/queries/trading/onboarding/useProviderService';
import { ERR_CRE_PROV_CONF, SUC_CRE_PROV_CONF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateProviderConfigurationDialog = ({ open, onClose }: DialogProps) => {
  const [traderRequirementProperty, setTraderRequirementProperty] = useState<string>('');
  const [traderRequirementValue, setTraderRequirementValue] = useState<string>('');

  const { operator, party } = useParties();
  const providerService = useProviderService();
  const mutate = useMutate();
  const createProviderConfiguration = useCreateProviderConfiguration();

  const resetState = useCallback(() => {
    setTraderRequirementProperty('');
    setTraderRequirementValue('');
  }, [setTraderRequirementProperty, setTraderRequirementValue]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!providerService.data) return;
    const payload = {
      providerServiceCid: providerService.data.contractId,
      traderRequirement: {
        operator,
        issuer: party,
        requiredClaims: [{ _1: traderRequirementProperty, _2: traderRequirementValue }],
      },
    };
    await mutate(createProviderConfiguration, payload, SUC_CRE_PROV_CONF, ERR_CRE_PROV_CONF);
    handleClose();
  }, [
    providerService.data,
    createProviderConfiguration,
    operator,
    party,
    traderRequirementProperty,
    traderRequirementValue,
    handleClose,
    mutate,
  ]);

  const { isLoading } = providerService;
  if (isLoading) return null;

  const disableSubmit = !traderRequirementProperty || !traderRequirementValue;
  return (
    <FormDialog
      open={open}
      title="Create Provider Configuration"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Create"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput
        label="Registrar Requirement Property"
        value={traderRequirementProperty}
        setValue={setTraderRequirementProperty}
        required
      />
      <TextInput
        label="Registrar Requirement Value"
        value={traderRequirementValue}
        setValue={setTraderRequirementValue}
        required
      />
    </FormDialog>
  );
};
