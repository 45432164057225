import { Route, Routes } from 'react-router-dom';
import ErrorWrapper from '../components/ErrorWrapper';
import Header from '../components/Header/Header';
import Loading from '../components/Loading';
import Sidebar from '../components/Sidebar/Sidebar';
import useHolderService from '../hooks/queries/registry/onboarding/useHolderService';
import useOperatorService from '../hooks/queries/registry/onboarding/useOperatorService';
import useProviderService from '../hooks/queries/registry/onboarding/useProviderService';
import useRegistrarService from '../hooks/queries/registry/onboarding/useRegistrarService';
import { Burns } from '../pages/registry/burn/Burns';
import { Configurations } from '../pages/registry/configuration/Configurations';
import { Holdings } from '../pages/registry/holdings/Holdings';
import { Locks } from '../pages/registry/lock/Locks';
import { Mints } from '../pages/registry/mint/Mints';
import { Onboarding } from '../pages/registry/onboarding/Onboarding';
import { Transfers } from '../pages/registry/transfer/Transfers';
import { Unlocks } from '../pages/registry/unlock/Unlocks';
import { SidebarEntry } from '../routes/types';
import useStyles from './styles';

const sidebarEntries: SidebarEntry[] = [
  {
    label: 'Onboarding',
    route: { path: 'onboarding', element: <Onboarding /> },
    subEntries: [],
  },
];

const configurationEntry: SidebarEntry = {
  label: 'Configurations',
  route: { path: 'configurations', element: <Configurations /> },
  subEntries: [],
};

const holderEntries: SidebarEntry[] = [
  {
    label: 'Holdings',
    route: { path: 'holdings', element: <Holdings /> },
    subEntries: [],
  },
  {
    label: 'Transfers',
    route: { path: 'transfers', element: <Transfers /> },
    subEntries: [],
  },
  {
    label: 'Locks',
    route: { path: 'locks', element: <Locks /> },
    subEntries: [],
  },
  {
    label: 'Unlocks',
    route: { path: 'unlocks', element: <Unlocks /> },
    subEntries: [],
  },
  {
    label: 'Mints',
    route: { path: 'mints', element: <Mints /> },
    subEntries: [],
  },
  {
    label: 'Burns',
    route: { path: 'burns', element: <Burns /> },
    subEntries: [],
  },
];

export const Registry: React.FC = () => {
  const { classes } = useStyles();
  const holderService = useHolderService();
  const registrarService = useRegistrarService();
  const providerService = useProviderService();
  const operatorService = useOperatorService();

  const isLoading =
    holderService.isLoading ||
    registrarService.isLoading ||
    providerService.isLoading ||
    operatorService.isLoading;
  if (isLoading) return <Loading />;

  const isHolder = !!holderService.data;
  const isRegistrar = !!registrarService.data;
  const isProvider = !!providerService.data;
  const isOperator = !!operatorService.data;

  let entries = sidebarEntries;
  if (isOperator || isProvider || isRegistrar) {
    entries = entries.concat([configurationEntry]).concat(holderEntries);
  } else if (isHolder) entries = entries.concat(holderEntries);

  const routes = entries.flatMap((e) =>
    [e.route].concat(e.subEntries ? e.subEntries.map((se) => se.route) : []),
  );

  return (
    <ErrorWrapper>
      <div className={classes.root}>
        <Header appName="Registry" />
        <Sidebar entries={entries} />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Routes>
            {routes.map((r) => (
              <Route key={r.path} path={r.path} element={r.element} />
            ))}
          </Routes>
        </div>
      </div>
    </ErrorWrapper>
  );
};
