import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeRequest,
  TradeRequest_Accept_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptTradeRequest: () => UseMutationResult<
  TradeRequest_Accept_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeRequestCid: ContractId<TradeRequest>;
    pricePerUnit: string;
    acceptorLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptTradeRequest', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeRequestCid, pricePerUnit, acceptorLabel }) => {
      return ledgerApi.acceptTradeRequest(
        traderServiceCid,
        tradeRequestCid,
        pricePerUnit,
        acceptorLabel,
      );
    },
  });
};

export default useAcceptTradeRequest;
