import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useScanProxyApiClient } from '../../other/useScanProxyApiClient';

const useDsoParty: () => UseQueryResult<string, string> = () => {
  const scanProxyApi = useScanProxyApiClient();

  return useQuery({
    queryKey: ['getDsoPartyId', scanProxyApi],
    queryFn: async () => {
      const response = await scanProxyApi.getDsoPartyId();
      return response.dso_party_id;
    },
    refetchInterval: false,
  });
};

export default useDsoParty;
