import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { LockOffer } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelLockOffer: () => UseMutationResult<
  void,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    lockOfferCid: ContractId<LockOffer>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelLockOffer', ledgerApi],
    mutationFn: async ({ holderServiceCid, lockOfferCid }) => {
      return ledgerApi.cancelLockOffer(holderServiceCid, lockOfferCid);
    },
  });
};

export default useCancelLockOffer;
