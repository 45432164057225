import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { Set } from '@daml.js/stdlib-set/lib/DA/Set/Types';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import {
  AcceptedMint,
  MintRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Mint';
import { ContractId, Map } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptMintRequest: () => UseMutationResult<
  ContractId<AcceptedMint>,
  string,
  {
    registrarServiceCid: ContractId<RegistrarService>;
    mintRequestCid: ContractId<MintRequest>;
    registrarObservers: Map<string, Set<string>>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptMintRequest', ledgerApi],
    mutationFn: async ({ registrarServiceCid, mintRequestCid, registrarObservers }) => {
      return ledgerApi.acceptMintRequest(registrarServiceCid, mintRequestCid, registrarObservers);
    },
  });
};

export default useAcceptMintRequest;
