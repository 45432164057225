import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AcceptedLock } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptedLocks: () => UseQueryResult<TemplateContract<AcceptedLock>[], string> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useQuery({
    queryKey: ['queryAcceptedLocks', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryAcceptedLocks(operator);
    },
  });
};

export default useAcceptedLocks;
