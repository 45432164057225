import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ProviderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTerminateProviderService: () => UseMutationResult<
  void,
  string,
  { providerServiceCid: ContractId<ProviderService> }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();

  return useMutation({
    mutationKey: ['terminateRegistryProviderService', ledgerApi],
    mutationFn: async ({ providerServiceCid }) => {
      return ledgerApi.terminateRegistryProviderService(providerServiceCid, party);
    },
  });
};

export default useTerminateProviderService;
