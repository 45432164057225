import { GridFilterItem } from '@mui/x-data-grid';
import { Set } from '@daml.js/stdlib-set/lib/DA/Set/Types';
import { InstrumentKey } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { emptyMap } from '@daml/types';

export const fmt = (x: number | string, decimals?: number) => {
  return (typeof x === 'string' ? parseFloat(x) : x)
    .toFixed(decimals || 0)
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'");
};

export const emptySet = <T>(): Set<T> => ({ map: emptyMap<T, object>() });

export const createSet = <T>(vals: T[]): Set<T> => {
  let map = emptyMap<T, object>();
  for (let i = 0; i < vals.length; i += 1) map = map.set(vals[i], {});
  return { map };
};

export function values<T>(set: Set<T>): T[] {
  return set.map.entriesArray().map((v) => v[0]);
}

export const shorten = (s: string, len?: number): string => {
  return s.substring(0, len || 8);
};

export const prettyParty = (party: string): string => {
  const [p] = party.split('::');
  return p;
};

export const filterToSqlText = (f: GridFilterItem): string => {
  if (typeof f.value === 'string') {
    const s = f.value.replaceAll('%', '');
    if (f.operator === 'startsWith') {
      return `${s}%`;
    }
    if (f.operator === 'equals') {
      return s;
    }
  }
  throw new Error('Invalid filter');
};

export const filterDateToSqlText = (f: GridFilterItem): string => {
  const date = new Date(f.value as string);
  if (f.operator === 'before') {
    return `< '${date.toISOString()}'`;
  }
  if (f.operator === 'after') {
    return `> '${date.toISOString()}'`;
  }
  throw new Error('Invalid filter');
};

export const instrumentKeyEquals = (k1: InstrumentKey, k2: InstrumentKey): boolean => {
  return (
    k1.agent === k2.agent && k1.issuer === k2.issuer && k1.id === k2.id && k1.version === k2.version
  );
};

export const parseDateString = (dateAsString: string): Date => {
  const parsedFromString = Date.parse(dateAsString);
  return new Date(parsedFromString);
};
