import React from 'react';
import Ledger, { LedgerOptions } from '@daml/ledger';
import useUserState from '../hooks/other/useUserState';
import LedgerApiClient from '../utils/LedgerApiClient';

const ensureTrailingSlash = (url: string): string => {
  return url.endsWith('/') ? url : `${url}/`;
};

export const LedgerApiContext = React.createContext<LedgerApiClient | undefined>(undefined);

export interface LedgerApiProps {
  jsonApiUrl: string;
}

export const LedgerApiClientProvider = ({
  jsonApiUrl,
  children,
}: React.PropsWithChildren<LedgerApiProps>) => {
  const { loggedInUser } = useUserState();
  const baseUrl = ensureTrailingSlash(jsonApiUrl);

  let ledgerApiClient: LedgerApiClient | undefined;

  if (loggedInUser) {
    const ledgerOptions: LedgerOptions = {
      httpBaseUrl: baseUrl,
      token: loggedInUser.userAccessToken,
    };
    ledgerApiClient = new LedgerApiClient(new Ledger(ledgerOptions), loggedInUser.userId);
  }

  return <LedgerApiContext.Provider value={ledgerApiClient}>{children}</LedgerApiContext.Provider>;
};
