import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { PartyCredentialRequirement } from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { InstrumentKey } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { OperatorConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Operator';
import { OperatorService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Operator';
import { ContractId, Map } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCreateOperatorConfiguration: () => UseMutationResult<
  ContractId<OperatorConfiguration>,
  string,
  {
    operatorServiceCid: ContractId<OperatorService>;
    providerRequirement: PartyCredentialRequirement;
    instrumentMapping: Map<string, InstrumentKey>;
    registrarMapping: Map<string, string>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['createTradingOperatorConfiguration', ledgerApi],
    mutationFn: async ({
      operatorServiceCid,
      providerRequirement,
      instrumentMapping,
      registrarMapping,
    }) => {
      return ledgerApi.createTradingOperatorConfiguration(
        operatorServiceCid,
        providerRequirement,
        instrumentMapping,
        registrarMapping,
      );
    },
  });
};

export default useCreateOperatorConfiguration;
