import React, { useMemo } from 'react';
import {
  ScanProxyApi,
  ServerConfiguration,
  createConfiguration,
  RequestContext,
  ResponseContext,
  Middleware,
} from 'validator-scan-proxy-api-ts-client';
import useUserState from '../hooks/other/useUserState';
import { BaseApiMiddleware } from '../utils/BaseApiMiddleware';
import { OpenAPILoggingMiddleware } from '../utils/OpenApiLoggingMiddleware';

export const ScanProxyApiContext = React.createContext<ScanProxyApi | undefined>(undefined);

export interface ScanProxyApiProps {
  validatorUrl: string;
}

class ApiMiddleware
  extends BaseApiMiddleware<RequestContext, ResponseContext>
  implements Middleware {}

export const ScanProxyApiProvider = ({
  validatorUrl,
  children,
}: React.PropsWithChildren<ScanProxyApiProps>) => {
  const { loggedInUser } = useUserState();
  const selectedToken = loggedInUser?.userAccessToken ?? '';
  const client: ScanProxyApi | undefined = useMemo(() => {
    const configuration = createConfiguration({
      baseServer: new ServerConfiguration(validatorUrl, {}),
      promiseMiddleware: [
        new ApiMiddleware(selectedToken),
        new OpenAPILoggingMiddleware('scan-proxy'),
      ],
    });

    return new ScanProxyApi(configuration);
  }, [validatorUrl, selectedToken]);

  return <ScanProxyApiContext.Provider value={client}>{children}</ScanProxyApiContext.Provider>;
};
