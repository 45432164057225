import { useCallback, useState } from 'react';
import FormDialog from '../../components/Dialog/FormDialog';
import TextInput from '../../components/Form/TextInput';
import useOfferFreeCredential from '../../hooks/mutations/credential/offer/useOfferFreeCredential';
import useMutate from '../../hooks/mutations/useMutate';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import { ERR_OFF_CRED_FREE, SUC_OFF_CRED_FREE } from '../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const OfferFreeCredentialDialog = ({ open, onClose }: DialogProps) => {
  const [holder, setHolder] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [property, setProperty] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const userService = useUserService();
  const offerFreeCredential = useOfferFreeCredential();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setHolder('');
    setId('');
    setDescription('');
    setSubject('');
    setProperty('');
    setValue('');
  }, [setHolder, setId, setDescription, setSubject, setProperty, setValue]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      userServiceCid: userService.data!.contractId,
      holder,
      id,
      description,
      claims: [{ subject, property, value }],
    };
    await mutate(offerFreeCredential, payload, SUC_OFF_CRED_FREE, ERR_OFF_CRED_FREE);
    handleClose();
  }, [
    userService,
    holder,
    id,
    description,
    subject,
    property,
    value,
    offerFreeCredential,
    mutate,
    handleClose,
  ]);

  const disableSubmit = !holder || !id || !description || !subject || !property || !value;
  return (
    <FormDialog
      open={open}
      title="Offer Free Credential"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Offer"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Holder" value={holder} setValue={setHolder} required />
      <TextInput label="Id" value={id} setValue={setId} required />
      <TextInput label="Description" value={description} setValue={setDescription} required />
      <TextInput label="Subject" value={subject} setValue={setSubject} required />
      <TextInput label="Property" value={property} setValue={setProperty} required />
      <TextInput label="Value" value={value} setValue={setValue} required />
    </FormDialog>
  );
};
