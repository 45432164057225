import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { OperatorConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Operator';
import {
  ProviderServiceRequest,
  ProviderServiceRequest_Accept_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useAcceptProviderServiceRequest: () => UseMutationResult<
  ProviderServiceRequest_Accept_Result,
  string,
  {
    providerServiceRequestCid: ContractId<ProviderServiceRequest>;
    operatorConfigurationCid: ContractId<OperatorConfiguration>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();

  return useMutation({
    mutationKey: ['acceptTradingProviderServiceRequest', ledgerApi, operator],
    mutationFn: async ({ providerServiceRequestCid, operatorConfigurationCid }) => {
      return ledgerApi.acceptTradingProviderServiceRequest(
        providerServiceRequestCid,
        operatorConfigurationCid,
      );
    },
  });
};

export default useAcceptProviderServiceRequest;
