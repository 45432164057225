import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProviderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useProviderService: () => UseQueryResult<
  TemplateContract<ProviderService> | null,
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator, party } = useParties();
  return useQuery({
    queryKey: ['queryTradingProviderUserService', ledgerApi, operator, party],
    queryFn: async () => {
      return ledgerApi.queryTradingProviderService(operator, party);
    },
  });
};

export default useProviderService;
