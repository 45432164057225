import { SnackbarKey, useSnackbar } from 'notistack';
import { PropsWithChildren, useCallback, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useStyles from '../routes/styles';

const ErrorWrapper = ({ children }: PropsWithChildren) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [errorStack, setErrorStack] = useState('');
  const [errorContext, setErrorContext] = useState<Error>();
  const [displayError, setDisplayError] = useState(false);
  const { classes } = useStyles();

  const action = useCallback(
    (snackbarId: SnackbarKey) => (
      <>
        <Button
          type="button"
          style={{ textTransform: 'none', color: '#0000CD' }}
          onClick={() => {
            setDisplayError(true);
            closeSnackbar(snackbarId);
          }}
        >
          Details
        </Button>
        <Button
          type="button"
          style={{ textTransform: 'none' }}
          onClick={() => closeSnackbar(snackbarId)}
        >
          Dismiss
        </Button>
      </>
    ),
    [closeSnackbar],
  );

  const Fallback: React.FC<FallbackProps> = useCallback(
    ({ error, resetErrorBoundary }) => {
      const errorT = error as Error;
      setErrorContext(errorT);
      enqueueSnackbar(errorT.message, {
        variant: 'error',
        onEnter: resetErrorBoundary,
        preventDuplicate: true,
        autoHideDuration: 10000,
        action,
      });
      return <div />;
    },
    [action, enqueueSnackbar],
  );

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={(error, info) => setErrorStack(info.componentStack || error.stack || '')}
    >
      <Backdrop open={displayError}>
        <Dialog open={displayError} onClose={() => setDisplayError(false)} fullWidth maxWidth="md">
          <DialogTitle className={classes.heading}>{errorContext?.message}</DialogTitle>
          <DialogContent dividers>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Cause</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{JSON.stringify(errorContext?.cause)}</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Stacktrace</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{errorStack}</Typography>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button id="form-dialog-cancel" onClick={() => setDisplayError(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorWrapper;
