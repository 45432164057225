import { useCallback, useState } from 'react';
import { CredentialOffer } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import FormDialog from '../../components/Dialog/FormDialog';
import TextInput from '../../components/Form/TextInput';
import useRejectCredentialOffer from '../../hooks/mutations/credential/offer/useRejectCredentialOffer';
import useMutate from '../../hooks/mutations/useMutate';
import useUserService from '../../hooks/queries/credential/onboarding/useUserService';
import TemplateContract from '../../utils/TemplateContract';
import { ERR_REJ_CRED_OFF, SUC_REJ_CRED_OFF } from '../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  row: TemplateContract<CredentialOffer> | undefined;
};

export const RejectOfferPaidCredentialDialog = ({ open, onClose, row }: DialogProps) => {
  const [reason, setReason] = useState<string>('');
  const userService = useUserService();
  const rejectCredentialOffer = useRejectCredentialOffer();

  const mutate = useMutate();

  const resetState = useCallback(() => {
    setReason('');
  }, [setReason]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (row !== undefined) {
      const payload = {
        userServiceCid: userService.data!.contractId,
        credentialOfferCid: row.contractId,
        reason,
      };
      await mutate(rejectCredentialOffer, payload, SUC_REJ_CRED_OFF, ERR_REJ_CRED_OFF);
    }
    handleClose();
  }, [userService, row, reason, rejectCredentialOffer, mutate, handleClose]);

  const disableSubmit = !reason;
  return (
    <FormDialog
      open={open}
      title="Reject Credential Offer"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Reject"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Reason" value={reason} setValue={setReason} required />
    </FormDialog>
  );
};
