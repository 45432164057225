import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { RegistrarService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import {
  AcceptedBurn,
  BurnRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Burn';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptBurnRequest: () => UseMutationResult<
  ContractId<AcceptedBurn>,
  string,
  {
    registrarServiceCid: ContractId<RegistrarService>;
    burnRequestCid: ContractId<BurnRequest>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptBurnRequest', ledgerApi],
    mutationFn: async ({ registrarServiceCid, burnRequestCid }) => {
      return ledgerApi.acceptBurnRequest(registrarServiceCid, burnRequestCid);
    },
  });
};

export default useAcceptBurnRequest;
