import { UseQueryResult, useQuery } from '@tanstack/react-query';
import useLedgerApiClient from '../other/useLedgerApiClient';

const usePrimaryParty = (): UseQueryResult<string, string> => {
  const ledgerApi = useLedgerApiClient();

  return useQuery({
    queryKey: ['fetchPrimaryParty', ledgerApi],
    refetchInterval: false, // primary party ID is static
    queryFn: async () => {
      return ledgerApi.getPrimaryParty();
    },
  });
};

export default usePrimaryParty;
