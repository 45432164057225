import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeOffer,
  TradeOffer_Accept_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptTradeOffer: () => UseMutationResult<
  TradeOffer_Accept_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeOfferCid: ContractId<TradeOffer>;
    acceptorLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptTradeOffer', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeOfferCid, acceptorLabel }) => {
      return ledgerApi.acceptTradeOffer(traderServiceCid, tradeOfferCid, acceptorLabel);
    },
  });
};

export default useAcceptTradeOffer;
