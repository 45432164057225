import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeRequest,
  TradeRequest_Reject_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectTradeRequest: () => UseMutationResult<
  TradeRequest_Reject_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeRequestCid: ContractId<TradeRequest>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTradeRequest', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeRequestCid, reason }) => {
      return ledgerApi.rejectTradeRequest(traderServiceCid, tradeRequestCid, reason);
    },
  });
};

export default useRejectTradeRequest;
