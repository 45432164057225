import { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { InstrumentKey } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { Map, emptyMap } from '@daml/types';
import { SyncButton } from '../../../components/Button/SyncButton';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useCreateOperatorConfiguration from '../../../hooks/mutations/trading/configuration/useCreateOperatorConfiguration';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useOperatorService from '../../../hooks/queries/trading/onboarding/useOperatorService';
import { ERR_CRE_OP_CONF, SUC_CRE_OP_CONF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateOperatorConfigurationDialog = ({ open, onClose }: DialogProps) => {
  const [providerRequirementProperty, setProviderRequirementProperty] = useState<string>('');
  const [providerRequirementValue, setProviderRequirementValue] = useState<string>('');
  const [instrumentId, setInstrumentId] = useState<string>('');
  const [instrumentVersion, setInstrumentVersion] = useState<string>('');
  const [instrumentRegistrar, setInstrumentRegistrar] = useState<string>('');
  const [instrumentIssuer, setInstrumentIssuer] = useState<string>('');
  const [instrumentMapping, setInstrumentMapping] =
    useState<Map<string, InstrumentKey>>(emptyMap());
  const [registrarMapping, setRegistrarMapping] = useState<Map<string, string>>(emptyMap());

  const { operator, party } = useParties();
  const operatorService = useOperatorService();
  const mutate = useMutate();
  const createOperatorConfiguration = useCreateOperatorConfiguration();

  const resetState = useCallback(() => {
    setProviderRequirementProperty('');
    setProviderRequirementValue('');
    setInstrumentId('');
    setInstrumentVersion('');
    setInstrumentRegistrar('');
    setInstrumentIssuer('');
    setInstrumentMapping(emptyMap());
    setRegistrarMapping(emptyMap());
  }, [
    setProviderRequirementProperty,
    setProviderRequirementValue,
    setInstrumentId,
    setInstrumentVersion,
    setInstrumentRegistrar,
    setInstrumentIssuer,
    setInstrumentMapping,
    setRegistrarMapping,
  ]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!operatorService.data) return;
    const payload = {
      operatorServiceCid: operatorService.data.contractId,
      providerRequirement: {
        operator,
        issuer: party,
        requiredClaims: [{ _1: providerRequirementProperty, _2: providerRequirementValue }],
      },
      instrumentMapping,
      registrarMapping,
    };
    await mutate(createOperatorConfiguration, payload, SUC_CRE_OP_CONF, ERR_CRE_OP_CONF);
    handleClose();
  }, [
    operatorService,
    createOperatorConfiguration,
    operator,
    party,
    providerRequirementProperty,
    providerRequirementValue,
    instrumentMapping,
    registrarMapping,
    handleClose,
    mutate,
  ]);

  const addInstrument = useCallback(() => {
    setInstrumentMapping(
      instrumentMapping.set(instrumentId, {
        agent: operator,
        issuer: instrumentIssuer,
        id: instrumentId,
        version: instrumentVersion,
      }),
    );
    setRegistrarMapping(registrarMapping.set(instrumentId, instrumentRegistrar));
    setInstrumentId('');
    setInstrumentVersion('');
    setInstrumentRegistrar('');
    setInstrumentIssuer('');
  }, [
    operator,
    instrumentId,
    instrumentVersion,
    instrumentRegistrar,
    instrumentIssuer,
    instrumentMapping,
    registrarMapping,
    setInstrumentMapping,
    setRegistrarMapping,
  ]);

  const { isLoading } = operatorService;
  if (isLoading) return null;

  const disableSubmit = !providerRequirementProperty || !providerRequirementValue;
  return (
    <FormDialog
      open={open}
      title="Create Operator Configuration"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Create"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput
        label="Provider Requirement Property"
        value={providerRequirementProperty}
        setValue={setProviderRequirementProperty}
        required
      />
      <TextInput
        label="Provider Requirement Value"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
      <TextInput
        label="Instrument Id"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
      <Typography variant="h6">Instruments</Typography>
      {instrumentMapping.entriesArray().map((e) => (
        <Typography key={e[0]}>{e[0]}</Typography>
      ))}
      <TextInput
        label="Instrument Version"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
      <TextInput
        label="Instrument Agent"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
      <TextInput
        label="Instrument Registrar"
        value={providerRequirementValue}
        setValue={setProviderRequirementValue}
        required
      />
      <SyncButton onClick={addInstrument}>Add instrument</SyncButton>
    </FormDialog>
  );
};
