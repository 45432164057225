import { useSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Tooltip } from '@mui/material';
import useParties from '../hooks/other/useParties';

// TODO: This only works for logged in party. Should work for any party display.
const CopyPartyToClipboard = ({
  children,
  buttonClassName,
  iconClassName,
  tooltipText,
}: PropsWithChildren<{
  buttonClassName: string;
  iconClassName: string;
  tooltipText: string;
}>) => {
  const { party } = useParties();
  const { enqueueSnackbar } = useSnackbar();

  const copyPartyToClipboard = () => {
    navigator.clipboard
      .writeText(party)
      .then(() => enqueueSnackbar('Party copied to clipboard', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Failed to copy to clipboard', { variant: 'error' }));
  };

  return (
    <>
      <Tooltip title={tooltipText} arrow>
        <Button
          className={buttonClassName}
          size="large"
          variant="text"
          startIcon={<ContentCopyIcon className={iconClassName} />}
          onClick={copyPartyToClipboard}
        >
          {children}
        </Button>
      </Tooltip>
      <div style={{ display: 'none' }} aria-hidden="true" aria-label="user-primary-party-id">
        {party}
      </div>
    </>
  );
};

export default CopyPartyToClipboard;
