import { useCallback, useState } from 'react';
import {
  Lock,
  LockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptLockRequest from '../../../hooks/mutations/registry/lock/useAcceptLockRequest';
import useMutate from '../../../hooks/mutations/useMutate';
import useHolderService from '../../../hooks/queries/registry/onboarding/useHolderService';
import { emptySet } from '../../../utils/common';
import { ERR_ACC_LOCK_REQ, SUC_ACC_LOCK_REQ } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  request: { contractId: string; state: string; payload: Lock };
};

export const AcceptLockRequestDialog = ({ open, onClose, request }: DialogProps) => {
  const [registrar, setRegistrar] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const holderService = useHolderService();
  const acceptLockRequest = useAcceptLockRequest();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setRegistrar('');
    setLabel('');
  }, [setRegistrar, setLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [onClose, resetState]);

  const handleSubmit = useCallback(async () => {
    if (!holderService.data || !registrar) return;
    const payload = {
      holderServiceCid: holderService.data.contractId,
      lockRequestCid: request.contractId as ContractId<LockRequest>,
      holdingLabel: label,
      observers: emptySet<string>(),
    };
    await mutate(acceptLockRequest, payload, SUC_ACC_LOCK_REQ, ERR_ACC_LOCK_REQ);
    handleClose();
  }, [holderService, registrar, label, request.contractId, handleClose, mutate, acceptLockRequest]);

  const { isLoading } = holderService;
  if (isLoading) return null;

  const disableSubmit = !registrar;
  return (
    <FormDialog
      open={open}
      title="Accept Lock Request"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Registrar" value={registrar} setValue={setRegistrar} required />
      <TextInput label="Label" value={label} setValue={setLabel} />
    </FormDialog>
  );
};
