import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OperatorConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Operator';
import TemplateContract from '../../../../utils/TemplateContract';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useOperatorConfigurations: () => UseQueryResult<
  TemplateContract<OperatorConfiguration>[],
  string
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { operator } = useParties();
  return useQuery({
    queryKey: ['queryTradingOperatorConfigurations', ledgerApi, operator],
    queryFn: async () => {
      return ledgerApi.queryTradingOperatorConfigurations(operator);
    },
  });
};

export default useOperatorConfigurations;
