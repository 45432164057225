import { Amulet as Amulet012 } from '@daml.js/splice-amulet-0.1.2/lib/Splice/Amulet';
import { Amulet, LockedAmulet } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Amulet';
import {
  AmuletRules,
  AmuletRules_DevNet_TapResult,
  AppTransferContext,
  TransferContext,
  TransferOutput,
} from '@daml.js/splice-amulet-0.1.3/lib/Splice/AmuletRules';
import { OpenMiningRound } from '@daml.js/splice-amulet-0.1.3/lib/Splice/Round';
import { Amulet as Amulet014 } from '@daml.js/splice-amulet-0.1.4/lib/Splice/Amulet';
import { Set } from '@daml.js/stdlib-set/lib/DA/Set/Types';
import {
  CredentialBilling,
  CredentialBilling_Bill_Result,
  CredentialBilling_Cancel_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Billing';
import {
  CredentialOffer,
  CredentialOffer_AcceptFree_Result,
  CredentialOffer_AcceptPaid_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import {
  UserService,
  UserService_OfferFreeCredential_Result,
  UserService_OfferPaidCredential_Result,
  UserServiceRequest,
  UserServiceRequest_Accept_Result,
} from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { BillingParams } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Types';
import {
  Claim,
  Credential,
  PartyCredentialRequirement,
} from '@daml.js/utility-credential-v0/lib/Utility/Credential/V0/Credential';
import { OperatorConfiguration as RegistryOperatorConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Operator';
import { ProviderConfiguration as RegistryProviderConfiguration } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Configuration/Provider';
import {
  HolderService,
  HolderServiceRequest,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  ProviderService as RegistryProviderService,
  ProviderServiceRequest as RegistryProviderServiceRequest,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Provider';
import {
  RegistrarService,
  RegistrarServiceRequest,
} from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Registrar';
import { OperatorService as RegistryOperatorService } from '@daml.js/utility-registry-operator-v0/lib/Utility/Registry/Operator/V0/Service/Operator';
import { InstrumentConfiguration } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Configuration/Instrument';
import { Holding } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Core/Holding';
import {
  AcceptedBurn,
  BurnOffer,
  BurnRequest,
  ExecutedBurn,
  FailedBurn,
  RejectedBurn,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Burn';
import {
  AcceptedLock,
  ExecutedLock,
  FailedLock,
  LockOffer,
  LockRequest,
  RejectedLock,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import {
  AcceptedMint,
  ExecutedMint,
  FailedMint,
  MintOffer,
  MintRequest,
  RejectedMint,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Mint';
import {
  AcceptedTransfer,
  ExecutedTransfer,
  FailedTransfer,
  RejectedTransfer,
  TransferOffer,
  TransferRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import {
  AcceptedUnlock,
  ExecutedUnlock,
  FailedUnlock,
  RejectedUnlock,
  UnlockOffer,
  UnlockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Unlock';
import { InstrumentKey, Batch } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { OperatorConfiguration as TradingOperatorConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Operator';
import { ProviderConfiguration as TradingProviderConfiguration } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Configuration/Provider';
import {
  AcceptedTrade,
  AcceptedTrade_Instruct_Result,
  Direction,
  ExecutedTrade,
  InstructedTrade,
  RejectedTradeOffer,
  RejectedTradeRequest,
  Trade,
  TradeOffer,
  TradeOffer_Accept_Result,
  TradeOffer_Cancel_Result,
  TradeOffer_Reject_Result,
  TradeRequest,
  TradeRequest_Accept_Result,
  TradeRequest_Cancel_Result,
  TradeRequest_Reject_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { OperatorService as TradingOperatorService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Operator';
import {
  ProviderServiceRequest_Accept_Result,
  ProviderServiceRequest_Cancel_Result,
  ProviderServiceRequest_Reject_Result,
  ProviderService_Terminate_Result,
  ProviderService as TradingProviderService,
  ProviderServiceRequest as TradingProviderServiceRequest,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import {
  TraderService,
  TraderServiceRequest,
  TraderServiceRequest_Accept_Result,
  TraderServiceRequest_Cancel_Result,
  TraderServiceRequest_Reject_Result,
  TraderService_OfferTrade_Result,
  TraderService_RequestTrade_Result,
  TraderService_Terminate_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import Ledger, { CreateEvent } from '@daml/ledger';
import { ContractId, DisclosedContract, Map, Template } from '@daml/types';
import TemplateContract from './TemplateContract';

// Uses the JSON API (via @daml/ledger) to connect to the ledger.
export default class LedgerApiClient {
  private ledger: Ledger;

  private userId: string;

  constructor(ledger: Ledger, userId: string) {
    this.ledger = ledger;
    this.userId = userId;
  }

  async getPrimaryParty(): Promise<string> {
    const user = await this.ledger.getUser(this.userId);
    if (!user.primaryParty) {
      throw new Error(`User ${this.userId} has no primary party`);
    }
    return user.primaryParty;
  }

  async queryCredentialUserService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<UserService> | null> {
    const svcs = await this.ledger.query(UserService);
    return svcs.find((r) => r.payload.operator === operator && r.payload.user === party) || null;
  }

  async queryCredentialUserServices(operator: string): Promise<TemplateContract<UserService>[]> {
    const svcs = await this.ledger.query(UserService);
    return svcs.filter((r) => r.payload.operator === operator);
  }

  async queryCredentialUserServiceRequests(
    operator: string,
  ): Promise<TemplateContract<UserServiceRequest>[]> {
    const reqs = await this.ledger.query(UserServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryCredentials(): Promise<TemplateContract<Credential>[]> {
    return this.ledger.query(Credential);
  }

  async queryCredentialOffers(operator: string): Promise<TemplateContract<CredentialOffer>[]> {
    const svcs = await this.ledger.query(CredentialOffer);
    return svcs.filter((r) => r.payload.operator === operator);
  }

  async queryCredentialBillings(operator: string): Promise<TemplateContract<CredentialBilling>[]> {
    const svcs = await this.ledger.query(CredentialBilling);
    return svcs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryOperatorConfiguration(
    operator: string,
  ): Promise<TemplateContract<RegistryOperatorConfiguration> | null> {
    const configs = await this.ledger.query(RegistryOperatorConfiguration);
    return configs.find((r) => r.payload.operator === operator) || null;
  }

  async queryRegistryOperatorConfigurations(
    operator: string,
  ): Promise<TemplateContract<RegistryOperatorConfiguration>[] | null> {
    const configs = await this.ledger.query(RegistryOperatorConfiguration);
    return configs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryProviderConfiguration(
    operator: string,
    provider: string,
  ): Promise<TemplateContract<RegistryProviderConfiguration> | null> {
    const configs = await this.ledger.query(RegistryProviderConfiguration);
    return (
      configs.find((r) => r.payload.operator === operator && r.payload.provider === provider) ||
      null
    );
  }

  async queryRegistryProviderConfigurations(
    operator: string,
  ): Promise<TemplateContract<RegistryProviderConfiguration>[] | null> {
    const configs = await this.ledger.query(RegistryProviderConfiguration);
    return configs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryInstrumentConfigurations(
    operator: string,
  ): Promise<TemplateContract<InstrumentConfiguration>[]> {
    const configs = await this.ledger.query(InstrumentConfiguration);
    return configs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryOperatorService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<RegistryOperatorService> | null> {
    const svcs = await this.ledger.query(RegistryOperatorService);
    return (
      svcs.find((r) => r.payload.operator === operator && r.payload.operator === party) || null
    );
  }

  async queryRegistryProviderServiceRequests(
    operator: string,
  ): Promise<TemplateContract<RegistryProviderServiceRequest>[]> {
    const reqs = await this.ledger.query(RegistryProviderServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryProviderServices(
    operator: string,
  ): Promise<TemplateContract<RegistryProviderService>[]> {
    const reqs = await this.ledger.query(RegistryProviderService);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryProviderService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<RegistryProviderService> | null> {
    const svcs = await this.ledger.query(RegistryProviderService);
    return (
      svcs.find((r) => r.payload.operator === operator && r.payload.provider === party) || null
    );
  }

  async queryRegistryRegistrarServiceRequests(
    operator: string,
  ): Promise<TemplateContract<RegistrarServiceRequest>[]> {
    const reqs = await this.ledger.query(RegistrarServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryRegistrarServices(
    operator: string,
  ): Promise<TemplateContract<RegistrarService>[]> {
    const reqs = await this.ledger.query(RegistrarService);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryRegistrarService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<RegistrarService> | null> {
    const svcs = await this.ledger.query(RegistrarService);
    return (
      svcs.find((r) => r.payload.operator === operator && r.payload.registrar === party) || null
    );
  }

  async queryRegistryHolderServiceRequests(
    operator: string,
  ): Promise<TemplateContract<HolderServiceRequest>[]> {
    const reqs = await this.ledger.query(HolderServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryHolderServices(operator: string): Promise<TemplateContract<HolderService>[]> {
    const reqs = await this.ledger.query(HolderService);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryRegistryHolderService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<HolderService> | null> {
    const svcs = await this.ledger.query(HolderService);
    return svcs.find((r) => r.payload.operator === operator && r.payload.holder === party) || null;
  }

  async queryHoldings(): Promise<TemplateContract<Holding>[]> {
    const res = await this.ledger.query(Holding);
    return res;
  }

  async queryInstrumentConfigurations(
    operator: string,
  ): Promise<TemplateContract<InstrumentConfiguration>[]> {
    const res = await this.ledger.query(InstrumentConfiguration);
    return res.filter((r) => r.payload.operator === operator);
  }

  async queryMintRequests(operator: string): Promise<TemplateContract<MintRequest>[]> {
    const res = await this.ledger.query(MintRequest);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryMintOffers(operator: string): Promise<TemplateContract<MintOffer>[]> {
    const res = await this.ledger.query(MintOffer);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryAcceptedMints(operator: string): Promise<TemplateContract<AcceptedMint>[]> {
    const res = await this.ledger.query(AcceptedMint);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryRejectedMints(operator: string): Promise<TemplateContract<RejectedMint>[]> {
    const res = await this.ledger.query(RejectedMint);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryExecutedMints(operator: string): Promise<TemplateContract<ExecutedMint>[]> {
    const res = await this.ledger.query(ExecutedMint);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryFailedMints(operator: string): Promise<TemplateContract<FailedMint>[]> {
    const res = await this.ledger.query(FailedMint);
    return res.filter((r) => r.payload.mint.operator === operator);
  }

  async queryBurnRequests(operator: string): Promise<TemplateContract<BurnRequest>[]> {
    const res = await this.ledger.query(BurnRequest);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryBurnOffers(operator: string): Promise<TemplateContract<BurnOffer>[]> {
    const res = await this.ledger.query(BurnOffer);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryAcceptedBurns(operator: string): Promise<TemplateContract<AcceptedBurn>[]> {
    const res = await this.ledger.query(AcceptedBurn);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryRejectedBurns(operator: string): Promise<TemplateContract<RejectedBurn>[]> {
    const res = await this.ledger.query(RejectedBurn);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryExecutedBurns(operator: string): Promise<TemplateContract<ExecutedBurn>[]> {
    const res = await this.ledger.query(ExecutedBurn);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryFailedBurns(operator: string): Promise<TemplateContract<FailedBurn>[]> {
    const res = await this.ledger.query(FailedBurn);
    return res.filter((r) => r.payload.burn.operator === operator);
  }

  async queryTransferRequests(operator: string): Promise<TemplateContract<TransferRequest>[]> {
    const res = await this.ledger.query(TransferRequest);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryTransferOffers(operator: string): Promise<TemplateContract<TransferOffer>[]> {
    const res = await this.ledger.query(TransferOffer);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryAcceptedTransfers(operator: string): Promise<TemplateContract<AcceptedTransfer>[]> {
    const res = await this.ledger.query(AcceptedTransfer);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryRejectedTransfers(operator: string): Promise<TemplateContract<RejectedTransfer>[]> {
    const res = await this.ledger.query(RejectedTransfer);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryExecutedTransfers(operator: string): Promise<TemplateContract<ExecutedTransfer>[]> {
    const res = await this.ledger.query(ExecutedTransfer);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryFailedTransfers(operator: string): Promise<TemplateContract<FailedTransfer>[]> {
    const res = await this.ledger.query(FailedTransfer);
    return res.filter((r) => r.payload.transfer.operator === operator);
  }

  async queryLockRequests(operator: string): Promise<TemplateContract<LockRequest>[]> {
    const res = await this.ledger.query(LockRequest);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryLockOffers(operator: string): Promise<TemplateContract<LockOffer>[]> {
    const res = await this.ledger.query(LockOffer);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryAcceptedLocks(operator: string): Promise<TemplateContract<AcceptedLock>[]> {
    const res = await this.ledger.query(AcceptedLock);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryRejectedLocks(operator: string): Promise<TemplateContract<RejectedLock>[]> {
    const res = await this.ledger.query(RejectedLock);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryExecutedLocks(operator: string): Promise<TemplateContract<ExecutedLock>[]> {
    const res = await this.ledger.query(ExecutedLock);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryFailedLocks(operator: string): Promise<TemplateContract<FailedLock>[]> {
    const res = await this.ledger.query(FailedLock);
    return res.filter((r) => r.payload.lock.operator === operator);
  }

  async queryUnlockRequests(operator: string): Promise<TemplateContract<UnlockRequest>[]> {
    const res = await this.ledger.query(UnlockRequest);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryUnlockOffers(operator: string): Promise<TemplateContract<UnlockOffer>[]> {
    const res = await this.ledger.query(UnlockOffer);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryAcceptedUnlocks(operator: string): Promise<TemplateContract<AcceptedUnlock>[]> {
    const res = await this.ledger.query(AcceptedUnlock);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryRejectedUnlocks(operator: string): Promise<TemplateContract<RejectedUnlock>[]> {
    const res = await this.ledger.query(RejectedUnlock);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryExecutedUnlocks(operator: string): Promise<TemplateContract<ExecutedUnlock>[]> {
    const res = await this.ledger.query(ExecutedUnlock);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryFailedUnlocks(operator: string): Promise<TemplateContract<FailedUnlock>[]> {
    const res = await this.ledger.query(FailedUnlock);
    return res.filter((r) => r.payload.unlock.operator === operator);
  }

  async queryTradingOperatorConfiguration(
    operator: string,
  ): Promise<TemplateContract<TradingOperatorConfiguration> | null> {
    const configs = await this.ledger.query(TradingOperatorConfiguration);
    return configs.find((r) => r.payload.operator === operator) || null;
  }

  async queryTradingOperatorConfigurations(
    operator: string,
  ): Promise<TemplateContract<TradingOperatorConfiguration>[] | null> {
    const configs = await this.ledger.query(TradingOperatorConfiguration);
    return configs.filter((r) => r.payload.operator === operator);
  }

  async queryTradingProviderConfiguration(
    operator: string,
    provider: string,
  ): Promise<TemplateContract<TradingProviderConfiguration> | null> {
    const configs = await this.ledger.query(TradingProviderConfiguration);
    return (
      configs.find((r) => r.payload.operator === operator && r.payload.provider === provider) ||
      null
    );
  }

  async queryTradingProviderConfigurations(
    operator: string,
  ): Promise<TemplateContract<TradingProviderConfiguration>[] | null> {
    const configs = await this.ledger.query(TradingProviderConfiguration);
    return configs.filter((r) => r.payload.operator === operator);
  }

  async queryTradingOperatorService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<TradingOperatorService> | null> {
    const svcs = await this.ledger.query(TradingOperatorService);
    return (
      svcs.find((r) => r.payload.operator === operator && r.payload.operator === party) || null
    );
  }

  async queryTradingProviderServiceRequests(
    operator: string,
  ): Promise<TemplateContract<TradingProviderServiceRequest>[]> {
    const reqs = await this.ledger.query(TradingProviderServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryTradingProviderService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<TradingProviderService> | null> {
    const svcs = await this.ledger.query(TradingProviderService);
    return (
      svcs.find((r) => r.payload.operator === operator && r.payload.provider === party) || null
    );
  }

  async queryTradingProviderServices(
    operator: string,
  ): Promise<TemplateContract<TradingProviderService>[]> {
    const reqs = await this.ledger.query(TradingProviderService);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryTradingTraderServiceRequests(
    operator: string,
  ): Promise<TemplateContract<TraderServiceRequest>[]> {
    const reqs = await this.ledger.query(TraderServiceRequest);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryTradingTraderService(
    operator: string,
    party: string,
  ): Promise<TemplateContract<TraderService> | null> {
    const svcs = await this.ledger.query(TraderService);
    return svcs.find((r) => r.payload.operator === operator && r.payload.trader === party) || null;
  }

  async queryTradingTraderServices(operator: string): Promise<TemplateContract<TraderService>[]> {
    const reqs = await this.ledger.query(TraderService);
    return reqs.filter((r) => r.payload.operator === operator);
  }

  async queryTradeRequests(operator: string): Promise<TemplateContract<TradeRequest>[]> {
    const res = await this.ledger.query(TradeRequest);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryTradeOffers(operator: string): Promise<TemplateContract<TradeOffer>[]> {
    const res = await this.ledger.query(TradeOffer);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryRejectedTradeRequests(
    operator: string,
  ): Promise<TemplateContract<RejectedTradeRequest>[]> {
    const res = await this.ledger.query(RejectedTradeRequest);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryRejectedTradeOffers(
    operator: string,
  ): Promise<TemplateContract<RejectedTradeOffer>[]> {
    const res = await this.ledger.query(RejectedTradeOffer);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryAcceptedTrades(operator: string): Promise<TemplateContract<AcceptedTrade>[]> {
    const res = await this.ledger.query(AcceptedTrade);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryInstructedTrades(operator: string): Promise<TemplateContract<InstructedTrade>[]> {
    const res = await this.ledger.query(InstructedTrade);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryExecutedTrades(operator: string): Promise<TemplateContract<ExecutedTrade>[]> {
    const res = await this.ledger.query(ExecutedTrade);
    return res.filter((r) => r.payload.trade.operator === operator);
  }

  async queryCoins(party: string): Promise<TemplateContract<Amulet>[]> {
    const promises: Promise<CreateEvent<Amulet012 | Amulet | Amulet014, undefined, string>[]>[] =
      [];
    [Amulet012, Amulet, Amulet014].forEach((amulet) => {
      promises.push(this.ledger.query(amulet));
    });

    const createdEvents: CreateEvent<Amulet, undefined, string>[] = [];
    const settledPromises = await Promise.allSettled(promises);
    settledPromises.forEach((p, i) => {
      if (p.status === 'rejected') {
        const packageName = `splice-amulet-0.1.${i + 2}`;
        console.log(
          `Error looking up ${packageName} package Amulets - package likely unvetted or yet to be vetted`,
        );
      } else {
        createdEvents.push(...p.value);
      }
    });
    return createdEvents.filter((e) => e.payload.owner === party);
  }

  async queryLockedCoins(party: string): Promise<TemplateContract<LockedAmulet>[]> {
    const res = await this.ledger.query(LockedAmulet);
    return res.filter((r) => r.payload.amulet.owner === party);
  }

  async createCredentialUserServiceRequest(
    operator: string,
    user: string,
  ): Promise<CreateEvent<UserServiceRequest>> {
    return this.ledger.create(UserServiceRequest, { operator, user });
  }

  async acceptCredentialUserServiceRequest(
    userServiceRequestCid: ContractId<UserServiceRequest>,
    dso: string,
  ): Promise<UserServiceRequest_Accept_Result> {
    const [res] = await this.ledger.exercise(
      UserServiceRequest.UserServiceRequest_Accept,
      userServiceRequestCid,
      { dso },
    );
    return res;
  }

  async rejectCredentialUserServiceRequest(
    userServiceRequestCid: ContractId<UserServiceRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      UserServiceRequest.UserServiceRequest_Reject,
      userServiceRequestCid,
      {},
    );
  }

  async cancelCredentialUserServiceRequest(
    userServiceRequestCid: ContractId<UserServiceRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      UserServiceRequest.UserServiceRequest_Cancel,
      userServiceRequestCid,
      {},
    );
  }

  async terminateCredentialUserService(
    userServiceCid: ContractId<UserService>,
    actor: string,
  ): Promise<void> {
    await this.ledger.exercise(UserService.UserService_Terminate, userServiceCid, {
      actor,
    });
  }

  async offerFreeCredential(
    userServiceCid: ContractId<UserService>,
    holder: string,
    id: string,
    description: string,
    claims: Claim[],
  ): Promise<UserService_OfferFreeCredential_Result> {
    const [result] = await this.ledger.exercise(
      UserService.UserService_OfferFreeCredential,
      userServiceCid,
      { holder, id, description, claims },
    );
    return result;
  }

  async offerPaidCredential(
    userServiceCid: ContractId<UserService>,
    holder: string,
    id: string,
    description: string,
    claims: Claim[],
    billingParams: BillingParams,
    depositInitialAmountUsd: string,
  ): Promise<UserService_OfferPaidCredential_Result> {
    const [result] = await this.ledger.exercise(
      UserService.UserService_OfferPaidCredential,
      userServiceCid,
      { holder, id, description, claims, billingParams, depositInitialAmountUsd },
    );
    return result;
  }

  async acceptFreeCredentialOffer(
    userServiceCid: ContractId<UserService>,
    credentialOfferCid: ContractId<CredentialOffer>,
  ): Promise<CredentialOffer_AcceptFree_Result> {
    const [result] = await this.ledger.exercise(
      UserService.UserService_AcceptFreeCredentialOffer,
      userServiceCid,
      { credentialOfferCid },
    );
    return result;
  }

  async acceptPaidCredentialOffer(
    userServiceCid: ContractId<UserService>,
    credentialOfferCid: ContractId<CredentialOffer>,
    depositAmulets: ContractId<Amulet>[],
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<CredentialOffer_AcceptPaid_Result> {
    const [result] = await this.ledger.exercise(
      UserService.UserService_AcceptPaidCredentialOffer,
      userServiceCid,
      { credentialOfferCid, depositAmulets, appTransferContext },
      { disclosedContracts },
    );
    return result;
  }

  async cancelCredentialOffer(
    userServiceCid: ContractId<UserService>,
    credentialOfferCid: ContractId<CredentialOffer>,
  ): Promise<void> {
    await this.ledger.exercise(UserService.UserService_CancelCredentialOffer, userServiceCid, {
      credentialOfferCid,
    });
  }

  async rejectCredentialOffer(
    userServiceCid: ContractId<UserService>,
    credentialOfferCid: ContractId<CredentialOffer>,
    reason: string,
  ): Promise<void> {
    await this.ledger.exercise(UserService.UserService_RejectCredentialOffer, userServiceCid, {
      credentialOfferCid,
      reason,
    });
  }

  async distributeAmulet(
    userServiceCid: ContractId<UserService>,
    credentialBillingCid: ContractId<CredentialBilling>,
    amountUsd: string,
    coinCids: ContractId<Amulet>[],
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<void> {
    await this.ledger.exercise(
      UserService.UserService_Distribute,
      userServiceCid,
      {
        credentialBillingCid,
        amountUsd,
        coinCids,
        appTransferContext,
      },
      { disclosedContracts },
    );
  }

  async topupLockedCredentialDeposit(
    userServiceCid: ContractId<UserService>,
    credentialBillingCid: ContractId<CredentialBilling>,
    amountUsd: string,
    coinCids: ContractId<Amulet>[],
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<void> {
    await this.ledger.exercise(
      UserService.UserService_TopUp,
      userServiceCid,
      {
        credentialBillingCid,
        amountUsd,
        coinCids,
        appTransferContext,
      },
      { disclosedContracts },
    );
  }

  async billCredential(
    credentialBillingCid: ContractId<CredentialBilling>,
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<CredentialBilling_Bill_Result> {
    const [res] = await this.ledger.exercise(
      CredentialBilling.CredentialBilling_Bill,
      credentialBillingCid,
      { appTransferContext },
      { disclosedContracts },
    );
    return res;
  }

  async revokeCredential(
    userServiceCid: ContractId<UserService>,
    credentialCid: ContractId<Credential>,
  ): Promise<void> {
    await this.ledger.exercise(UserService.UserService_RevokeCredential, userServiceCid, {
      credentialCid,
    });
  }

  async cancelCredentialBilling(
    credentialUserSerivceCid: ContractId<UserService>,
    credentialBillingCid: ContractId<CredentialBilling>,
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<CredentialBilling_Cancel_Result> {
    const [res] = await this.ledger.exercise(
      UserService.UserService_CancelCredentialBilling,
      credentialUserSerivceCid,
      { credentialBillingCid, appTransferContext },
      { disclosedContracts },
    );
    return res;
  }

  async revokeCredentialAndCancelBilling(
    userServiceCid: ContractId<UserService>,
    credentialCid: ContractId<Credential>,
    credentialBillingCid: ContractId<CredentialBilling>,
    appTransferContext: AppTransferContext,
    disclosedContracts: DisclosedContract[],
  ): Promise<void> {
    await this.ledger.exercise(
      UserService.UserService_RevokeCredentialAndCancelBilling,
      userServiceCid,
      { credentialCid, credentialBillingCid, appTransferContext },
      { disclosedContracts },
    );
  }

  async requestRegistryProviderService(
    operator: string,
    provider: string,
    credentialCid: ContractId<Credential>,
  ): Promise<CreateEvent<RegistryProviderServiceRequest>> {
    return this.ledger.create(RegistryProviderServiceRequest, {
      operator,
      provider,
      credentialCid,
    });
  }

  async terminateRegistryProviderService(
    providerServiceCid: ContractId<RegistryProviderService>,
    actor: string,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistryProviderService.ProviderService_Terminate,
      providerServiceCid,
      {
        actor,
      },
    );
  }

  async requestRegistryRegistrarService(
    operator: string,
    provider: string,
    registrar: string,
    credentialCid: ContractId<Credential>,
  ): Promise<CreateEvent<RegistrarServiceRequest>> {
    return this.ledger.create(RegistrarServiceRequest, {
      operator,
      provider,
      registrar,
      credentialCid,
    });
  }

  async terminateRegistryRegistrarService(
    registrarServiceCid: ContractId<RegistrarService>,
    actor: string,
  ): Promise<void> {
    await this.ledger.exercise(RegistrarService.RegistrarService_Terminate, registrarServiceCid, {
      actor,
    });
  }

  async requestRegistryHolderService(
    operator: string,
    provider: string,
    holder: string,
    credentialCid: ContractId<Credential>,
  ): Promise<CreateEvent<HolderServiceRequest>> {
    return this.ledger.create(HolderServiceRequest, {
      operator,
      provider,
      holder,
      credentialCid,
    });
  }

  async terminateRegistryHolderService(
    holderServiceCid: ContractId<HolderService>,
    actor: string,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_Terminate, holderServiceCid, {
      actor,
    });
  }

  async acceptRegistryProviderServiceRequest(
    providerServiceRequestCid: ContractId<RegistryProviderServiceRequest>,
    operatorConfigurationCid: ContractId<RegistryOperatorConfiguration>,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistryProviderServiceRequest.ProviderServiceRequest_Accept,
      providerServiceRequestCid,
      { operatorConfigurationCid },
    );
  }

  async rejectRegistryProviderServiceRequest(
    providerServiceRequestCid: ContractId<RegistryProviderServiceRequest>,
    reason: string,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistryProviderServiceRequest.ProviderServiceRequest_Reject,
      providerServiceRequestCid,
      { reason },
    );
  }

  async cancelRegistryProviderServiceRequest(
    providerServiceRequestCid: ContractId<RegistryProviderServiceRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistryProviderServiceRequest.ProviderServiceRequest_Cancel,
      providerServiceRequestCid,
      {},
    );
  }

  async acceptRegistryRegistrarServiceRequest(
    registrarServiceRequestCid: ContractId<RegistrarServiceRequest>,
    providerConfigurationCid: ContractId<RegistryProviderConfiguration>,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistrarServiceRequest.RegistrarServiceRequest_Accept,
      registrarServiceRequestCid,
      { providerConfigurationCid },
    );
  }

  async rejectRegistryRegistrarServiceRequest(
    registrarServiceRequestCid: ContractId<RegistrarServiceRequest>,
    reason: string,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistrarServiceRequest.RegistrarServiceRequest_Reject,
      registrarServiceRequestCid,
      { reason },
    );
  }

  async cancelRegistryRegistrarServiceRequest(
    registrarServiceRequestCid: ContractId<RegistrarServiceRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      RegistrarServiceRequest.RegistrarServiceRequest_Cancel,
      registrarServiceRequestCid,
      {},
    );
  }

  async acceptRegistryHolderServiceRequest(
    holderServiceRequestCid: ContractId<HolderServiceRequest>,
    providerConfigurationCid: ContractId<RegistryProviderConfiguration>,
  ): Promise<void> {
    await this.ledger.exercise(
      HolderServiceRequest.HolderServiceRequest_Accept,
      holderServiceRequestCid,
      { providerConfigurationCid },
    );
  }

  async rejectRegistryHolderServiceRequest(
    holderServiceRequestCid: ContractId<HolderServiceRequest>,
    reason: string,
  ): Promise<void> {
    await this.ledger.exercise(
      HolderServiceRequest.HolderServiceRequest_Reject,
      holderServiceRequestCid,
      { reason },
    );
  }

  async cancelRegistryHolderServiceRequest(
    holderServiceRequestCid: ContractId<HolderServiceRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      HolderServiceRequest.HolderServiceRequest_Cancel,
      holderServiceRequestCid,
      {},
    );
  }

  // TODO: This should go through the provider service (and possibly others too)
  async createRegistryOperatorService(
    operator: string,
  ): Promise<CreateEvent<RegistryOperatorService>> {
    const res = await this.ledger.create(RegistryOperatorService, { operator });
    return res;
  }

  async createRegistryOperatorConfiguration(
    operatorServiceCid: ContractId<RegistryOperatorService>,
    providerRequirement: PartyCredentialRequirement,
    instrumentMapping: Map<string, InstrumentKey>,
  ): Promise<ContractId<RegistryOperatorConfiguration>> {
    const [res] = await this.ledger.exercise(
      RegistryOperatorService.OperatorService_CreateOperatorConfiguration,
      operatorServiceCid,
      { providerRequirement, instrumentMapping },
    );
    return res.operatorConfigurationCid;
  }

  async createRegistryProviderConfiguration(
    providerServiceCid: ContractId<RegistryProviderService>,
    holderRequirement: PartyCredentialRequirement,
    registrarRequirement: PartyCredentialRequirement,
  ): Promise<ContractId<RegistryProviderConfiguration>> {
    const [res] = await this.ledger.exercise(
      RegistryProviderService.ProviderService_CreateProviderConfiguration,
      providerServiceCid,
      { holderRequirement, registrarRequirement },
    );
    return res.providerConfigurationCid;
  }

  async createRegistryInstrumentConfiguration(
    registryServiceCid: ContractId<RegistrarService>,
    registrar: string,
    instrumentId: string,
    issuerRequirements: PartyCredentialRequirement[],
    holderRequirements: PartyCredentialRequirement[],
  ): Promise<ContractId<InstrumentConfiguration>> {
    const [res] = await this.ledger.exercise(
      RegistrarService.RegistrarService_CreateInstrumentConfiguration,
      registryServiceCid,
      { registrar, instrumentId, issuerRequirements, holderRequirements },
    );
    return res.instrumentConfigurationCid;
  }

  async requestMint(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    instrumentId: string,
    amount: string,
    holdingLabel: string,
    batch: Batch,
  ): Promise<ContractId<MintRequest>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RequestMint,
      holderServiceCid,
      {
        registrar,
        instrumentId,
        amount,
        holdingLabel,
        batch,
      },
    );
    return res.mintRequestCid;
  }

  async acceptMintRequest(
    registrarServiceCid: ContractId<RegistrarService>,
    mintRequestCid: ContractId<MintRequest>,
    registrarObservers: Map<string, Set<string>>,
  ): Promise<ContractId<AcceptedMint>> {
    const [res] = await this.ledger.exercise(
      RegistrarService.RegistrarService_AcceptMintRequest,
      registrarServiceCid,
      {
        cid: mintRequestCid,
        payload: { registrarObservers },
      },
    );
    return res.acceptedMintCid;
  }

  async rejectMintRequest(
    registrarServiceCid: ContractId<RegistrarService>,
    mintRequestCid: ContractId<MintRequest>,
    reason: string,
  ): Promise<ContractId<RejectedMint>> {
    const [res] = await this.ledger.exercise(
      RegistrarService.RegistrarService_RejectMintRequest,
      registrarServiceCid,
      { cid: mintRequestCid, payload: { reason } },
    );
    return res.rejectedMintCid;
  }

  async cancelMintRequest(
    holderServiceCid: ContractId<HolderService>,
    mintRequestCid: ContractId<MintRequest>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelMintRequest, holderServiceCid, {
      cid: mintRequestCid,
    });
  }

  async requestBurn(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    instrumentId: string,
    amount: string,
    holdingLabel: string,
    batch: Batch,
  ): Promise<ContractId<BurnRequest>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RequestBurn,
      holderServiceCid,
      { registrar, instrumentId, amount, holdingLabel, batch },
    );
    return res.burnRequestCid;
  }

  async acceptBurnRequest(
    registrarServiceCid: ContractId<RegistrarService>,
    burnRequestCid: ContractId<BurnRequest>,
  ): Promise<ContractId<AcceptedBurn>> {
    const [res] = await this.ledger.exercise(
      RegistrarService.RegistrarService_AcceptBurnRequest,
      registrarServiceCid,
      { cid: burnRequestCid, payload: {} },
    );
    return res.acceptedBurnCid;
  }

  async rejectBurnRequest(
    registrarServiceCid: ContractId<RegistrarService>,
    burnRequestCid: ContractId<BurnRequest>,
    reason: string,
  ): Promise<ContractId<RejectedBurn>> {
    const [res] = await this.ledger.exercise(
      RegistrarService.RegistrarService_RejectBurnRequest,
      registrarServiceCid,
      { cid: burnRequestCid, payload: { reason } },
    );
    return res.rejectedBurnCid;
  }

  async cancelBurnRequest(
    holderServiceCid: ContractId<HolderService>,
    burnRequestCid: ContractId<BurnRequest>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelBurnRequest, holderServiceCid, {
      cid: burnRequestCid,
    });
  }

  async offerTransfer(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    receiver: string,
    instrumentId: string,
    amount: string,
    senderLabel: string,
    batch: Batch,
  ): Promise<ContractId<TransferOffer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_OfferTransfer,
      holderServiceCid,
      {
        registrar,
        receiver,
        instrumentId,
        amount,
        senderLabel,
        batch,
      },
    );
    return res.transferOfferCid;
  }

  async acceptTransferOffer(
    holderServiceCid: ContractId<HolderService>,
    transferOfferCid: ContractId<TransferOffer>,
    receiverLabel: string,
  ): Promise<ContractId<AcceptedTransfer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptTransferOffer,
      holderServiceCid,
      {
        cid: transferOfferCid,
        payload: { receiverLabel },
      },
    );
    return res.acceptedTransferCid;
  }

  async rejectTransferOffer(
    holderServiceCid: ContractId<HolderService>,
    transferOfferCid: ContractId<TransferOffer>,
    reason: string,
  ): Promise<ContractId<RejectedTransfer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectTransferOffer,
      holderServiceCid,
      { cid: transferOfferCid, payload: { reason } },
    );
    return res.rejectedTransferCid;
  }

  async cancelTransferOffer(
    holderServiceCid: ContractId<HolderService>,
    transferOfferCid: ContractId<TransferOffer>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelTransferOffer, holderServiceCid, {
      cid: transferOfferCid,
    });
  }

  async requestTransfer(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    sender: string,
    instrumentId: string,
    amount: string,
    receiverLabel: string,
    batch: Batch,
  ): Promise<ContractId<TransferRequest>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RequestTransfer,
      holderServiceCid,
      {
        registrar,
        sender,
        instrumentId,
        amount,
        receiverLabel,
        batch,
      },
    );
    return res.transferRequestCid;
  }

  async acceptTransferRequest(
    holderServiceCid: ContractId<HolderService>,
    transferRequestCid: ContractId<TransferRequest>,
    senderLabel: string,
  ): Promise<ContractId<AcceptedTransfer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptTransferRequest,
      holderServiceCid,
      {
        cid: transferRequestCid,
        payload: { senderLabel },
      },
    );
    return res.acceptedTransferCid;
  }

  async rejectTransferRequest(
    holderServiceCid: ContractId<HolderService>,
    transferRequestCid: ContractId<TransferRequest>,
    reason: string,
  ): Promise<ContractId<RejectedTransfer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectTransferRequest,
      holderServiceCid,
      { cid: transferRequestCid, payload: { reason } },
    );
    return res.rejectedTransferCid;
  }

  async cancelTransferRequest(
    holderServiceCid: ContractId<HolderService>,
    transferRequestCid: ContractId<TransferRequest>,
  ): Promise<void> {
    await this.ledger.exercise(
      HolderService.HolderService_CancelTransferRequest,
      holderServiceCid,
      { cid: transferRequestCid },
    );
  }

  async offerLock(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    locker: string,
    instrumentId: string,
    amount: string,
    holdingLabel: string,
    context: string,
    batch: Batch,
  ): Promise<ContractId<LockOffer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_OfferLock,
      holderServiceCid,
      {
        registrar,
        locker,
        instrumentId,
        amount,
        holdingLabel,
        context,
        batch,
      },
    );
    return res.lockOfferCid;
  }

  async acceptLockOffer(
    holderServiceCid: ContractId<HolderService>,
    lockOfferCid: ContractId<LockOffer>,
  ): Promise<ContractId<AcceptedLock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptLockOffer,
      holderServiceCid,
      { cid: lockOfferCid, payload: {} },
    );
    return res.acceptedLockCid;
  }

  async rejectLockOffer(
    holderServiceCid: ContractId<HolderService>,
    lockOfferCid: ContractId<LockOffer>,
    reason: string,
  ): Promise<ContractId<RejectedLock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectLockOffer,
      holderServiceCid,
      { cid: lockOfferCid, payload: { reason } },
    );
    return res.rejectedLockCid;
  }

  async cancelLockOffer(
    holderServiceCid: ContractId<HolderService>,
    lockOfferCid: ContractId<LockOffer>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelLockOffer, holderServiceCid, {
      cid: lockOfferCid,
    });
  }

  async requestLock(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    receiver: string,
    instrumentId: string,
    amount: string,
    context: string,
    batch: Batch,
  ): Promise<ContractId<LockRequest>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RequestLock,
      holderServiceCid,
      { registrar, receiver, instrumentId, amount, context, batch },
    );
    return res.lockRequestCid;
  }

  async acceptLockRequest(
    holderServiceCid: ContractId<HolderService>,
    lockRequestCid: ContractId<LockRequest>,
    holdingLabel: string,
  ): Promise<ContractId<AcceptedLock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptLockRequest,
      holderServiceCid,
      {
        cid: lockRequestCid,
        payload: { holdingLabel },
      },
    );
    return res.acceptedLockCid;
  }

  async rejectLockRequest(
    holderServiceCid: ContractId<HolderService>,
    lockRequestCid: ContractId<LockRequest>,
    reason: string,
  ): Promise<ContractId<RejectedLock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectLockRequest,
      holderServiceCid,
      { cid: lockRequestCid, payload: { reason } },
    );
    return res.rejectedLockCid;
  }

  async cancelLockRequest(
    holderServiceCid: ContractId<HolderService>,
    lockRequestCid: ContractId<LockRequest>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelLockRequest, holderServiceCid, {
      cid: lockRequestCid,
    });
  }

  async offerUnlock(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    locker: string,
    instrumentId: string,
    amount: string,
    holdingLabel: string,
    batch: Batch,
  ): Promise<ContractId<UnlockOffer>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_OfferUnlock,
      holderServiceCid,
      { registrar, locker, instrumentId, amount, holdingLabel, batch },
    );
    return res.unlockOfferCid;
  }

  async acceptUnlockOffer(
    holderServiceCid: ContractId<HolderService>,
    unlockOfferCid: ContractId<UnlockOffer>,
  ): Promise<ContractId<AcceptedUnlock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptUnlockOffer,
      holderServiceCid,
      { cid: unlockOfferCid, payload: {} },
    );
    return res.acceptedUnlockCid;
  }

  async rejectUnlockOffer(
    holderServiceCid: ContractId<HolderService>,
    unlockOfferCid: ContractId<UnlockOffer>,
    reason: string,
  ): Promise<ContractId<RejectedUnlock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectUnlockOffer,
      holderServiceCid,
      { cid: unlockOfferCid, payload: { reason } },
    );
    return res.rejectedUnlockCid;
  }

  async cancelUnlockOffer(
    holderServiceCid: ContractId<HolderService>,
    unlockOfferCid: ContractId<UnlockOffer>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelUnlockOffer, holderServiceCid, {
      cid: unlockOfferCid,
    });
  }

  async requestUnlock(
    holderServiceCid: ContractId<HolderService>,
    registrar: string,
    receiver: string,
    instrumentId: string,
    amount: string,
    holdingLabel: string,
    batch: Batch,
  ): Promise<ContractId<UnlockRequest>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RequestUnlock,
      holderServiceCid,
      { registrar, receiver, instrumentId, amount, holdingLabel, batch },
    );
    return res.unlockRequestCid;
  }

  async acceptUnlockRequest(
    holderServiceCid: ContractId<HolderService>,
    unlockRequestCid: ContractId<UnlockRequest>,
    holdingLabel: string,
  ): Promise<ContractId<AcceptedUnlock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_AcceptUnlockRequest,
      holderServiceCid,
      { cid: unlockRequestCid, payload: { holdingLabel } },
    );
    return res.acceptedUnlockCid;
  }

  async rejectUnlockRequest(
    holderServiceCid: ContractId<HolderService>,
    unlockRequestCid: ContractId<UnlockRequest>,
    reason: string,
  ): Promise<ContractId<RejectedUnlock>> {
    const [res] = await this.ledger.exercise(
      HolderService.HolderService_RejectUnlockRequest,
      holderServiceCid,
      { cid: unlockRequestCid, payload: { reason } },
    );
    return res.rejectedUnlockCid;
  }

  async cancelUnlockRequest(
    holderServiceCid: ContractId<HolderService>,
    unlockRequestCid: ContractId<UnlockRequest>,
  ): Promise<void> {
    await this.ledger.exercise(HolderService.HolderService_CancelUnlockRequest, holderServiceCid, {
      cid: unlockRequestCid,
    });
  }

  async createTradingOperatorService(
    operator: string,
  ): Promise<CreateEvent<TradingOperatorService>> {
    const res = await this.ledger.create(TradingOperatorService, { operator });
    return res;
  }

  async createTradingOperatorConfiguration(
    operatorServiceCid: ContractId<TradingOperatorService>,
    providerRequirement: PartyCredentialRequirement,
    instrumentMapping: Map<string, InstrumentKey>,
    registrarMapping: Map<string, string>,
  ): Promise<ContractId<TradingOperatorConfiguration>> {
    const [res] = await this.ledger.exercise(
      TradingOperatorService.OperatorService_CreateOperatorConfiguration,
      operatorServiceCid,
      { providerRequirement, instrumentMapping, registrarMapping },
    );
    return res.operatorConfigurationCid;
  }

  async createTradingProviderConfiguration(
    providerServiceCid: ContractId<TradingProviderService>,
    traderRequirement: PartyCredentialRequirement,
  ): Promise<ContractId<TradingProviderConfiguration>> {
    const [res] = await this.ledger.exercise(
      TradingProviderService.ProviderService_CreateProviderConfiguration,
      providerServiceCid,
      { traderRequirement },
    );
    return res.providerConfigurationCid;
  }

  async requestTradingProviderService(
    operator: string,
    provider: string,
    credentialCid: ContractId<Credential>,
  ): Promise<CreateEvent<TradingProviderServiceRequest>> {
    return this.ledger.create(TradingProviderServiceRequest, {
      operator,
      provider,
      credentialCid,
    });
  }

  async terminateTradingProviderService(
    providerServiceCid: ContractId<TradingProviderService>,
    actor: string,
  ): Promise<ProviderService_Terminate_Result> {
    const [res] = await this.ledger.exercise(
      TradingProviderService.ProviderService_Terminate,
      providerServiceCid,
      {
        actor,
      },
    );
    return res;
  }

  async acceptTradingProviderServiceRequest(
    providerServiceRequestCid: ContractId<TradingProviderServiceRequest>,
    operatorConfigurationCid: ContractId<TradingOperatorConfiguration>,
  ): Promise<ProviderServiceRequest_Accept_Result> {
    const [res] = await this.ledger.exercise(
      TradingProviderServiceRequest.ProviderServiceRequest_Accept,
      providerServiceRequestCid,
      { operatorConfigurationCid },
    );
    return res;
  }

  async rejectTradingProviderServiceRequest(
    providerServiceRequestCid: ContractId<TradingProviderServiceRequest>,
    reason: string,
  ): Promise<ProviderServiceRequest_Reject_Result> {
    const [res] = await this.ledger.exercise(
      TradingProviderServiceRequest.ProviderServiceRequest_Reject,
      providerServiceRequestCid,
      { reason },
    );
    return res;
  }

  async cancelTradingProviderServiceRequest(
    providerServiceRequestCid: ContractId<TradingProviderServiceRequest>,
  ): Promise<ProviderServiceRequest_Cancel_Result> {
    const [res] = await this.ledger.exercise(
      TradingProviderServiceRequest.ProviderServiceRequest_Cancel,
      providerServiceRequestCid,
      {},
    );
    return res;
  }

  async requestTradingTraderService(
    operator: string,
    provider: string,
    trader: string,
    credentialCid: ContractId<Credential>,
  ): Promise<CreateEvent<TraderServiceRequest>> {
    return this.ledger.create(TraderServiceRequest, {
      operator,
      provider,
      trader,
      credentialCid,
    });
  }

  async terminateTradingTraderService(
    traderServiceCid: ContractId<TraderService>,
    actor: string,
  ): Promise<TraderService_Terminate_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_Terminate,
      traderServiceCid,
      {
        actor,
      },
    );
    return res;
  }

  async acceptTradingTraderServiceRequest(
    traderServiceRequestCid: ContractId<TraderServiceRequest>,
    providerConfigurationCid: ContractId<TradingProviderConfiguration>,
  ): Promise<TraderServiceRequest_Accept_Result> {
    const [res] = await this.ledger.exercise(
      TraderServiceRequest.TraderServiceRequest_Accept,
      traderServiceRequestCid,
      { providerConfigurationCid },
    );
    return res;
  }

  async rejectTradingTraderServiceRequest(
    traderServiceRequestCid: ContractId<TraderServiceRequest>,
    reason: string,
  ): Promise<TraderServiceRequest_Reject_Result> {
    const [res] = await this.ledger.exercise(
      TraderServiceRequest.TraderServiceRequest_Reject,
      traderServiceRequestCid,
      { reason },
    );
    return res;
  }

  async cancelTradingTraderServiceRequest(
    traderServiceRequestCid: ContractId<TraderServiceRequest>,
  ): Promise<TraderServiceRequest_Cancel_Result> {
    const [res] = await this.ledger.exercise(
      TraderServiceRequest.TraderServiceRequest_Cancel,
      traderServiceRequestCid,
      {},
    );
    return res;
  }

  async requestTrade(
    traderServiceCid: ContractId<TraderService>,
    trade: Trade,
    direction: Direction,
  ): Promise<TraderService_RequestTrade_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_RequestTrade,
      traderServiceCid,
      { trade, direction },
    );
    return res;
  }

  async acceptTradeRequest(
    traderServiceCid: ContractId<TraderService>,
    tradeRequestCid: ContractId<TradeRequest>,
    pricePerUnit: string,
    acceptorLabel: string,
  ): Promise<TradeRequest_Accept_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_AcceptTradeRequest,
      traderServiceCid,
      {
        tradeRequestCid,
        payload: {
          pricePerUnit,
          acceptorLabel,
        },
      },
    );
    return res;
  }

  async rejectTradeRequest(
    traderServiceCid: ContractId<TraderService>,
    tradeRequestCid: ContractId<TradeRequest>,
    reason: string,
  ): Promise<TradeRequest_Reject_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_RejectTradeRequest,
      traderServiceCid,
      { tradeRequestCid, payload: { reason } },
    );
    return res;
  }

  async cancelTradeRequest(
    traderServiceCid: ContractId<TraderService>,
    tradeRequestCid: ContractId<TradeRequest>,
  ): Promise<TradeRequest_Cancel_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_CancelTradeRequest,
      traderServiceCid,
      { tradeRequestCid, payload: {} },
    );
    return res;
  }

  async offerTrade(
    traderServiceCid: ContractId<TraderService>,
    trade: Trade,
    direction: Direction,
    pricePerUnit: string,
    offerorLabel: string,
  ): Promise<TraderService_OfferTrade_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_OfferTrade,
      traderServiceCid,
      {
        trade,
        direction,
        pricePerUnit,
        offerorLabel,
      },
    );
    return res;
  }

  async acceptTradeOffer(
    traderServiceCid: ContractId<TraderService>,
    tradeOfferCid: ContractId<TradeOffer>,
    acceptorLabel: string,
  ): Promise<TradeOffer_Accept_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_AcceptTradeOffer,
      traderServiceCid,
      {
        tradeOfferCid,
        payload: { acceptorLabel },
      },
    );
    return res;
  }

  async rejectTradeOffer(
    traderServiceCid: ContractId<TraderService>,
    tradeOfferCid: ContractId<TradeOffer>,
    reason: string,
  ): Promise<TradeOffer_Reject_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_RejectTradeOffer,
      traderServiceCid,
      { tradeOfferCid, payload: { reason } },
    );
    return res;
  }

  async cancelTradeOffer(
    traderServiceCid: ContractId<TraderService>,
    tradeOfferCid: ContractId<TradeOffer>,
  ): Promise<TradeOffer_Cancel_Result> {
    const [res] = await this.ledger.exercise(
      TraderService.TraderService_CancelTradeOffer,
      traderServiceCid,
      { tradeOfferCid, payload: {} },
    );
    return res;
  }

  async instructAcceptedTrade(
    operatorServiceCid: ContractId<TradingOperatorService>,
    providerServiceCid: ContractId<TradingProviderService>,
    acceptedTradeCid: ContractId<AcceptedTrade>,
    instrumentMapping: Map<string, InstrumentKey>,
    registrarMapping: Map<string, string>,
  ): Promise<AcceptedTrade_Instruct_Result> {
    const [res] = await this.ledger.exercise(
      TradingOperatorService.OperatorService_InstructAcceptedTrade,
      operatorServiceCid,
      { providerServiceCid, acceptedTradeCid, payload: { instrumentMapping, registrarMapping } },
    );
    return res;
  }

  async tapCoin(
    amuletRulesCid: ContractId<AmuletRules>,
    receiver: string,
    amount: string,
    openRound: ContractId<OpenMiningRound>,
    disclosedContracts: DisclosedContract[],
  ): Promise<AmuletRules_DevNet_TapResult> {
    const [res] = await this.ledger.exercise(
      AmuletRules.AmuletRules_DevNet_Tap,
      amuletRulesCid,
      { receiver, amount, openRound },
      { disclosedContracts },
    );
    return res;
  }

  async calculateFees(
    amuletRulesCid: ContractId<AmuletRules>,
    context: TransferContext,
    sender: string,
    outputs: [TransferOutput],
    disclosedContracts: DisclosedContract[],
    latestAmuletPrice: string,
  ) {
    const [res] = await this.ledger.exercise(
      AmuletRules.AmuletRules_ComputeFees,
      amuletRulesCid,
      { context, sender, outputs },
      { disclosedContracts },
    );
    return { res, latestAmuletPrice };
  }

  async getContractById<T extends object, K>(
    tmpl: Template<T, K>,
    cid: ContractId<T>,
  ): Promise<TemplateContract<T>> {
    const r = await this.ledger.fetch(tmpl, cid);
    if (!r) {
      throw new Error(`Contract with id ${cid} is not active`);
    }
    return TemplateContract.fromJsonAPI(r);
  }
}
