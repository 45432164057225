import { useCallback, useState } from 'react';
import {
  Trade,
  TradeOffer,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { ContractId } from '@daml/types';
import FormDialog from '../../../components/Dialog/FormDialog';
import TextInput from '../../../components/Form/TextInput';
import useAcceptTradeOffer from '../../../hooks/mutations/trading/trade/useAcceptTradeOffer';
import useMutate from '../../../hooks/mutations/useMutate';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import { ERR_ACC_TRADE_OFF, SUC_ACC_TRADE_OFF } from '../../../utils/strings';

export type DialogProps = {
  open: boolean;
  onClose: () => void;
  tradeOffer?: { contractId: string; state: string; payload: Trade };
};

export const AcceptTradeOfferDialog = ({ open, onClose, tradeOffer }: DialogProps) => {
  const [acceptorLabel, setAcceptorLabel] = useState<string>('');

  const traderService = useTraderService();
  const acceptTradeOffer = useAcceptTradeOffer();
  const mutate = useMutate();

  const resetState = useCallback(() => {
    setAcceptorLabel('');
  }, [setAcceptorLabel]);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [resetState, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!traderService.data) throw new Error('Trader service not found');
    if (!tradeOffer) throw new Error('Trade request not set');
    const payload = {
      traderServiceCid: traderService.data.contractId,
      tradeOfferCid: tradeOffer.contractId as ContractId<TradeOffer>,
      acceptorLabel,
    };
    await mutate(acceptTradeOffer, payload, SUC_ACC_TRADE_OFF, ERR_ACC_TRADE_OFF);
    handleClose();
  }, [traderService.data, tradeOffer, acceptorLabel, acceptTradeOffer, mutate, handleClose]);

  const isLoading = traderService.isLoading || !traderService.data;
  if (isLoading) return null;

  const disableSubmit = false;
  return (
    <FormDialog
      open={open}
      title="Accept trade offer"
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitName="Accept"
      isSubmitDisabled={disableSubmit}
    >
      <TextInput label="Account label" value={acceptorLabel} setValue={setAcceptorLabel} />
    </FormDialog>
  );
};
