import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { CredentialOffer } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Model/Offer';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelCredentialOffer: () => UseMutationResult<
  void,
  string,
  {
    userServiceCid: ContractId<UserService>;
    credentialOfferCid: ContractId<CredentialOffer>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelCredentialOffer', ledgerApi],
    mutationFn: async ({ userServiceCid, credentialOfferCid }) => {
      return ledgerApi.cancelCredentialOffer(userServiceCid, credentialOfferCid);
    },
  });
};

export default useCancelCredentialOffer;
