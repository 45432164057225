import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { UserService } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTerminateUserService: () => UseMutationResult<
  void,
  string,
  { userServiceCid: ContractId<UserService> }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();

  return useMutation({
    mutationKey: ['terminateUserService', ledgerApi],
    mutationFn: async ({ userServiceCid }) => {
      return ledgerApi.terminateCredentialUserService(userServiceCid, party);
    },
  });
};

export default useTerminateUserService;
