import { useState, useEffect } from 'react';
import { z } from 'zod';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import Copyright from '../components/Copyright';
import useBranding from '../hooks/other/useBranding';
import useUserState from '../hooks/other/useUserState';

const LowerCaseAlpha = z
  .string()
  .refine((value) => /^[a-z]+$/.test(value), 'Name contains lowercase alphabet');

const Email = z.string().email();

function validate(e: string) {
  const lowerCaseAlpha = LowerCaseAlpha.safeParse(e);
  const email = Email.safeParse(e);
  return lowerCaseAlpha.success || email.success;
}

const Login = () => {
  const [userId, setUserId] = useState<string>('');
  const { login, isSelfSignedToken } = useUserState();
  const { branding } = useBranding();

  const [inputValidated, setInputValidated] = useState(false);
  const [showUserIdAsTitle] = useState(false);

  const loginKey = (key: string) => {
    if (key === 'Enter') {
      login(userId);
    }
  };

  // If you want to display the user id in the title, e.g., for demo purposes, simply set the
  // showUserIdAsTitle state to true above.
  useEffect(() => {
    if (userId && showUserIdAsTitle) {
      document.title = userId.toUpperCase();
    }
  }, [userId, showUserIdAsTitle]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
          pl: 50,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {branding.loginLogo}
          <Typography component="h1" variant="h4" sx={{ mt: 5 }}>
            Utility
          </Typography>
          <Box sx={{ mt: 1 }}>
            {isSelfSignedToken() ? (
              <>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="login-userid-input"
                  label="User Id"
                  name="userid"
                  autoComplete="username"
                  autoFocus
                  onChange={(event) => {
                    setInputValidated(validate(event.target.value));
                    setUserId(event.target.value);
                  }}
                  onKeyDown={(event) => loginKey(event.key)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!inputValidated}
                  sx={{ mt: 3, mb: 2 }}
                  id="login-button"
                  onClick={() => login(userId)}
                >
                  Log In
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                id="login-button"
                autoFocus
                onClick={() => login('')}
              >
                Log In
              </Button>
            )}
          </Box>
          <Copyright />
        </Box>
      </Container>
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          pl: 50,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {branding.loginImage}
        </Box>
      </Container>
    </Container>
  );
};

export default Login;
