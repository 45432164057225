import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { UserServiceRequest } from '@daml.js/utility-credential-app-v0/lib/Utility/Credential/App/V0/Service/User';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectUserServiceRequest: () => UseMutationResult<
  void,
  string,
  { userServiceRequestCid: ContractId<UserServiceRequest> }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectuserServiceRequest', ledgerApi],
    mutationFn: async ({ userServiceRequestCid }) => {
      return ledgerApi.rejectCredentialUserServiceRequest(userServiceRequestCid);
    },
  });
};

export default useRejectUserServiceRequest;
