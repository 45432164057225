import { PropsWithChildren } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AsyncButton } from '../Button/AsyncButton';
import { SyncButton } from '../Button/SyncButton';
import useStyles from './styles';

const FormDialog = ({
  open,
  title,
  onCancel,
  onSubmit,
  submitName,
  children,
  isSubmitDisabled,
}: PropsWithChildren<{
  open: boolean;
  title: string;
  onCancel: () => void;
  onSubmit: () => Promise<unknown>;
  submitName: string;
  isSubmitDisabled?: boolean;
}>) => {
  const { classes } = useStyles();

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: 0 } }}
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.heading}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SyncButton size="large" onClick={onCancel}>
          Cancel
        </SyncButton>
        <AsyncButton size="large" onClick={onSubmit} disabled={isSubmitDisabled}>
          {submitName}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
