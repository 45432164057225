import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { TransferOffer } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { Batch } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Types';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useOfferTransfer: () => UseMutationResult<
  ContractId<TransferOffer>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    registrar: string;
    receiver: string;
    instrumentId: string;
    amount: string;
    senderLabel: string;
    batch: Batch;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['offerTransfer', ledgerApi],
    mutationFn: async ({
      holderServiceCid,
      registrar,
      receiver,
      instrumentId,
      amount,
      senderLabel,
      batch,
    }) => {
      return ledgerApi.offerTransfer(
        holderServiceCid,
        registrar,
        receiver,
        instrumentId,
        amount,
        senderLabel,
        batch,
      );
    },
  });
};

export default useOfferTransfer;
