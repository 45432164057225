import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  TradeRequest,
  TradeRequest_Cancel_Result,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Model/Trade';
import { TraderService } from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useCancelTradeRequest: () => UseMutationResult<
  TradeRequest_Cancel_Result,
  string,
  {
    traderServiceCid: ContractId<TraderService>;
    tradeRequestCid: ContractId<TradeRequest>;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['cancelTradeRequest', ledgerApi],
    mutationFn: async ({ traderServiceCid, tradeRequestCid }) => {
      return ledgerApi.cancelTradeRequest(traderServiceCid, tradeRequestCid);
    },
  });
};

export default useCancelTradeRequest;
