import { useState } from 'react';
import { Chip } from '@mui/material';
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { InstrumentConfiguration } from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Configuration/Instrument';
import { SyncButton } from '../../../components/Button/SyncButton';
import Loading from '../../../components/Loading';
import { QueryTable } from '../../../components/Table/QueryTable';
import useRegistryInstrumentConfigurations from '../../../hooks/queries/registry/configuration/useRegistryInstrumentConfigurations';
import useRegistrarService from '../../../hooks/queries/registry/onboarding/useRegistrarService';
import TemplateContract from '../../../utils/TemplateContract';
import { prettyParty } from '../../../utils/common';
import { CreateInstrumentConfigurationDialog } from './CreateInstrumentConfigurationDialog';

type Row = TemplateContract<InstrumentConfiguration>;

const createHeaders: () => GridColDef<Row>[] = () => {
  return [
    {
      field: 'registrar',
      headerName: 'Registrar',
      valueGetter: (params) => prettyParty(params.row.payload.registrar),
      flex: 150,
      filterable: false,
    },
    {
      field: 'instrumentId',
      headerName: 'InstrumentId',
      valueGetter: (params) => `${params.row.payload.instrumentId}`,
      flex: 150,
      filterable: false,
    },
    {
      field: 'issuerRequirements',
      headerName: 'Issuer Requirements',
      renderCell: (params) => (
        <>
          {params.row.payload.issuerRequirements.flatMap((ir) =>
            ir.requiredClaims.map((c) => (
              <>
                <Chip key={c._1 + c._2} label={c._1} />
                <Chip key={c._1 + c._2} label={c._2} />
              </>
            )),
          )}
        </>
      ),
      flex: 250,
      filterable: false,
    },
    {
      field: 'holderRequirement',
      headerName: 'Holder Requirement',
      renderCell: (params) => (
        <>
          {params.row.payload.holderRequirements.flatMap((hr) =>
            hr.requiredClaims.map((c) => (
              <>
                <Chip key={c._1 + c._2} label={c._1} />
                <Chip key={c._1 + c._2} label={c._2} />
              </>
            )),
          )}
        </>
      ),
      flex: 250,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [createInstrumentConfigurationIsOpen, setCreateInstrumentConfigurationIsOpen] =
    useState(false);
  const registrarService = useRegistrarService();

  const { isLoading } = registrarService;
  if (isLoading) return null;

  return (
    <GridToolbarContainer>
      {createInstrumentConfigurationIsOpen && (
        <CreateInstrumentConfigurationDialog
          open={createInstrumentConfigurationIsOpen}
          onClose={() => setCreateInstrumentConfigurationIsOpen(false)}
        />
      )}
      <SyncButton
        onClick={() => setCreateInstrumentConfigurationIsOpen(true)}
        disabled={!registrarService.data}
      >
        Create instrument configuration
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const InstrumentConfigurations = () => {
  const configurations = useRegistryInstrumentConfigurations();

  const isLoading = configurations.isLoading || !configurations.data;
  if (isLoading) return <Loading />;

  return (
    <QueryTable
      title="Instrument Configurations"
      variant="h3"
      columns={createHeaders()}
      rowQuery={configurations}
      rowKey={(a) => a.contractId}
      toolbar={Toolbar}
    />
  );
};
