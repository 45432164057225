import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  AcceptedLock,
  LockRequest,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Lock';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useAcceptLockRequest: () => UseMutationResult<
  ContractId<AcceptedLock>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    lockRequestCid: ContractId<LockRequest>;
    holdingLabel: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['acceptLockRequest', ledgerApi],
    mutationFn: async ({ holderServiceCid, lockRequestCid, holdingLabel }) => {
      return ledgerApi.acceptLockRequest(holderServiceCid, lockRequestCid, holdingLabel);
    },
  });
};

export default useAcceptLockRequest;
