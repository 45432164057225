import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import {
  RejectedTransfer,
  TransferOffer,
} from '@daml.js/utility-registry-v0/lib/Utility/Registry/V0/Holding/Transfer';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';

const useRejectTransferOffer: () => UseMutationResult<
  ContractId<RejectedTransfer>,
  string,
  {
    holderServiceCid: ContractId<HolderService>;
    transferOfferCid: ContractId<TransferOffer>;
    reason: string;
  }
> = () => {
  const ledgerApi = useLedgerApiClient();

  return useMutation({
    mutationKey: ['rejectTransferOffer', ledgerApi],
    mutationFn: async ({ holderServiceCid, transferOfferCid, reason }) => {
      return ledgerApi.rejectTransferOffer(holderServiceCid, transferOfferCid, reason);
    },
  });
};

export default useRejectTransferOffer;
