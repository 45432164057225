import { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import {
  ProviderService,
  ProviderServiceRequest,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Provider';
import {
  TraderService,
  TraderServiceRequest,
} from '@daml.js/utility-trading-app-v0/lib/Utility/Trading/App/V0/Service/Trader';
import { ContractId } from '@daml/types';
import { AsyncButton } from '../../../components/Button/AsyncButton';
import { SyncButton } from '../../../components/Button/SyncButton';
import { RejectDialog } from '../../../components/Dialog/RejectDialog';
import Loading from '../../../components/Loading';
import { DataTable } from '../../../components/Table/DataTable';
import useAcceptProviderServiceRequest from '../../../hooks/mutations/trading/onboarding/useAcceptProviderServiceRequest';
import useAcceptTraderServiceRequest from '../../../hooks/mutations/trading/onboarding/useAcceptTraderServiceRequest';
import useCancelProviderServiceRequest from '../../../hooks/mutations/trading/onboarding/useCancelProviderServiceRequest';
import useCancelTraderServiceRequest from '../../../hooks/mutations/trading/onboarding/useCancelTraderServiceRequest';
import useCreateOperatorService from '../../../hooks/mutations/trading/onboarding/useCreateOperatorService';
import useRejectProviderServiceRequest from '../../../hooks/mutations/trading/onboarding/useRejectProviderServiceRequest';
import useRejectTraderServiceRequest from '../../../hooks/mutations/trading/onboarding/useRejectTraderServiceRequest';
import useTerminateProviderService from '../../../hooks/mutations/trading/onboarding/useTerminateProviderService';
import useTerminateTraderService from '../../../hooks/mutations/trading/onboarding/useTerminateTraderService';
import useMutate from '../../../hooks/mutations/useMutate';
import useParties from '../../../hooks/other/useParties';
import useOperatorConfiguration from '../../../hooks/queries/trading/configuration/useOperatorConfiguration';
import useProviderConfiguration from '../../../hooks/queries/trading/configuration/useProviderConfiguration';
import useOperatorService from '../../../hooks/queries/trading/onboarding/useOperatorService';
import useProviderService from '../../../hooks/queries/trading/onboarding/useProviderService';
import useProviderServiceRequests from '../../../hooks/queries/trading/onboarding/useProviderServiceRequests';
import useProviderServices from '../../../hooks/queries/trading/onboarding/useProviderServices';
import useTraderService from '../../../hooks/queries/trading/onboarding/useTraderService';
import useTraderServiceRequests from '../../../hooks/queries/trading/onboarding/useTraderServiceRequests';
import useTraderServices from '../../../hooks/queries/trading/onboarding/useTraderServices';
import { prettyParty } from '../../../utils/common';
import {
  ERR_TRD_ACC_PROVIDER_SVC,
  ERR_TRD_ACC_TRADER_SVC,
  ERR_TRD_CAN_PROVIDER_SVC,
  ERR_TRD_CAN_TRADER_SVC,
  ERR_TRD_CRE_OP_SVC,
  ERR_TRD_REJ_PROVIDER_SVC,
  ERR_TRD_REJ_TRADER_SVC,
  ERR_TRD_TER_PROVIDER_SVC,
  ERR_TRD_TER_TRADER_SVC,
  SUC_TRD_ACC_PROVIDER_SVC,
  SUC_TRD_ACC_TRADER_SVC,
  SUC_TRD_CAN_PROVIDER_SVC,
  SUC_TRD_CAN_TRADER_SVC,
  SUC_TRD_CRE_OP_SVC,
  SUC_TRD_REJ_PROVIDER_SVC,
  SUC_TRD_REJ_TRADER_SVC,
  SUC_TRD_TER_PROVIDER_SVC,
  SUC_TRD_TER_TRADER_SVC,
} from '../../../utils/strings';
import { RequestProviderServiceDialog } from './RequestProviderServiceDialog';
import { RequestTraderServiceDialog } from './RequestTraderServiceDialog';

type Row = {
  contractId: string;
  serviceType: string;
  operator: string;
  provider: string;
  user: string;
};

type Action = {
  name: string;
  action: (r: Row) => Promise<unknown>;
};

const createHeaders: (createActions: (r: Row) => Action[]) => GridColDef<Row>[] = (
  createActions,
) => {
  const renderActionCell = (params: GridRenderCellParams<Row>) => {
    const actions = createActions(params.row);
    return (
      <>
        {actions.map(({ name, action }) => (
          <AsyncButton key={name} sx={{ mr: 1 }} onClick={() => action(params.row)}>
            {name}
          </AsyncButton>
        ))}
      </>
    );
  };

  return [
    {
      field: 'serviceType',
      headerName: 'Service Type',
      valueGetter: (params) => params.row.serviceType,
      flex: 150,
      filterable: false,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      valueGetter: (params) => prettyParty(params.row.operator),
      flex: 150,
      filterable: false,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      valueGetter: (params) => prettyParty(params.row.provider),
      flex: 150,
      filterable: false,
    },
    {
      field: 'user',
      headerName: 'User',
      valueGetter: (params) => prettyParty(params.row.user),
      flex: 150,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => renderActionCell(params),
      flex: 150,
      filterable: false,
    },
  ];
};

const Toolbar = () => {
  const [providerIsOpen, setProviderIsOpen] = useState<boolean>(false);
  const [traderIsOpen, setTraderIsOpen] = useState<boolean>(false);
  const { operator, party } = useParties();
  const traderService = useTraderService();
  const traderServiceRequests = useTraderServiceRequests();
  const providerService = useProviderService();
  const providerServiceRequests = useProviderServiceRequests();
  const operatorService = useOperatorService();
  const providerConfiguration = useProviderConfiguration();
  const createOperatorService = useCreateOperatorService();
  const mutate = useMutate();

  const createOperator = useCallback(() => {
    return mutate(createOperatorService, undefined, SUC_TRD_CRE_OP_SVC, ERR_TRD_CRE_OP_SVC);
  }, [createOperatorService, mutate]);

  const isLoading =
    traderService.isLoading ||
    traderServiceRequests.isLoading ||
    providerConfiguration.isLoading ||
    providerService.isLoading ||
    providerServiceRequests.isLoading ||
    operatorService.isLoading;
  if (isLoading) return null;

  const traderServiceRequest = traderServiceRequests.data!.find((r) => r.payload.trader === party);
  const providerServiceRequest = providerServiceRequests.data!.find(
    (r) => r.payload.provider === party,
  );

  const canRequestTrader = !traderService.data && !traderServiceRequest;
  const canRequestProvider = !providerService.data && !providerServiceRequest;
  const canCreateOperator = !operatorService.data && operator === party;

  return (
    <GridToolbarContainer>
      {!!providerIsOpen && (
        <RequestProviderServiceDialog
          open={!!providerIsOpen}
          onClose={() => setProviderIsOpen(false)}
        />
      )}
      {!!traderIsOpen && (
        <RequestTraderServiceDialog open={!!traderIsOpen} onClose={() => setTraderIsOpen(false)} />
      )}
      {operator === party && (
        <AsyncButton onClick={createOperator} disabled={!canCreateOperator}>
          Create operator service
        </AsyncButton>
      )}
      <SyncButton onClick={() => setTraderIsOpen(true)} disabled={!canRequestTrader}>
        Request trader service
      </SyncButton>
      <SyncButton onClick={() => setProviderIsOpen(true)} disabled={!canRequestProvider}>
        Request provider service
      </SyncButton>
    </GridToolbarContainer>
  );
};

export const Onboarding = () => {
  const [onReject, setOnReject] = useState<((reason: string) => Promise<unknown>) | undefined>();
  const { party } = useParties();
  const traderServices = useTraderServices();
  const providerServices = useProviderServices();
  const traderServiceRequests = useTraderServiceRequests();
  const providerServiceRequests = useProviderServiceRequests();
  const terminateTraderService = useTerminateTraderService();
  const terminateProviderService = useTerminateProviderService();
  const acceptTraderServiceRequest = useAcceptTraderServiceRequest();
  const acceptProviderServiceRequest = useAcceptProviderServiceRequest();
  const rejectTraderServiceRequest = useRejectTraderServiceRequest();
  const rejectProviderServiceRequest = useRejectProviderServiceRequest();
  const cancelTraderServiceRequest = useCancelTraderServiceRequest();
  const cancelProviderServiceRequest = useCancelProviderServiceRequest();
  const providerConfiguration = useProviderConfiguration();
  const operatorConfiguration = useOperatorConfiguration();
  const mutate = useMutate();

  const terminateTrader = useCallback(
    (r: Row) => {
      const payload = { traderServiceCid: r.contractId as ContractId<TraderService> };
      return mutate(
        terminateTraderService,
        payload,
        SUC_TRD_TER_TRADER_SVC,
        ERR_TRD_TER_TRADER_SVC,
      );
    },
    [terminateTraderService, mutate],
  );

  const terminateProvider = useCallback(
    (r: Row) => {
      const payload = { providerServiceCid: r.contractId as ContractId<ProviderService> };
      return mutate(
        terminateProviderService,
        payload,
        SUC_TRD_TER_PROVIDER_SVC,
        ERR_TRD_TER_PROVIDER_SVC,
      );
    },
    [terminateProviderService, mutate],
  );

  const acceptTrader = useCallback(
    (r: Row) => {
      if (!providerConfiguration.data) throw new Error('Provider configuration not loaded');
      const payload = {
        traderServiceRequestCid: r.contractId as ContractId<TraderServiceRequest>,
        providerConfigurationCid: providerConfiguration.data.contractId,
      };
      return mutate(
        acceptTraderServiceRequest,
        payload,
        SUC_TRD_ACC_TRADER_SVC,
        ERR_TRD_ACC_TRADER_SVC,
      );
    },
    [acceptTraderServiceRequest, providerConfiguration, mutate],
  );

  const acceptProvider = useCallback(
    (r: Row) => {
      if (!operatorConfiguration.data) throw new Error('Provider configuration not loaded');
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
        operatorConfigurationCid: operatorConfiguration.data.contractId,
      };
      return mutate(
        acceptProviderServiceRequest,
        payload,
        SUC_TRD_ACC_PROVIDER_SVC,
        ERR_TRD_ACC_PROVIDER_SVC,
      );
    },
    [acceptProviderServiceRequest, operatorConfiguration, mutate],
  );

  const rejectTrader = useCallback(
    (r: Row, rejectReason: string) => {
      const payload = {
        traderServiceRequestCid: r.contractId as ContractId<TraderServiceRequest>,
        rejectReason,
      };
      return mutate(
        rejectTraderServiceRequest,
        payload,
        SUC_TRD_REJ_TRADER_SVC,
        ERR_TRD_REJ_TRADER_SVC,
      );
    },
    [rejectTraderServiceRequest, mutate],
  );

  const rejectProvider = useCallback(
    (r: Row, rejectReason: string) => {
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
        rejectReason,
      };
      return mutate(
        rejectProviderServiceRequest,
        payload,
        SUC_TRD_REJ_PROVIDER_SVC,
        ERR_TRD_REJ_PROVIDER_SVC,
      );
    },
    [rejectProviderServiceRequest, mutate],
  );

  const cancelTrader = useCallback(
    (r: Row) => {
      const payload = {
        traderServiceRequestCid: r.contractId as ContractId<TraderServiceRequest>,
      };
      return mutate(
        cancelTraderServiceRequest,
        payload,
        SUC_TRD_CAN_TRADER_SVC,
        ERR_TRD_CAN_TRADER_SVC,
      );
    },
    [cancelTraderServiceRequest, mutate],
  );

  const cancelProvider = useCallback(
    (r: Row) => {
      const payload = {
        providerServiceRequestCid: r.contractId as ContractId<ProviderServiceRequest>,
      };
      return mutate(
        cancelProviderServiceRequest,
        payload,
        SUC_TRD_CAN_PROVIDER_SVC,
        ERR_TRD_CAN_PROVIDER_SVC,
      );
    },
    [cancelProviderServiceRequest, mutate],
  );

  const createServiceActions = (r: Row) => {
    if (r.serviceType === 'TraderService') return [{ name: 'Terminate', action: terminateTrader }];
    if (r.serviceType === 'ProviderService')
      return [{ name: 'Terminate', action: terminateProvider }];
    return [];
  };

  const createRequestActions = (r: Row) => {
    if (r.operator === party && r.serviceType === 'ProviderService') {
      return [
        { name: 'Accept', action: acceptProvider },
        {
          name: 'Reject',
          action: (row: Row) =>
            Promise.resolve(setOnReject((reason: string) => rejectProvider(row, reason))),
        },
      ];
    }
    if (r.provider === party) {
      if (r.serviceType === 'TraderService')
        return [
          { name: 'Accept', action: acceptTrader },
          {
            name: 'Reject',
            action: (row: Row) =>
              Promise.resolve(setOnReject((reason: string) => rejectTrader(row, reason))),
          },
        ];
      return [];
    }
    if (r.user === party) {
      if (r.serviceType === 'TraderService') return [{ name: 'Cancel', action: cancelTrader }];
      if (r.serviceType === 'ProviderService') return [{ name: 'Cancel', action: cancelProvider }];
      return [];
    }
    return [];
  };

  const isLoading =
    traderServices.isLoading ||
    providerServices.isLoading ||
    traderServiceRequests.isLoading ||
    providerServiceRequests.isLoading ||
    !traderServices.data ||
    !providerServices.data ||
    !traderServiceRequests.data ||
    !providerServiceRequests.data;
  if (isLoading) return <Loading />;

  const serviceRows = traderServices.data
    .map((r) => ({
      contractId: r.contractId as string,
      serviceType: 'TraderService',
      operator: r.payload.operator,
      provider: r.payload.provider,
      user: r.payload.trader,
    }))
    .concat(
      providerServices.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'ProviderService',
        operator: r.payload.operator,
        provider: '',
        user: r.payload.provider,
      })),
    );

  const requestRows = traderServiceRequests.data
    .map((r) => ({
      contractId: r.contractId as string,
      serviceType: 'TraderService',
      operator: r.payload.operator,
      provider: r.payload.provider,
      user: r.payload.trader,
    }))
    .concat(
      providerServiceRequests.data.map((r) => ({
        contractId: r.contractId as string,
        serviceType: 'ProviderService',
        operator: r.payload.operator,
        provider: '',
        user: r.payload.provider,
      })),
    );

  return (
    <>
      {!!onReject && (
        <RejectDialog
          open={!!onReject}
          onClose={() => setOnReject(undefined)}
          onReject={onReject}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataTable
            title="Services"
            variant="h3"
            columns={createHeaders(createServiceActions)}
            rows={serviceRows}
            rowKey={(a) => a.contractId}
            toolbar={Toolbar}
          />
        </Grid>
        <Grid item xs={6}>
          <DataTable
            title="Requests"
            variant="h3"
            columns={createHeaders(createRequestActions)}
            rows={requestRows}
            rowKey={(a) => a.contractId}
          />
        </Grid>
      </Grid>
    </>
  );
};
