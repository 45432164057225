import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HolderService } from '@daml.js/utility-registry-app-v0/lib/Utility/Registry/App/V0/Service/Holder';
import { ContractId } from '@daml/types';
import useLedgerApiClient from '../../../other/useLedgerApiClient';
import useParties from '../../../other/useParties';

const useTerminateHolderService: () => UseMutationResult<
  void,
  string,
  { holderServiceCid: ContractId<HolderService> }
> = () => {
  const ledgerApi = useLedgerApiClient();
  const { party } = useParties();

  return useMutation({
    mutationKey: ['terminateRegistryHolderService', ledgerApi],
    mutationFn: async ({ holderServiceCid }) => {
      return ledgerApi.terminateRegistryHolderService(holderServiceCid, party);
    },
  });
};

export default useTerminateHolderService;
