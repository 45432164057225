import { useNavigate } from 'react-router-dom';
import { Box, Card, CardActionArea, CardMedia, Grid, Typography } from '@mui/material';
import imageCredentials from '../assets/credentials.webp';
import imageGradient from '../assets/gradient.png';
import imageRegistry from '../assets/registry.webp';
import imageTrading from '../assets/trading.webp';
import ErrorWrapper from '../components/ErrorWrapper';
import Header from '../components/Header/Header';
import { UtilityConfig } from '../config/config';
import useStyles from './styles';

const Root: React.FC<{ config: UtilityConfig }> = ({ config }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <ErrorWrapper>
      <div className={classes.root}>
        <Header appName="Utility" />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Grid container direction="column" className={classes.bg}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                Welcome to the Canton Utility
              </Typography>
              <Typography variant="h3" className={classes.subtext}>
                The following modules are available to you:
              </Typography>
              <Grid container direction="row" spacing={4}>
                {config.modules.includes('credential') && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card className={classes.card}>
                      <Box style={{ height: '100%' }}>
                        <CardActionArea onClick={() => navigate('/credential/onboarding')}>
                          <CardMedia
                            className={classes.cardMediaLarge}
                            image={imageCredentials}
                            title="Credentials"
                          />
                          <CardMedia
                            className={classes.cardMediaLargeOverlay}
                            image={imageGradient}
                            title="Credentials"
                          />
                          <Box className={classes.cardMediaText}>
                            <Typography className={classes.cardTitle}>Credentials</Typography>
                            <Typography className={classes.cardText}>Manage credentials</Typography>
                          </Box>
                        </CardActionArea>
                      </Box>
                    </Card>
                  </Grid>
                )}
                {config.modules.includes('registry') && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card className={classes.card}>
                      <Box style={{ height: '100%' }}>
                        <CardActionArea onClick={() => navigate('/registry/onboarding')}>
                          <CardMedia
                            className={classes.cardMediaLarge}
                            image={imageRegistry}
                            title="Registry"
                          />
                          <CardMedia
                            className={classes.cardMediaLargeOverlay}
                            image={imageGradient}
                            title="Registry"
                          />
                          <Box className={classes.cardMediaText}>
                            <Typography className={classes.cardTitle}>Registry</Typography>
                            <Typography className={classes.cardText}>Manage inventory</Typography>
                          </Box>
                        </CardActionArea>
                      </Box>
                    </Card>
                  </Grid>
                )}
                {config.modules.includes('trading') && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card className={classes.card}>
                      <Box style={{ height: '100%' }}>
                        <CardActionArea onClick={() => navigate('/trading/onboarding')}>
                          <CardMedia
                            className={classes.cardMediaLarge}
                            image={imageTrading}
                            title="Trading"
                          />
                          <CardMedia
                            className={classes.cardMediaLargeOverlay}
                            image={imageGradient}
                            title="Trading"
                          />
                          <Box className={classes.cardMediaText}>
                            <Typography className={classes.cardTitle}>Trading</Typography>
                            <Typography className={classes.cardText}>Trade assets</Typography>
                          </Box>
                        </CardActionArea>
                      </Box>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </ErrorWrapper>
  );
};

export default Root;
